import { Form } from '@unform/web';
import React, { useEffect, useState } from 'react';
import { FaBell, FaCertificate, FaCheckCircle, FaExclamationCircle, FaFile, FaHammer, FaIdBadge, FaImages, FaQuestionCircle, FaReceipt, FaRegIdBadge, FaSubscript, FaTicketAlt, FaTrophy, FaUser } from 'react-icons/fa';
import { FiMessageCircle } from 'react-icons/fi';

import { IconType } from 'react-icons/lib';
import { useAuth } from '../../../../hooks/Auth';
import { useConfiguration } from '../../../../hooks/Configuration';
import api from '../../../../services/api';
import InOut from '../../../DashboardPages/core/components/Forms/InOut';
import Curriculum from '../Curriculum';
import FAQ from '../Faq';
import InformacoesGerais from '../InformacoesGerais';
import Notifications from '../Notifications';
import PagamentosGeral from '../PagamentosGeral';
import Pagamentos from '../Pagamentos';
import ProfileContent from '../ProfileContent';
import Registration from '../Registration';

import { Container, ContainerWhite } from './styles';
import { useParams } from 'react-router-dom';
import { useModal } from '../../../../hooks/Modal';
import { useToast } from '../../../../hooks/Toast';
import Input from '../../../../components/Forms/Input';
import Loader from '../../../../components/Loader';
import SelectSimple from '../../../../components/Forms/SelectSimple';
import CheckboxInput from '../../../../components/Forms/CheckboxInput';
import ConfirmateRegistration from '../RegistrationConfirmation';
import CurriculumRecurse from '../CurriculumRecurse';
import TextareaInput from '../../../DashboardPages/core/components/Forms/TextareaInput';
import UploadFormContent from '../../../DashboardPages/features/UploadFormDocuments';
import Recurses from '../Recurses';
import Isencoes from '../SolicitacaoIsencao';




const UserHome: React.FC = () => {

  const { user } = useAuth();
  const { projectHash } = useParams<Record<string, any>>();
  const [userInfo, setUserInfo] = useState<Record<string, any>>({});
  const [projects, setProjects] = useState<Array<Record<string, any>>>([]);
  const [dashboardStatus, setDashboardStatus] = useState('');
  const { addModal, removeModal } = useModal();
  const { addToast } = useToast();
  const [loading, setLoading] = useState(false);
  const selectionStatus = {

    waiting: { title: 'Aguardando documentação', color: '#777' },
    waiting_curriculum: { title: 'Aguardando avaliação da documentação', color: 'orange' },
    register: { title: 'Aguardando pré-matrícula', color: 'rgb(0,0,150)' },
    waiting_complete: { title: 'Aguardando Deferimento', color: 'orange' },
    aprovado: { title: 'Aprovado', color: 'rgb(0,150,0)' },
    complete: { title: 'Matriculado', color: 'rgb(0,150,0)' },
    cancelled: { title: 'Desistente', color: 'rgb(150,0,0)' },
    disqualified: { title: 'Desclassificado', color: 'rgb(150,0,0)' },
    reserved: {
      title: 'Vaga reservada', color: 'rgb(0,100,0)'
    }

  }

  const changeStatus = (value) => {
    setDashboardStatus(value);
    window.scrollTo(0, 0);
  }

  const { configuration } = useConfiguration();
  const [myMedicSubscription, setMyMedicSubscription] = useState<Record<string, any>>({})

  const loadProjects = async () => {
    const response = await api.get('/get-projects');
    if (response?.data) {

      response?.data?.rows.sort((a, b) => {
        return a.title > b.title ? 1 : a.title < b.title ? -1 : 0;
      })


      setProjects(response?.data.rows);
    }
  }

  const checkProcess = async () => {
    const response = await api.get(`/my-medic-subscription/${projectHash}`);
    if (response?.data) {



      setMyMedicSubscription({ ...response.data })
    }
  }

  useEffect(() => {
    loadProjects();

  }, [])

  useEffect(() => {
    checkProcess();

  }, [projectHash])


  const askCancelCode = async () => {

    try {
      const response = await api.post(`/selective-process-cancel-code/${myMedicSubscription._id}`);
      addToast({ title: response?.data?.message, type: 'success' })

      return;
    }
    catch (err) {
      addToast({ title: err.response?.data?.message || 'Erro ao finalizar desistência', type: 'error' })
      return;
    }

  }

  const sendCancelCode = async (data) => {

    setLoading(true);

    if (!data?.code) {
      addToast({ title: 'Informe o código de desistência', type: 'error' });
      setLoading(false);
      return;
    }

    try {

      const response = await api.post(`/selective-process-cancel-send/${myMedicSubscription._id}`, { code: data.code });
      addToast({ title: 'Desistência realizada com sucesso', type: 'success' });
      setLoading(false);

    }
    catch (err) {
      addToast({ title: err?.response?.data?.message || err?.response?.data?.message || 'Erro ao desistir de sua vaga', type: 'error' });
      setLoading(false);
    }
  }

  const cancelSubscription = () => {

    addModal({
      theme: 'whiteModalMedium',
      title: 'Solicitação de Desistência',
      content: <>
        <p style={{ color: '#333', marginTop: '20px' }}>Etapa 1: Solicite o código de confirmação clicando no botão abaixo</p>
        <button type="button" style={{ margin: '20px 0px' }} onClick={() => askCancelCode()} className='defaultButtonMini'>Clique aqui</button>
        <Form onSubmit={sendCancelCode} style={{ paddingTop: '15px', borderTop: '1px solid #ddd' }}>
          <p style={{ color: '#333' }}>Etapa 2: Insira o código que foi encaminhado para o seu e-mail</p>
          <div style={{ border: '1px solid #333', borderRadius: '15px' }}><Input name="code" placeholder="" /></div>

          <button style={{ margin: '10px auto' }} className="defaultButtonMini">Enviar</button>
        </Form>

      </>
    })

  }





  const sendReserva = async (data) => {

    setLoading(true);

    if (data?.reserva?.[0] !== "Sim") {
      addToast({ title: 'Para efetuar a reserva, é obrigatório selecionar Sim', type: 'error' });
      setLoading(false);
      return;
    }

    if (data?.documents?.uploads?.length < 1) {
      addToast({ title: 'Anexe a este pedido o comprovante da convocação para a prestação do serviço militar', type: 'error' });
      setLoading(false);
      return;
    }

    try {

      const response = await api.post(`/selective-process-reserva-send/${myMedicSubscription._id}`, { reserva: data.reserva, documents: data.documents });
      addToast({ title: 'Solicitação de reserva realizada com sucesso', type: 'success' });
      window.location.reload();
      setLoading(false);

    }
    catch (err) {
      addToast({ title: err?.response?.data?.message || err?.response?.data?.message || 'Erro ao solicitar reserva', type: 'error' });
      setLoading(false);
    }
  }

  const reservateSubscription = () => {
    addModal({
      theme: 'whiteModalMedium',
      title: 'Reserva de vaga para residente médico que presta Serviço Militar',
      content: <>

        <p style={{ fontSize: '12px', textAlign: 'justify', color: '#000' }}>
          Eu, {myMedicSubscription?.client_id_response?.name}, CPF {myMedicSubscription?.client_id_response?.document_number},
          RG {myMedicSubscription?.client_id_response?.rg}, aprovado(a) no Programa de Residência Médica em
          {myMedicSubscription.area}, do(a) {configuration?.title}, venho por meio deste, requerer a reserva de vaga no Programa de Residência Médica
          supracitado para a prestação de serviço militar.<br /><br /> Declaro, para os devidos fins, estar ciente de que o reingresso ao Programa
          de Residência Médica se dará mediante requerimento à instituição competente, conforme descrito no edital, e que o não cumprimento do
          disposto implicará perda automática da vaga. Anexo a este pedido o comprovante da convocação para a prestação do serviço militar.


        </p>
        <p style={{ color: '#333', marginTop: '20px' }}>Você deseja realizar a reserva de Vaga?</p>

        <Form onSubmit={sendReserva} style={{ paddingTop: '15px', borderTop: '1px solid #ddd' }}>
          < UploadFormContent name="documents" placeholder='Comprovante da convocação para a prestação do serviço militar' />
          <CheckboxInput name="reserva" placeholder="" options={[{ label: 'Sim', id: 'Sim', value: 'Sim' },

          ]} />

          <button style={{ margin: '10px auto' }} className="defaultButtonMini">Enviar</button>
        </Form>

      </>
    })
  }

  const changeRoom = (url) => {
    window.location.href = `/app/${url}/user-dashboard`;
  }


  const dashboardStates = myMedicSubscription?._id && myMedicSubscription?.project_id === projectHash ? [

    { title: 'Informações Gerais', Icon: <FaReceipt />, state: '', getPage: () => <InformacoesGerais selectiveProcess={myMedicSubscription} changeStatus={(value) => changeStatus(value)} /> },
    { title: 'Perfil', Icon: <FaUser />, state: 'profile', getPage: () => <ProfileContent changeStatus={(value) => changeStatus(value)} /> },
    { title: 'Pagamentos', Icon: <FaTicketAlt />, state: 'payments', getPage: () => <Pagamentos myMedicSubscription={myMedicSubscription} changeStatus={(value) => changeStatus(value)} /> },

    { title: configuration?.allow_curriculum_avaliation_show === 'yes' ? 'Visualizar documentos e avaliação' : 'Envio de Documentação', show: myMedicSubscription?.status !== 'register' && (configuration.stage === 'curriculum' || configuration.stage === 'curriculum_waiting' || configuration.stage === 'curriculum_complete') ? true : false, Icon: <FaImages />, state: 'documents', getPage: () => <Curriculum changeStatus={(value) => changeStatus(value)} /> },

    { title: 'Solicitação de recurso', show: myMedicSubscription?.status !== 'register' && (myMedicSubscription?.allow_curriculum_recurse === 'yes' || configuration?.allow_curriculum_recurse === 'yes') ? true : false, Icon: <FaExclamationCircle color='red' />, state: 'curriculum_recurse', getPage: () => <CurriculumRecurse changeStatus={(value) => changeStatus(value)} /> },
    { title: 'Solicitação de Isenção', Icon: <FaReceipt />, state: 'isention', getPage: () => <Isencoes myMedicSubscription={myMedicSubscription} changeStatus={(value) => changeStatus(value)} /> },


    { title: 'Pré-matrícula', Icon: <FaRegIdBadge style={{ color: 'orange' }} />, show: myMedicSubscription.status === 'register' ? true : false, state: 'registration', getPage: () => <Registration changeStatus={(value) => changeStatus(value)} /> },
    { title: 'Revisão e Confirmação de pré-matrícula', show: myMedicSubscription.status === 'register' || myMedicSubscription.status === 'waiting_complete' || myMedicSubscription.status === 'aprovado' ? true : false, Icon: <FaCheckCircle />, state: 'confirmateRegistration', getPage: () => <ConfirmateRegistration changeStatus={(value) => changeStatus(value)} /> },
    { title: 'Área de Recursos', Icon: <FaQuestionCircle />, state: 'myRecourses', getPage: () => <Recurses selectiveProcess={myMedicSubscription} changeStatus={(value) => changeStatus(value)} /> },


    { title: 'FAQ', Icon: <FiMessageCircle />, state: 'faq', getPage: () => <FAQ limit={50} changeStatus={(value) => changeStatus(value)} /> },
    { title: 'Notificações', Icon: <FaBell />, state: 'notifications', getPage: () => <Notifications limit={50} changeStatus={(value) => changeStatus(value)} /> },
    { title: 'Meus Pagamentos', Icon: <FaBell />, state: 'myPayments', getPage: () => <PagamentosGeral changeStatus={(value) => changeStatus(value)} />, show: false },

  ] : [

    {
      title: 'Informações Gerais', Icon: <FaReceipt />, state: '', getPage: () => <InformacoesGerais changeStatus={(value) => changeStatus(value)} />
    },
    { title: 'Perfil', Icon: <FaUser />, state: 'profile', getPage: () => <ProfileContent changeStatus={(value) => changeStatus(value)} /> },
    { title: 'Pagamentos', Icon: <FaTicketAlt />, state: 'payments', getPage: () => <Pagamentos changeStatus={(value) => changeStatus(value)} /> },
    { title: 'Área de Recursos', Icon: <FaQuestionCircle />, state: 'myRecourses', getPage: () => <Recurses selectiveProcess={myMedicSubscription} changeStatus={(value) => changeStatus(value)} /> },
    { title: 'Solicitação de Isenção', Icon: <FaReceipt />, state: 'isention', getPage: () => <Isencoes myMedicSubscription={myMedicSubscription} changeStatus={(value) => changeStatus(value)} /> },

    // { title: 'Envio de Documentação', Icon: <FaImages />, state: 'documents', getPage: () => <Curriculum changeStatus={(value) => changeStatus(value)} /> },
    // { title: 'Matrícula', Icon: <FaRegIdBadge />, state: 'registration', getPage: () => <Registration changeStatus={(value) => changeStatus(value)} /> },
    { title: 'FAQ', Icon: <FiMessageCircle />, state: 'faq', getPage: () => <FAQ limit={50} changeStatus={(value) => changeStatus(value)} /> },
    { title: 'Notificações', Icon: <FaBell />, state: 'notifications', getPage: () => <Notifications limit={50} changeStatus={(value) => changeStatus(value)} /> },
    { title: 'Meus Pagamentos', Icon: <FaBell />, state: 'myPayments', getPage: () => <PagamentosGeral changeStatus={(value) => changeStatus(value)} />, show: false },

  ];






  const currentState = dashboardStates.find(state => state.state === dashboardStatus);





  return (
    <>
      {loading && <Loader message='Carregando ...' />}
      <Container id="dashboardUser" >
        <div className='p800 headerBoard' >
          <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}>
            <select onChange={(e) => {
              if (e.target.value !== '') {
                changeRoom(e.target.value);
              }
            }} className='defaultButtonMini'>
              <option value={''}>Selecionar outro Processo seletivo</option>
              {projects && projects.map(url => {
                return <option value={url.url} key={`change-${url.url}`} >{url.title}</option>
              })}
            </select>
            <button className='defaultButtonMini' onClick={() => setDashboardStatus('myPayments')}>Meus pagamentos</button>
          </div>

          {dashboardStatus === 'myPayments' ? <>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', marginTop: '10px' }} ><button className='defaultButton' style={{ margin: '0px', marginRight: '15px', marginTop: '0px', marginBottom: '20px' }} onClick={() => setDashboardStatus('')}>Voltar</button>

              <h2 style={{ background: '#41517E', padding: '10px', margin: '0px 0px', marginBottom: '20px', color: '#fff', borderRadius: '5px' }}>
                Todos os pagamentos  </h2>


            </div>
            {currentState?.getPage()} </> : <>


            <div >
              <h2 style={{ background: '#41517E', padding: '10px', margin: '10px 0px', marginBottom: '20px', color: '#fff', borderRadius: '5px' }}>
                {configuration?.current_event_id_response?.title}
              </h2>


            </div>

            <div className='user-header'>
              <h2>
                Área do Participante
              </h2>

              <div />

            </div>
            <div className='user-block shadow'>
              <div className='content-block'>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                  {dashboardStates.map(dash => {
                    return dash?.show === false ? <> </> : <nav key={dash.title} onClick={() => changeStatus(dash.state)} className={`dashboardMenu ${dashboardStatus === dash.state ? 'dashboardMenuActive' : ''}`} > {dash.Icon} <p className='computerOnly'>{dash.title}</p></nav>
                  })}



                </div>
              </div>


            </div>




            {myMedicSubscription?._id && myMedicSubscription?.project_id === projectHash &&
              <div className='user-block shadow'>
                <div className='content-block'>
                  <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>



                    <nav key={'process-status'} className={`dashboardMenu selectDashboardMenu`} style={{ color: '#fff', background: selectionStatus?.[myMedicSubscription?.status]?.color }}> <p>Status: {selectionStatus?.[myMedicSubscription?.status]?.title}</p></nav>

                    {(myMedicSubscription?.status === 'register' || myMedicSubscription?.status === 'waiting_complete' || myMedicSubscription?.status === 'aprovado') && <>
                      {configuration.allow_cancel === 'yes' && myMedicSubscription?.register_position === 'Classificado' ? <nav onClick={() => cancelSubscription()} key={'process-cancelled'} className={`dashboardMenu selectDashboardMenu `} style={{ background: 'rgb(150,0,0)' }}> <p>Desistir da vaga</p></nav> : <></>}
                      {configuration.allow_reserve === 'yes' && myMedicSubscription?.register_position === 'Classificado' ? <nav onClick={() => reservateSubscription()} key={'process-reserved'} className={`dashboardMenu selectDashboardMenu `} style={{ background: 'rgb(0,150,0)' }}> <p>Reserva Militar</p></nav> : <></>}
                    </>
                    }
                  </div>
                </div>
              </div>
            }



            <div className='user-header-submenu'>
              <h2>
                {currentState?.Icon}{` ${currentState?.title}`}
              </h2>

              <div />

            </div>

            {currentState?.getPage()}



          </>}


        </div>
      </Container>
    </>
  );
};
export default UserHome;
