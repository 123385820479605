import React, { useEffect, useRef, useState } from 'react';
import { Form } from '@unform/web';
import { FiMail, FiUser, FiLock, FiMap, FiArrowRight } from 'react-icons/fi';
import { Link, useParams } from 'react-router-dom';
import {
  FaWhatsapp,
  FaFileMedical,
  FaNotesMedical,
  FaHospital,
  FaSimplybuilt,
  FaBuilding,
  FaIdBadge,
  FaClinicMedical,
} from 'react-icons/fa';
import { ISigla, readSigla, siglas as siglasRef } from './siglas';
import { MdError, MdDateRange, MdChat } from 'react-icons/md';
import Webcam from "react-webcam";
import * as Yup from 'yup';
import ReCAPTCHA from 'react-google-recaptcha';
import { FormHandles } from '@unform/core';
import Input from '../../../../../components/Forms/Input';
import Button from '../../../../../components/Forms/Button';
import Loader from '../../../../../components/Loader';
import MaskedInput from '../../../../../components/Forms/MaskedInput';
import { ResponseMessage } from '../../../../../components/ResponseMessage';
import getValidationErrors from '../../../../../utils/getValidationErrors';
import { Error } from './styles';
import SelectSimple from '../../../../../components/Forms/SelectSimple';
import Whatsapp from '../../../../../components/WhatsAppCompartilhar';

import api from '../../../../../services/api';
import { authTitle, recaptchaKey, urlWebsite } from '../../../../../config';
import { countries } from './countries';
import TextareaInput from '../../../../../components/Forms/TextareaInput';
import { useToast } from '../../../../../hooks/Toast';
import CheckboxInput from '../../../../../components/Forms/CheckboxInput';
import getCep from '../../../../../services/cep';
import Axios from 'axios';
import states from './states';
import { useLanguage } from '../../../../../hooks/Language';
import { useConfiguration } from '../../../../../hooks/Configuration';
import DatePicker from '../../../core/components/Forms/DatePicker';
import { uuid } from 'uuidv4';
import { banks } from './banks';
import { rgemissor } from './rgEmissor';
import Documents from '../../../../DashboardUserPages/Pages/Documents';
import { useAuth } from '../../../../../hooks/Auth';
import UploadCurriculumContent from '../../../core/components/Forms/UploadCurriculumContent';
import { DDDs } from './ddds';

interface IRecoverFormData {
  [key: string]: any;
}


interface IRead {
  [key: string]: any;
}

interface IOption {
  value: string;
  label: string;
}

interface ICaptcha {
  getValue(): string;
}

declare var window: any;

const FormContainer = ({ changeStatus }) => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [webcamKey, setWebcamKey] = useState('camera');
  const formRef = useRef<FormHandles>(null);
  const formRefEspecializacao = useRef<FormHandles>(null);
  const { user } = useAuth();
  const [uploadType, setUploadType] = useState('camera');
  const { addToast } = useToast();
  const { translate } = useLanguage();
  const { projectHash, configuration } = useConfiguration();
  const [rgEmissor, setRgEmissor] = useState('');
  const [currentStep, setCurrentStep] = useState(configuration?.register_steps?.[0]?.hash || 'subscription');
  const [cities, setCities] = useState<Array<IOption>>([]);
  const [cities2, setCities2] = useState<Array<IOption>>([]);


  const [stateValue, setStateValue] = useState('');
  const [stateValue2, setStateValue2] = useState('');
  const [residencia, setResidencia] = useState('');


  const [registration, setRegistration] = useState<Record<string, any>>({})
  const [profile, setProfile] = useState<Record<string, any>>({})




  const banco = configuration?.bank || '';

  const [photo, setPhoto] = useState(profile.photo);
  const haveSubscription = configuration?.register_steps?.find(i => i?.hash === 'subscription');
  const changeStep = (step) => {

    setCurrentStep(step);
    window.scrollTo(0, 0);



  }
  const [countryInfo, setCountryInfo] = useState('BR');
  const [addressType, setAddressType] = useState('');
  const [ticket, setTicket] = useState<Record<string, any>>();

  const loadSale = async () => {
    const response = await api.get(`/my-tickets/${projectHash}`);

    if (response?.data?.rows?.length > 0) {
      const index = response.data.rows?.findIndex(s => s.payment_status_id === 'paid');
      setTicket(response?.data?.rows?.[index]);
    }
  }

  useEffect(() => {

    loadSale()


  }, [projectHash])

  const uploadPhoto = async ({ file }) => {

    const formData = new FormData();
    formData.append('file', file);
    try {
      const response = await api.post('/curriculum-upload', formData);
      if (response) {

        const data = response?.data?.message;




        await setTimeout(() => {




          setLoading(false);
          setPhoto(data);

        }, 2000);


      }
      else {

        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      return addToast({ title: err.response.data.message || 'Erro ao realizar upload' })
    }
  };


  const load = async () => {



    const response = await api.get(`/my-medic-subscription/${projectHash}`);

    if (response?.data) {

      if (response?.data?.dress_type) {
        setJalecoType(response?.data?.dress_type);
      }
      if (response?.data?.photo) {
        setPhoto(response?.data?.photo);
      }


      if (profile?.data?.born_in) {
        setCountryInfo(profile?.data?.born_in)
      }
      setRegistration(response?.data?.subscription_data || {})

      if (response?.data?.subscription_data?.residencia) {
        setResidencia(response?.data?.subscription_data?.residencia)
      }

      if (response?.data?.subscription_data?.birth_uf) {
        setStateValue(response?.data?.subscription_data?.birth_uf)
      }
      if (response?.data?.subscription_data?.state) {
        setStateValue2(response?.data?.subscription_data?.state)
      }

      setProfile(response.data);
    }

  }

  useEffect(() => {

    if (projectHash) {

      load();
    }

  }, [projectHash])


  const callCityApi = async (value) => {

    const response = await Axios.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${value}/municipios`);

    if (response) {

      const values: Array<IOption> = [];

      response.data.map(city => {
        values.push({ value: city.nome, label: city.nome });
      })

      setCities(values);
    }


  }

  const callCityApi2 = async (value) => {

    const response = await Axios.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${value}/municipios`);

    if (response) {

      const values: Array<IOption> = [];

      response.data.map(city => {
        values.push({ value: city.nome, label: city.nome });
      })

      setCities2(values);
    }


  }

  useEffect(() => {
    callCityApi(stateValue);
  }, [stateValue])

  useEffect(() => {
    callCityApi2(stateValue2);
  }, [stateValue2])

  const getCepFromApi = async (value: string): Promise<void> => {
    const cepResponse = await getCep(value);

    if (!cepResponse.error) {

      formRef?.current?.setFieldValue('address', cepResponse.logradouro);
      formRef?.current?.setFieldValue('neighborhood', cepResponse.bairro);
      formRef?.current?.setFieldValue('city', cepResponse.localidade);
      formRef?.current?.setFieldValue('state', cepResponse.uf);
    }
  };



  const getMedicalBanksOptions = () => {

    if (configuration?.allow_medical_subscription_bank && configuration?.allow_medical_subscription_bank?.length > 0) {

      const defaultBank = configuration?.allow_medical_subscription_bank?.filter(bank => bank.type === '');
      const otherBanks = configuration?.allow_medical_subscription_bank?.filter(bank => bank?.type && bank.category && bank?.subarea ? bank?.type === profile.category && bank.area === profile.area && bank.subarea === profile.subarea : bank.category && bank?.subarea ? bank.area === profile.area && bank.subarea === profile.subarea : bank.subarea === profile.subarea);

      if (otherBanks && otherBanks?.length > 0) {
        const bankOptions: Array<IOption> = [];

        otherBanks?.map(b => {

          const item = banks.find(bankValue => bankValue.value === b?.bank);

          if (item) {
            bankOptions.push({ ...item })
          }

        })

        return bankOptions;

      }
      else if (defaultBank && defaultBank?.length > 0) {

        const bankOptions: Array<IOption> = [];

        defaultBank?.map(b => {

          const item = banks.find(bankValue => bankValue.value === b.bank);

          if (item) {
            bankOptions.push({ ...item })
          }

        })

        return bankOptions;


      }
      else {
        return banks;
      }


    }
    else {
      return banks;
    }



  }


  const handleDress = async (data: IRecoverFormData) => {
    setLoading(true);


    try {
      formRef.current?.setErrors({});
      const schema = Yup.object().shape({
        dress_type: Yup.string().required('Informe se o Jaleco é masculino ou feminino'),
        dress_size: Yup.string().required('Informe tamanho do jaleco'),

      });

      await schema.validate(data, { abortEarly: false });

      const newUser = await api
        .post(`/update-dress/${projectHash}`, {
          ...data,
          project_id: projectHash,

        })
        .catch(error => {
          if (error instanceof Yup.ValidationError) {
            const errors = getValidationErrors(error);
            formRef.current?.setErrors(errors);
            setLoading(false);
          }

          addToast({
            type: 'error',
            title: error?.response?.data?.message || error?.response?.data?.error || 'Erro ao atualizar',
          });
          setLoading(false);
          return false;
        });


      addToast({ title: 'Atualizado com sucesso', type: 'success' })
      setLoading(false);


      return false;
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);

        formRef.current?.setErrors(errors);

        const errorInfo = err.errors.reduce((error, prev) => {
          prev = `${prev}, ${error}`;
          return prev;
        });
        setLoading(false);
        addToast({
          type: 'error',
          title: 'Erro de validação',
          description: errorInfo,
        });
      }
      setLoading(false);
    }
  }


  const handlePhoto = async (data: IRecoverFormData) => {
    setLoading(true);


    try {
      formRef.current?.setErrors({});
      const schema = Yup.object().shape({
        photo: Yup.string().required('Foto não identificada'),


      });




      await schema.validate(data, { abortEarly: false });

      const newUser = await api
        .post(`/update-photo/${projectHash}`, {
          ...data,
          project_id: projectHash,

        })
        .catch(error => {
          if (error instanceof Yup.ValidationError) {
            const errors = getValidationErrors(error);
            formRef.current?.setErrors(errors);
            setLoading(false);
          }

          addToast({
            type: 'error',
            title: error?.response?.data?.message || error?.response?.data?.error || 'Erro ao atualizar',
          });
          setLoading(false);
          return false;
        });


      addToast({ title: 'Atualizado com sucesso', type: 'success' })
      setLoading(false);


      return false;
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);

        formRef.current?.setErrors(errors);

        const errorInfo = err.errors.reduce((error, prev) => {
          prev = `${prev}, ${error}`;
          return prev;
        });
        setLoading(false);
        addToast({
          type: 'error',
          title: 'Erro de validação',
          description: errorInfo,
        });
      }
      setLoading(false);
    }
  }


  const sendSubscription = async () => {
    const response = await api.post(`/update-my-medic-confirm/${profile._id}`);
    addToast({ title: 'Matrícula enviada', type: 'success' });
    window.location.reload();

  }


  const sendDocuments = async (data: Record<string, any>) => {
    setLoading(true);

    const newData = {}

    Object.keys(data).map(key => {

      newData[key] = typeof data[key] === 'object' ? data?.[key]?.[0] : data?.[key];

    })



    try {
      formRef.current?.setErrors({});
      const schema = Yup.object().shape({
        mmr: Yup.string().required('Informe se você tomou a vacina MMR'),
        hepatiteb: Yup.string().required(`Informe se você tomou a vacina da Hepatite B`),
        antitetanica: Yup.string().required(`Informe se você tomou a vacina Antitetanica`),
        covid19: Yup.string().required(`Informe se você tomou a vacina da Covid 19`),

      });




      await schema.validate(newData, { abortEarly: false });


      const newUser = await api
        .post(`/update-my-medic-vaccines/${profile._id}`, {
          vaccines: { ...newData },
          project_id: projectHash,

        })
        .catch(error => {
          if (error instanceof Yup.ValidationError) {
            const errors = getValidationErrors(error);
            formRef.current?.setErrors(errors);
            setLoading(false);
          }

          console.log(error);

          addToast({
            type: 'error',
            title: error?.response?.data?.message || error?.response?.data?.error || 'Erro ao realizar Pré-matricula',
          });
          setLoading(false);
          return false;
        });


      addToast({ title: 'Atualizado com sucesso', type: 'success' })
      setLoading(false);


      return false;
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);

        formRef.current?.setErrors(errors);

        const errorInfo = err.errors.reduce((error, prev) => {
          prev = `${prev}, ${error}`;
          return prev;
        });
        setLoading(false);
        addToast({
          type: 'error',
          title: 'Erro de validação',
          description: errorInfo,
        });
      }
      setLoading(false);
    }
  }

  const sendVaccines = async (data: Record<string, any>) => {
    setLoading(true);

    const newData = {}

    Object.keys(data).map(key => {

      newData[key] = typeof data[key] === 'object' ? data?.[key]?.[0] : data?.[key];

    })



    try {
      formRef.current?.setErrors({});
      const schema = Yup.object().shape({
        mmr: Yup.string().required('Informe se você tomou a vacina MMR'),
        hepatiteb: Yup.string().required(`Informe se você tomou a vacina da Hepatite B`),
        antitetanica: Yup.string().required(`Informe se você tomou a vacina Antitetanica`),
        covid19: Yup.string().required(`Informe se você tomou a vacina da Covid 19`),

      });




      await schema.validate(newData, { abortEarly: false });


      const newUser = await api
        .post(`/update-my-medic-vaccines/${profile._id}`, {
          vaccines: { ...newData },
          project_id: projectHash,

        })
        .catch(error => {
          if (error instanceof Yup.ValidationError) {
            const errors = getValidationErrors(error);
            formRef.current?.setErrors(errors);
            setLoading(false);
          }

          console.log(error);

          addToast({
            type: 'error',
            title: error?.response?.data?.message || error?.response?.data?.error || 'Erro ao realizar Pré-matricula',
          });
          setLoading(false);
          return false;
        });


      addToast({ title: 'Atualizado com sucesso', type: 'success' })
      setLoading(false);


      return false;
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);

        formRef.current?.setErrors(errors);

        const errorInfo = err.errors.reduce((error, prev) => {
          prev = `${prev}, ${error}`;
          return prev;
        });
        setLoading(false);
        addToast({
          type: 'error',
          title: 'Erro de validação',
          description: errorInfo,
        });
      }
      setLoading(false);
    }
  }


  async function getMedia() {

    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      console.log("getUserMedia supported.");
      navigator.mediaDevices
        .getUserMedia(
          // constraints - only audio needed for this app
          {
            video: true,
          }
        ).then(r => {
          setWebcamKey(uuid());
        })
    }
  }

  const handleNewLogin = async (data: IRecoverFormData) => {
    setLoading(true);



    try {
      formRef.current?.setErrors({});
      const schema = Yup.object().shape({
        birth_date: Yup.string().required('Data de nascimento é obrigatório'),
        badge_name: Yup.string().required('Nome que deseja usar no Crachá'),
        bank_name: Yup.string().required(`Banco`),
        bank_agency: Yup.string().required(`Número da Agência`),

        bank_account: Yup.string().required(`Número da conta corrente`),
        bank_account_dv: Yup.string().required(`DV da conta corrente`),
        marital_status: Yup.string().required(`Estado Civil`),

        work_number: Yup.string().required(`PIS/NIT/NIS`),
        // work_number_date: Yup.string().required(`Data de Expedição`),
        blood_type: Yup.string().required(`Tipo Sanguíneo`),
        blood_type_factor: Yup.string().required(`Fator RH sanguíneo`),


        ddd_home: Yup.string().required(`DDD do Telefone residencial obrigatório`),
        phone: Yup.string().required(`Telefone residencial obrigatório`),
        ddd_mobile: Yup.string().required(`DDD do celular obrigatório`),
        mobile: Yup.string().required(`Celular obrigatório`),
        address: Yup.string().required(`Endereço`),
        address_number: Yup.string().required(`Número / Apartamento`),
        neighborhood: Yup.string().required(`Bairro`),
        zipcode: Yup.string().required(`Cep`),
        city: Yup.string().required(`Cidade`),
        state: Yup.string().required(`Estado`),

        rg: Yup.string().required(`${translate('Carteira de Identidade (RG)')} ${translate('obrigatório')}`),
        rg_date: Yup.string().required(`${translate('Data de expedição do RG')} ${translate('obrigatório')}`),
        rg_emissor: Yup.string().required(`${translate('Órgão Expedidor do RG')} ${translate('obrigatório')}`),
        rg_uf: Yup.string().required(`${translate('Estado Expedidor do RG')} ${translate('obrigatório')}`),
        voter_registration: Yup.string().required(`${translate('Título de Eleitor')} ${translate('obrigatório')}`),

        votation_date: Yup.string().required(`${translate('Data de emissão do Título de Eleitor')} ${translate('obrigatório')}`),
        votation_zone: Yup.string().required(`Zona Eleitoral`),
        votation_section: Yup.string().required(`Seção Eleitoral`),

        gender_id: Yup.string().required(`${translate('Gênero')} ${translate('obrigatório')}`),

        nationality: Yup.string().required(`${translate('Nacionalidade')} ${translate('obrigatório')}`),
        mother_name: Yup.string().required(`${translate('Nome completo da mãe')} ${translate('obrigatório')}`),
        instituicao: Yup.string().required(`${translate('Universidade de Graduação')} ${translate('obrigatório')}`),

        race: Yup.string().required(`${translate('Raça / Cor')} ${translate('obrigatório')}`),
        graduation: Yup.string().required(`${translate('Grau de escolaridade')} ${translate('obrigatório')}`),
        born_at: Yup.string().required(`${translate('Naturalidade')} ${translate('obrigatório')}`),
        born_in: Yup.string().required(`${translate('País de nascimento')} ${translate('obrigatório')}`),

        university_entry_date: Yup.string().required(`Data de Ingresso na Universidade`),
        university_finish_date: Yup.string().required(`Data de Conclusão da Graduação`),
        birth_uf: Yup.string().required(`Estado de nascimento`),

        country_type: Yup.string().required(`Situação da nacionalidade obrigatória`),


      });

      await schema.validate(data, { abortEarly: false });

      const newUser = await api
        .post(`/update-my-medic-subscription-data/${profile._id}`, {
          subscription_data: { ...data },
          project_id: projectHash,

        })
        .catch(error => {
          if (error instanceof Yup.ValidationError) {
            const errors = getValidationErrors(error);
            formRef.current?.setErrors(errors);
            setLoading(false);
          }

          addToast({
            type: 'error',
            title: error?.response?.data?.message || error?.response?.data?.error || 'Erro ao realizar Pré-matricula',
          });
          setLoading(false);
          return false;
        });

      window?.scrollTo(0, 0)
      addToast({ title: 'Atualizado com sucesso', type: 'success' })
      setLoading(false);


      return false;
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);

        formRef.current?.setErrors(errors);

        const errorInfo = err.errors.reduce((error, prev) => {
          prev = `${prev}, ${error}`;
          return prev;
        });
        setLoading(false);
        addToast({
          type: 'error',
          title: 'Erro de validação',
          description: errorInfo,
        });
      }
      setLoading(false);
    }
  }



  const handleNewLoginEspecialidade = async (data: IRecoverFormData) => {
    setLoading(true);



    try {
      formRef.current?.setErrors({});

      const shape: Record<string, any> = {
        birth_date: Yup.string().required('Data de nascimento é obrigatório'),

        marital_status: Yup.string().required(`Estado Civil`),


        ddd_mobile: Yup.string().required(`DDD do celular obrigatório`),
        mobile: Yup.string().required(`Celular obrigatório`),
        address: Yup.string().required(`Endereço`),
        address_number: Yup.string().required(`Número / Apartamento`),
        address_extra: Yup.string().required(`Tipo de logradouro`),

        address_number_type: Yup.string().required(`Tipo de complemento`),

        neighborhood: Yup.string().required(`Bairro`),
        zipcode: Yup.string().required(`Cep`),
        city: Yup.string().required(`Cidade`),
        state: Yup.string().required(`Estado`),
        country: Yup.string().required(`País`),



        gender_id: Yup.string().required(`${translate('Sexo')} ${translate('obrigatório')}`),

        nationality: Yup.string().required(`${translate('Nacionalidade')} ${translate('obrigatório')}`),
        mother_name: Yup.string().required(`${translate('Nome completo da mãe')} ${translate('obrigatório')}`),
        instituicao: Yup.string().required(`${translate('Universidade de Graduação')} ${translate('obrigatório')}`),
        university_finish_date: Yup.string().required(`Data de Conclusão da Graduação`),
        race: Yup.string().required(`${translate('Origem étnica')} ${translate('obrigatório')}`),
        born_at: Yup.string().required(`${translate('Naturalidade')} ${translate('obrigatório')}`),

        payment_form: Yup.string().required(`${translate('Forma de pagamento - ')} ${translate('obrigatória')}`),


      }

      if (ticket?.product_id_response?.hash_link?.indexOf('-total') < 0 && data.payment_form === 'À vista') {
        data.payment_form = '';
      }


      if (ticket?.product_id_response?.hash_link?.indexOf('-total') >= 0) {
        data.payment_form = 'À vista';
      }

      const schema = Yup.object().shape(shape);




      await schema.validate(data, { abortEarly: false });










      const newUser = await api
        .post(`/update-my-medic-subscription-data/${profile._id}`, {
          subscription_data: { ...data },
          project_id: projectHash,

        })
        .catch(error => {
          if (error instanceof Yup.ValidationError) {
            const errors = getValidationErrors(error);
            formRef.current?.setErrors(errors);
            setLoading(false);
          }

          addToast({
            type: 'error',
            title: error?.response?.data?.message || error?.response?.data?.error || 'Erro ao realizar Pré-matricula',
          });
          setLoading(false);
          return false;
        });


      addToast({ title: 'Atualizado com sucesso', type: 'success' })
      setLoading(false);


      return false;
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);

        formRefEspecializacao.current?.setErrors(errors);

        const errorInfo = err.errors.reduce((error, prev) => {
          prev = `${prev}, ${error}`;
          return prev;
        });
        setLoading(false);
        addToast({
          type: 'error',
          title: 'Erro de validação',
          description: errorInfo,
        });
      }
      setLoading(false);
    }
  }



  const [jalecoType, setJalecoType] = useState('');

  function dataURLtoFile(dataurl, filename) {

    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  if (!profile?._id) {
    return <Loader message="Preparando sua matrícula..." position="fixed" />

  }

  if (profile.status !== 'register') {
    return <h2 style={{ color: "#333" }}>Etapa de Matrícula não liberada</h2>

  }

  return (
    <>
      {loading === true && (
        <Loader message="Preparando sua matrícula..." position="fixed" />
      )}

      <aside style={{ width: '100%', maxWidth: '800px', margin: '0px auto', paddingBottom: '150px' }}>

        <h2 style={{ color: '#333', fontSize: '24px', width: '100%', textAlign: 'center' }}>Orientações de Matrícula</h2>

        <p style={{ background: '#ddd', color: '#333', padding: '15px', textAlign: 'center', margin: '20px auto', maxWidth: '500px', width: '100%' }}>Para cada etapa, preencha os dados / adicione os arquivos e clique no botão Salvar no topo.</p>

        {profile.review_status === 'yes' && profile.status === 'register' ? <aside style={{ width: '100%', gap: '10px', display: 'flex', alignItems: 'center', flexWrap: 'wrap', justifyContent: 'center' }}>
          <div style={{ display: 'flex', borderRadius: '15px', justifyContent: 'center', alignItems: 'center', background: 'rgb(150,0,0)', flexDirection: 'column', padding: '15px 30px', width: '100%', maxWidth: '500px', margin: '20px auto' }}>
            <h2 style={{ color: '#fff', fontSize: '14px' }}>ATENÇÃO:</h2>
            <p style={{ color: '#fff', fontSize: '14px' }}>Após alterar os documentos solicitados no e-mail pela organização, revise novamente seus dados e FINALIZE A SUBMISSÃO DA MATRÍCULA<br />


            </p>
          </div>
        </aside> : <></>}

        <aside style={{ width: '100%', gap: '10px', display: 'flex', alignItems: 'center', flexWrap: 'wrap', justifyContent: 'center' }}>

          {configuration && configuration?.register_steps && configuration?.register_steps?.findIndex(item => item.hash === 'subscription') >= 0 ?
            <button style={{ background: '#333', color: '#fff' }} className={'simpleButtonActive'} onClick={() => changeStep('subscription')} type="submit">
              Matrícula
            </button> : <></>}

          {configuration && configuration?.register_steps && configuration?.register_steps?.findIndex(item => item.hash === 'subscription_especializacao') >= 0 ? <button style={{ background: '#333', color: '#fff' }} className={'simpleButtonActive'} onClick={() => changeStep('subscription_especializacao')} type="submit">
            Matrícula
          </button> : <></>}
          {configuration && configuration?.register_steps && configuration?.register_steps?.findIndex(item => item.hash === 'jaleco') >= 0 && <button style={{ background: '#333', color: '#fff' }} className={'simpleButtonActive'} onClick={() => changeStep('jaleco')} type="submit">
            Jaleco
          </button>}
          {configuration && configuration?.register_steps && configuration?.register_steps?.findIndex(item => item.hash === 'photo') >= 0 && <button style={{ background: '#333', color: '#fff' }} className={'simpleButtonActive'} onClick={() => changeStep('foto')} type="submit">
            Foto para Crachá
          </button>}
          {configuration && configuration?.register_steps && configuration?.register_steps?.findIndex(item => item.hash === 'vaccine') >= 0 ? <button style={{ background: '#333', color: '#fff' }} className={'simpleButtonActive'} onClick={() => changeStep('vacinas')} type="submit">
            Vacinas
          </button> : <></>}
          {configuration && configuration?.register_steps && configuration?.register_steps?.findIndex(item => item.hash === 'documents') >= 0 ? <button style={{ background: '#333', color: '#fff' }} className={'simpleButtonActive'} onClick={() => changeStep('documentos')} type="submit">
            Documentação
          </button> : <></>}
          <button style={{ background: '#333', color: '#fff' }} className={'simpleButtonActive'} onClick={() => changeStatus('confirmateRegistration')} type="submit">
            Revisar e Finalizar
          </button>
        </aside>
        <aside style={{ width: '100%', display: currentStep === 'subscription' ? 'block' : 'none' }}>

          <Form key={'form-subscription'} ref={formRef} onSubmit={handleNewLogin} initialData={{ residencia, graduation: 'SUPERIOR COMPLETO', born_in: 'BR', nationality: 'BRASILEIRO', ...profile?.client_id_response, ...profile?.subscription, }}>
            <aside style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <button className="defaultButton" type="submit">
                Salvar


              </button>
            </aside><br />

            <h1 style={{ color: 'rgb(0,15,64)', marginBottom: '20px' }}>Matrícula</h1>

            <h2 style={{ textAlign: 'center', fontSize: '22px', color: '#333', width: '100%', maxWidth: '500px', margin: '10px auto' }}>Dados Cadastrais</h2>

            <p style={{ textAlign: 'left', fontSize: '14px', marginTop: '15px', color: '#333', width: '100%', padding: '0px 32px', margin: '10px auto' }}>Nome: {user?.name}</p>
            <p style={{ textAlign: 'left', fontSize: '14px', color: '#333', width: '100%', padding: '0px 32px', margin: '10px auto' }}>E-mail: {user?.email}</p>
            <p style={{ textAlign: 'left', fontSize: '14px', marginBottom: '15px', color: '#333', width: '100%', padding: '0px 32px', margin: '10px auto' }}>CPF: {user?.document_number}</p>



            <Input replace='letter' name="social_name" placeholder="NOME SOCIAL
" />
            <p style={{ fontSize: '10px', color: '#333' }}>É o nome pelo qual uma pessoa se identifica e quer ser reconhecida na sociedade, sendo diferente do nome registrado em cartório no nascimento</p>
            <Input replace={projectHash?.indexOf('hcpa') >= 0 ? 'letter22' : 'letter'} name="badge_name" placeholder="NOME QUE DESEJA USAR NO CRACHÁ" mandatory='yes' />
            <MaskedInput mask="99/99/9999" placeholder='DATA DE NASCIMENTO' name="birth_date" mandatory='yes' />



            <Input mandatory="yes"

              name="rg"
              placeholder={translate('CARTEIRA DE IDENTIDADE - RG')}
            />

            <MaskedInput mandatory="yes"
              mask="99/99/9999"
              name="rg_date"
              placeholder={translate('DATA DE EXPEDIÇÃO DO RG')}
            />



            <SelectSimple index={4} mandatory="yes" setCallback={(value) => setRgEmissor(value)} options={rgemissor} name="rg_emissor" label={translate('ÓRGÃO EXPEDIDOR DO RG')} />

            {rgEmissor === 'outro' ? <Input replace="letter" name="rg_emissor"
              placeholder={translate('QUAL O ORGÃO EXPEDIDOR DO RG?')} /> : <></>}

            <SelectSimple mandatory="yes" index={5} options={states} name="rg_uf" label={translate('ESTADO EXPEDIDOR DO RG')} />



            {/* <Input replace="letter" name="etnia"
              placeholder={translate('Etnia')} /> */}

            <SelectSimple index={6} mandatory="yes" options={[
              { value: 'Masculino', label: 'Masculino', text: 'Masculino' },
              { value: 'Feminino', label: 'Feminino', text: 'Feminino' }
            ]} name="gender_id" label={translate('SEXO')} />



            <MaskedInput mandatory="yes"
              mask="999999999999"
              name="voter_registration"
              placeholder={translate('TÍTULO DE ELEITOR')} />

            <MaskedInput mandatory="yes" mask="99/99/9999" placeholder='DATA DE EMISSÃO DO TITULO DE ELEITOR' name="votation_date" />

            <Input mandatory="yes" placeholder='ZONA DO TITULO DE ELEITOR' name="votation_zone" />
            <Input mandatory="yes" placeholder='SEÇÃO DO TITULO DE ELEITOR' name="votation_section" />

            <Input replace='number' placeholder='NÚMERO DO RA - CERTIFICADO DE RESERVISTA' name="reservation_number" />



            <Input mandatory="yes" replace="letter" name="nationality"
              placeholder={translate('NACIONALIDADE')} />
            <SelectSimple mandatory="yes" index={8} label="PAÍS DE NASCIMENTO" setCallback={(value) => setCountryInfo(value)} options={countries} name="born_in" />
            {countryInfo === 'BR' ? <SelectSimple mandatory="yes" index={9} setCallback={(value) => setStateValue(value)} options={states} name="birth_uf" label='UF DE NASCIMENTO' /> :
              <Input mandatory="yes" name="birth_uf"
                placeholder={translate('UF DE NASCIMENTO')} />}
            {countryInfo === 'BR' ? <SelectSimple mandatory="yes" index={10} options={cities ? cities : profile?.born_at ? [{ label: profile?.born_at, value: profile?.born_at }] : []} name="born_at" label='NATURALIDADE' /> :
              <Input mandatory="yes" replace="letter" name="born_at"
                placeholder={translate('NATURALIDADE')} />}






            <Input mandatory="yes" replace="letter" name="mother_name"
              placeholder={translate('NOME COMPLETO DA MÃE')} />
            <Input mandatory="no" replace="letter" name="father_name"
              placeholder={translate('NOME COMPLETO DO PAI')} />

            <SelectSimple index={11} mandatory="yes" label="RAÇA/COR" options={[

              { value: "INDIGENA", label: 'INDIGENA', text: 'INDIGENA' },
              { value: "BRANCA", label: 'BRANCA', text: 'BRANCA' },
              { value: "PRETA", label: 'PRETA', text: 'PRETA' },
              { value: "AMARELA", label: 'AMARELA', text: 'AMARELA' },
              { value: "PARDA", label: 'PARDA', text: 'PARDA' },


            ]} name="race" />



            <Input placeholder='NÚMERO DE REGISTRO PROFISSIONAL' name="profissional_number" />
            <MaskedInput mask="99/99/9999" placeholder='DATA DO REGISTRO PROFISSSIONAL' name="profissional_number_date" />

            <SelectSimple index={12} options={getMedicalBanksOptions() || []} name="bank_name" label={`BANCO`} mandatory="yes" />

            <Input name="bank_agency" placeholder={`NÚMERO DA AGÊNCIA`} mandatory="yes" />
            <Input name="bank_agency_dv" placeholder={`DÍGITO VERIFICADOR DA AGÊNCIA`} />
            <Input name="bank_account" placeholder={`NÚMERO DA CONTA CORRENTE`} mandatory="yes" />
            <Input name="bank_account_dv" placeholder={`DÍGITO VERIFICADOR DA CONTA CORRENTE`} mandatory="yes" />
            <SelectSimple index={12} mandatory="yes"
              options={[
                { value: 'SOLTEIRO', label: 'SOLTEIRO' },
                { value: 'CASADO', label: 'CASADO' },
                { value: 'DIVORCIADO', label: 'DIVORCIADO' },
                { value: 'VIUVO', label: 'VIÚVO' },
                { value: 'SEPARADO', label: 'SEPARADO' },

              ]}
              name="marital_status"

              label="ESTADO CIVIL"
            />

            <Input replace='number' name="work_number" placeholder="PIS/NIT/NIS" mandatory="yes" />
            {/* <MaskedInput mask="99/99/9999" placeholder='DATA DE EXPEDIÇÃO' name="work_number_date" /> */}

            <SelectSimple index={14} mandatory="yes"
              options={[
                { value: 'A', label: 'A' },
                { value: 'AB', label: 'AB' },
                { value: 'B', label: 'B' },
                { value: 'O', label: 'O' },
              ]}
              name="blood_type"

              label="TIPO SANGUÍNEO"
            />

            <SelectSimple index={15} mandatory="yes"
              options={[
                { value: 'POSITIVO', label: 'Positivo' },
                { value: 'NEGATIVO', label: 'Negativo' },

              ]}
              name="blood_type_factor"

              label="FATOR RH SANGUÍNEO"
            />



            <p style={{ padding: '10px', color: '#333', textAlign: 'center', fontSize: '12px' }}>Preencha seu endereço de residencia/moradia</p>


            <SelectSimple mandatory="yes" index={16} options={countries} name="country" label='PAÍS' />
            <SelectSimple mandatory="yes" index={17} setCallback={(value) => setStateValue2(value)} options={states} name="state" label='ESTADO' />

            <SelectSimple mandatory="yes" index={18} options={cities2} name="city" label='CIDADE' />
            <MaskedInput mandatory="yes" mask="99999999" placeholder='CEP' name="zipcode" />
            <SelectSimple mandatory="yes" index={19} options={[
              { label: 'RUA', value: 'RUA' },
              { label: 'AVENIDA', value: 'AVENIDA' },
              { label: 'BECO', value: 'BECO' },
              { label: 'ALAMEDA', value: 'ALAMEDA' },
              { label: 'TRAVESSA', value: 'TRAVESSA' },
              { label: 'PRAÇA', value: 'PRAÇA' },
              { label: 'OUTRO', value: 'OUTRO' },

            ]} name="address_type" label='LOGRADOURO' />



            <Input mandatory="yes" name="address" placeholder={translate('ENDEREÇO COMPLETO')} />
            <Input mandatory="yes" name="address_number" placeholder={translate('NÚMERO')} />

            <SelectSimple mandatory="yes" index={20} options={[
              { label: 'CASA', value: 'CASA' },
              { label: 'AP', value: 'AP' },
              { label: 'BLOCO', value: 'BLOCO' },
              { label: 'QUADRA', value: 'QUADRA' },
              { label: 'SALA', value: 'SALA' },
              { label: 'OUTRO, QUAL?', value: 'OUTRO, QUAL?' },


            ]} name="address_number_type" label='TIPO DE COMPLEMENTO' />

            <Input name="address_number_extra" placeholder={translate('COMPLEMENTO')} />



            <Input mandatory="yes" name="neighborhood" placeholder={translate('BAIRRO')} />


            <SelectSimple mandatory="yes" index={21} label="DDD DO TELEFONE RESIDENCIAL" options={DDDs} name="ddd_home" />


            <MaskedInput mandatory="yes" mask="999999999" placeholder='TELEFONE RESIDENCIAL' name="phone" />

            <SelectSimple mandatory="yes" index={22} label="DDD DO CELULAR" options={DDDs} name="ddd_mobile" />

            <MaskedInput mandatory="yes" mask="999999999" placeholder='TELEFONE CELULAR' name="mobile" />


            <div style={{ display: 'none' }}>
              <SelectSimple mandatory="yes" index={23} label="GRAU DE INSTRUÇÃO" options={[

                { value: "SUPERIOR COMPLETO", label: 'SUPERIOR COMPLETO', text: 'SUPERIOR COMPLETO' },


              ]} name="graduation" />
            </div>


            <Input mandatory="yes" name="instituicao"
              placeholder={translate('UNIVERSIDADE DE GRADUAÇÃO - NOME POR EXTENSO INSTITUIÇÃO')} />
            <MaskedInput mandatory="yes" mask="99/99/9999" placeholder='DATA DE INGRESSO NA GRADUAÇÃO' name="university_entry_date" />
            <MaskedInput mandatory="yes" mask="99/99/9999" placeholder='DATA DE CONCLUSÃO DA GRADUAÇÃO' name="university_finish_date" />

            {projectHash?.indexOf('hcpa') >= 0 ? <>
              <SelectSimple index={24} mandatory="yes" defaultValueItem='Não'
                options={[
                  { value: 'Sim', label: 'Sim' },
                  { value: 'Não', label: 'Não' },

                ]}
                name="vinculo"

                label="JÁ TEVE OU TEM ALGUM TIPO DE VÍNCULO COM O HCPA?"
              />

              <SelectSimple index={25} mandatory="yes" defaultValueItem='Não'
                options={[
                  { value: 'Sim', label: 'Sim' },
                  { value: 'Não', label: 'Não' },

                ]}
                setCallback={(value) => setResidencia(value)}
                name="residencia"

                label={configuration?.url && configuration?.url?.indexOf('multi') > 0 ? "É OU JÁ FOI RESIDENTE UNI/MULTIPROFISSIONAL DO HCPA?" : "É OU JÁ FOI MÉDICO RESIDENTE DO HCPA?"}
              />

              {residencia === 'Sim' ? <Input name="residencia_matricula"
                placeholder={translate('CASO SEJA OU JÁ TENHA SIDO RESIDENTE, FAVOR INFORMAR SEU NÚMERO DE MATRÍCULA (CARTÃO PONTO)')} /> : <></>}




            </> : <></>}
            <SelectSimple index={26} mandatory="yes" label="SITUAÇÃO DA NACIONALIDADE" options={[

              { value: "ESTRANGEIRO SEM NATURALIZAÇÃO", label: 'ESTRANGEIRO SEM NATURALIZAÇÃO', text: 'ESTRANGEIRO SEM NATURALIZAÇÃO' },
              { value: "BRASILEIRO NATO", label: 'BRASILEIRO NATO', text: 'BRASILEIRO NATO' },
              { value: "ESTRANGEIRO NATURALIZADO", label: 'ESTRANGEIRO NATURALIZADO', text: 'ESTRANGEIRO NATURALIZADO' },

            ]} name="country_type" />
            <br />
            <aside style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <button className="defaultButton" type="submit">
                SALVAR E IR PARA PRÓXIMA ETAPA


              </button>
            </aside>

            <aside style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <button className="defaultButton" type="button" onClick={() => window.scrollTo(0, 0)}>
                Voltar ao topo


              </button>
            </aside>
          </Form>
        </aside>


        <aside style={{ width: '100%', display: currentStep === 'subscription_especializacao' ? 'block' : 'none' }}>

          <Form ref={formRefEspecializacao} onSubmit={handleNewLoginEspecialidade} initialData={{ ...profile?.client_id_response, nationality: 'BRASILEIRO', ...profile?.subscription, }}>
            <aside style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <button className="defaultButton" type="submit">
                Salvar


              </button>
            </aside><br />

            <h1 style={{ color: 'rgb(0,15,64)', marginBottom: '20px' }}>Matrícula</h1>
            <h2 style={{ textAlign: 'center', fontSize: '22px', color: '#333', width: '100%', maxWidth: '500px', margin: '10px auto' }}>Dados Cadastrais</h2>

            <MaskedInput mandatory="yes" setCallback={getCepFromApi} mask="99999999" placeholder='CEP' name="zipcode" />

            <Input mandatory="yes" name="address" placeholder={translate('ENDEREÇO')} />
            <Input mandatory="yes" name="address_number" placeholder={translate('NÚMERO')} />
            <Input mandatory="yes" name="address_number_extra" placeholder={translate('COMPLEMENTO')} />
            <Input mandatory="yes" name="address_extra" placeholder={translate('TIPO DE LOGRADOURO')} />
            <Input mandatory="yes" name="neighborhood" placeholder={translate('BAIRRO')} />
            <Input mandatory="yes" name="city" replace='letter' placeholder={translate('CIDADE')} />
            <SelectSimple mandatory="yes" index={23} options={states} name="state" label='ESTADO' />
            <SelectSimple mandatory="yes" index={24} options={countries} name="country" label='PAÍS' />


            <MaskedInput mandatory="yes" mask="99/99/9999" placeholder='DATA DE NASCIMENTO' name="birth_date" />

            <SelectSimple mandatory="yes" index={14} options={[
              { value: 'Masculino', label: 'Masculino', text: 'Masculino' },
              { value: 'Feminino', label: 'Feminino', text: 'Feminino' }
            ]} name="gender_id" label={translate('Sexo')} />

            Solteiro, Casado, Divorciado, Viúvo e Separado
            <SelectSimple mandatory="yes" index={25}
              options={[
                { value: 'SOLTEIRO', label: 'SOLTEIRO' },
                { value: 'CASADO', label: 'CASADO' },
                { value: 'DIVORCIADO', label: 'DIVORCIADO' },
                { value: 'VIUVO', label: 'VIÚVO' },
                { value: 'SEPARADO', label: 'SEPARADO' },
              ]}
              name="marital_status"

              label="ESTADO CIVIL"
            />


            <Input mandatory="yes" replace="letter" name="born_at"
              placeholder={translate('Naturalidade')} />

            <Input mandatory="yes" replace="letter" name="nationality"
              placeholder={translate('Nacionalidade')} />

            <SelectSimple mandatory="yes" index={26} label="ORIGEM ÉTNICA" options={[

              { value: "INDÍGENA", label: 'INDÍGENA', text: 'INDÍGENA' },
              { value: "BRANCA", label: 'BRANCA', text: 'BRANCA' },
              { value: "PRETA", label: 'PRETA', text: 'PRETA' },
              { value: "AMARELA", label: 'AMARELA', text: 'AMARELA' },
              { value: "PARDA", label: 'PARDA', text: 'PARDA' },


            ]} name="race" />

            <MaskedInput mandatory="yes" mask="99" placeholder='DDD DO CELULAR' name="ddd_mobile" />
            <MaskedInput mandatory="yes" mask="999999999" placeholder='TELEFONE CELULAR' name="mobile" />


            <Input mandatory="yes" replace="letter" name="mother_name"
              placeholder={translate('Nome completo da Mãe')} />
            <Input mandatory="yes" replace="letter" name="father_name"
              placeholder={translate('Nome completo do Pai')} />


            <Input mandatory="yes" name="instituicao"
              placeholder={translate('UNIVERSIDADE DE GRADUAÇÃO')} />
            <MaskedInput mandatory="yes" mask="99/99/9999" placeholder='DATA DE CONCLUSÃO DA GRADUAÇÃO' name="university_finish_date" />
            {ticket?.product_id_response?.hash_link?.indexOf('-total') < 0 ? <>
              <h2 style={{ color: '#333', fontSize: '16px', marginTop: '25px' }}>Selecionar a forma de pagamento e gerar o primeiro boleto com vencimento em 20/03/2022, conforme segue:</h2>
              <p style={{ color: '#333', fontSize: '12px', marginTop: '15px' }}>Após a etapa de matrícula, nosso financeiro emitira o(s) boleto(s) e enviará via e-mail.</p>

              <SelectSimple mandatory="yes" index={17} label="Forma de pagamento" options={[

                { value: "Taxa de Matrícula de 850,00 (oitocentos e cinquenta reais) e 12 parcelas (com desconto de 25%) no valor de 1.072,50 (um mil e setenta e dois reais e cinquenta centavos) ", label: 'Taxa de Matrícula de 850,00 (oitocentos e cinquenta reais) e 12 parcelas (com desconto de 25%) no valor de 1.072,50 (um mil e setenta e dois reais e cinquenta centavos) ', text: 'Taxa de Matrícula de 850,00 (oitocentos e cinquenta reais) e 12 parcelas (com desconto de 25%) no valor de 1.072,50 (um mil e setenta e dois reais e cinquenta centavos) ' },
                { value: "Taxa de Matrícula de 850,00 (oitocentos e cinquenta reais) e 14 parcelas (com desconto de 25%) no valor de 922,50 (novecentos e vinte e dois reais e cinquenta centavos)", label: 'Taxa de Matrícula de 850,00 (oitocentos e cinquenta reais) e 14 parcelas (com desconto de 25%) no valor de 922,50 (novecentos e vinte e dois reais e cinquenta centavos)', text: 'Taxa de Matrícula de 850,00 (oitocentos e cinquenta reais) e 14 parcelas (com desconto de 25%) no valor de 922,50 (novecentos e vinte e dois reais e cinquenta centavos)' },
                { value: "Taxa de Matrícula de 850,00 (oitocentos e cinquenta reais) e 16 parcelas de 1.093,00 (um mil e noventa e três reais)", label: 'Taxa de Matrícula de 850,00 (oitocentos e cinquenta reais) e 16 parcelas de 1.093,00 (um mil e noventa e três reais)', text: 'Taxa de Matrícula de 850,00 (oitocentos e cinquenta reais) e 16 parcelas de 1.093,00 (um mil e noventa e três reais)' },



              ]} name="payment_form" /> </> : <></>}

            <br />
            <aside style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <button className="defaultButton" type="submit">
                Salvar


              </button>
            </aside>
          </Form>
        </aside>

        <aside style={{ width: '100%', display: currentStep === 'jaleco' ? 'block' : 'none' }}>
          <div style={{ width: '100%', height: '25px', margin: '25px 0px', background: '#ddd' }} />
          <Form onSubmit={handleDress} key="jaleco" initialData={{ dress_size: profile.dress_size, dress_type: jalecoType || profile.dress_type }}>
            <aside style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <button className="defaultButton" type="submit">
                Salvar


              </button>
            </aside><br />

            <h2 style={{ textAlign: 'center', fontSize: '22px', width: '100%', maxWidth: '500px', color: '#333', margin: '10px auto' }}>Selecione o seu Avental</h2>


            <SelectSimple index={98} setCallback={(value) => {
              setJalecoType(value)
            }} name="dress_type" label="Tipo" options={[
              { label: 'Feminino', text: 'Feminino', value: 'Feminino' },
              { label: 'Masculino', text: 'Masculino', value: 'Masculino' }
            ]} />

            {jalecoType && <SelectSimple index={99} name="dress_size" label="Tamanho do seu avental" options={

              projectHash?.indexOf('ufcspa') >= 0 ? [

                { label: 'PP', text: 'PP', value: 'PP' },
                { label: 'P', text: 'P', value: 'P' },
                { label: 'M', text: 'M', value: 'M' },
                { label: 'G', text: 'G', value: 'G' },
                { label: 'GG', text: 'GG', value: 'GG' },
                { label: 'XG', text: 'XG', value: 'XG' },
                { label: 'XGG', text: 'XGG', value: 'XGG' },
              ] :

                jalecoType === 'Masculino' ?
                  [
                    { label: '40', text: '40', value: '40' },
                    { label: '42', text: '42', value: '42' },
                    { label: '44', text: '44', value: '44' },
                    { label: '46', text: '46', value: '46' },
                    { label: '48', text: '48', value: '48' },
                    { label: '50', text: '50', value: '50' },
                    { label: '52', text: '52', value: '52' },
                    { label: '54', text: '54', value: '54' },
                    { label: '56', text: '56', value: '56' },
                    { label: '58', text: '58', value: '58' },
                  ] :
                  [
                    { label: '36', text: '36', value: '36' },
                    { label: '38', text: '38', value: '38' },
                    { label: '40', text: '40', value: '40' },
                    { label: '42', text: '42', value: '42' },
                    { label: '44', text: '44', value: '44' },
                    { label: '46', text: '46', value: '46' },
                    { label: '48', text: '48', value: '48' },
                    { label: '50', text: '50', value: '50' },
                    { label: '52', text: '52', value: '52' },
                    { label: '54', text: '54', value: '54' },
                    { label: '56', text: '56', value: '56' },
                    { label: '58', text: '58', value: '58' },
                  ]


            } />}

            {jalecoType && <p style={{ color: '#333', margin: '10px 0px' }}><strong>Especificação:</strong></p>}

            {jalecoType === 'Masculino' && projectHash?.indexOf('hcpa') >= 0 && <img src="/apoio/avental-masculino.png" style={{ width: '100%' }} />}
            {projectHash?.indexOf('ufcspa') >= 0 && <img src="/apoio/uniforme-ufcspa.png" style={{ width: '100%' }} />}




            {jalecoType === 'Feminino' && projectHash?.indexOf('hcpa') >= 0 && <img src="/apoio/avental-feminino.png" style={{ width: '100%' }} />}




            <br />
            <aside style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <button className="defaultButton" type="submit">
                Salvar


              </button>
            </aside>



          </Form>
        </aside>

        {configuration && configuration?.register_steps && configuration?.register_steps.findIndex(item => item.hash === 'photo') >= 0 && <aside style={{ width: '100%', display: currentStep === 'foto' ? 'block' : 'none' }}>

          <div style={{ width: '100%', height: '25px', margin: '25px 0px', background: '#ddd' }} />

          <h2 style={{ textAlign: 'center', fontSize: '22px', color: '#333', width: '100%', maxWidth: '500px', margin: '10px auto' }}>Foto para o crachá</h2>
          <p style={{ textAlign: 'justify', color: '#333', width: '100%', maxWidth: '500px', margin: '10px auto' }}>Foto 3x4 com as mesmas características utilizadas em documentos de Identificação (RG, passaporte, etc.); deve conter fundo branco, possuir boa resolução de imagem, estar legível, ser colorida e estar em formato JPG; não serão aceitas fotos com outras pessoas, animais, locais públicos, adornos, óculos de grau, toga, etc. </p>
          <p style={{ textAlign: 'justify', color: '#333', width: '100%', maxWidth: '500px', margin: '10px auto' }}>Exemplo:</p>
          <img src="/apoio/modelo-foto.png" style={{ width: '100%', margin: '0px auto' }} />
          <aside style={{ width: '100%', justifyContent: 'center', alignItems: 'center', gap: '15px', display: 'flex' }}>
            <button type='button' className={uploadType === 'camera' ? 'defaultButtonReverse' : 'defaultButton'} onClick={() => setUploadType('camera')}>Câmera</button>
            <button type='button' className={uploadType === 'anexar' ? 'defaultButtonReverse' : 'defaultButton'} onClick={() => setUploadType('anexar')}>Anexar</button>
          </aside>
          {uploadType === 'anexar' ? <Form onSubmit={(data) => handlePhoto({ photo: data.photo })} initialData={{ photo: profile?.photo }}>
            <UploadCurriculumContent label={'Anexar foto'} name={'photo'} />
            <button className='defaultButton'>Salvar</button>
          </Form> : <></>}

          {uploadType === 'camera' ? <div style={{ width: '100%', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', display: uploadType === 'camera' ? 'flex' : 'none' }} >
            <p style={{ textAlign: 'justify', color: '#333', fontSize: '10px', width: '100%', maxWidth: '500px', margin: '10px auto', marginTop: '20px' }}>
              Caso tenha dificuldade em habilitar a câmera, clique no botão abaixo. Sugerimos o uso do navegador Google Chrome, em sua versão atualizada para esta tarefa
            </p>

            <p style={{ textAlign: 'justify', color: '#fff', background: 'red', fontSize: '14px', padding: '10px', width: '100%', maxWidth: '500px', margin: '10px auto', marginTop: '20px' }}>
              Após tirar a foto, a imagem ficará disponível na parte inferior da tela.<br />
              Após visualizar, clique em Salvar.
            </p>

            <button style={{ margin: '30px auto', background: '#333' }} onClick={() => getMedia()} className='defaultButtonMini'>
              Habilitar câmera
            </button>

            <aside style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }} >     <Webcam
              audio={false}
              height={720}
              screenshotFormat="image/jpeg"
              width={1280}
              mirrored
              key={webcamKey}
              videoConstraints={{
                width: 300,
                height: 400,
                facingMode: "user"
              }}

            >
              {({ getScreenshot }) => (
                <button className='defaultButton'
                  onClick={() => {
                    const imageSrc = getScreenshot();

                    const file = dataURLtoFile(imageSrc, 'photo.jpeg');
                    uploadPhoto({ file })

                  }}
                >
                  Tirar Foto
                </button>
              )}
            </Webcam>

              {photo && <> <button className='defaultButton'
                onClick={() => {
                  handlePhoto({ photo: photo });

                }}
              >
                Salvar
              </button><br /><img src={`${urlWebsite}/${photo}`} style={{ width: '300px' }} />

              </>}

            </aside> </div> : <></>}
        </aside>}

        <aside style={{ width: '100%', display: currentStep === 'vacinas' ? 'block' : 'none' }}>
          <div style={{ width: '100%', height: '25px', margin: '25px 0px', background: '#ddd' }} />
          <Form onSubmit={sendVaccines} initialData={profile?.vaccines}>
            <aside style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <button className="defaultButton" type="submit">
                Salvar


              </button>
            </aside><br />

            <h2 style={{ textAlign: 'center', fontSize: '22px', color: '#333', width: '100%', maxWidth: '500px', margin: '10px auto' }}>Checklist de Vacinas</h2>

            <p style={{ textAlign: 'justify', width: '100%', color: '#333', maxWidth: '500px', margin: '10px auto' }}>

              "Declaro, para fins de direito, sob as penas da Lei, que as informações prestadas que apresento para comprovação das vacinas, são verdadeiras e autênticas. Fico ciente por meio deste documento, que a falsidade dessa declaração configura crime previsto no art. 298 e 299 do Código Penal Brasileiro, passível de apuração na forma da Lei.
              <br /><br />Observação: Eventuais documentos comprobatórios poderão ser exigidos a qualquer tempo."
              <br /><br />Fui vacinado com:</p><br />

            <p style={{ color: '#333', textAlign: 'left' }}>Duas doses de MMR (sarampo, caxumba e rubéola)</p>
            <CheckboxInput name="mmr" options={[
              { label: 'Sim', value: 'Sim', id: 'Sim' },
              { label: 'Não', value: 'Não', id: 'Não' }]
            } />
            <p style={{ color: '#333', textAlign: 'left' }}>Três doses de vacina contra hepatite B</p>
            <CheckboxInput unique name="hepatiteb" options={[
              { label: 'Sim', value: 'Sim', id: 'Sim' },
              { label: 'Não', value: 'Não', id: 'Não' }]
            } />
            <p style={{ color: '#333', textAlign: 'left' }}>Antitetânica</p>
            <CheckboxInput unique name="antitetanica" options={[
              { label: 'Sim', value: 'Sim', id: 'Sim' },
              { label: 'Não', value: 'Não', id: 'Não' }]
            } />
            <p style={{ color: '#333', textAlign: 'left' }}>Vacina contra Covid-19 (Esquema de duas doses, exceto Janssen que é dose única) </p>


            <CheckboxInput unique name="covid19" options={[
              { label: 'Sim', value: 'Sim', id: 'Sim' },
              { label: 'Não', value: 'Não', id: 'Não' }]
            } />

            <br />
            <aside style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <button className="defaultButton" type="submit">
                Salvar


              </button>
            </aside>
          </Form>
        </aside>

        <aside style={{ width: '100%', display: currentStep === 'documentos' ? 'block' : 'none' }}>
          <div style={{ width: '100%', height: '25px', margin: '25px 0px' }} />


          <h2 style={{ textAlign: 'center', fontSize: '22px', color: '#333', width: '100%', margin: '10px auto' }}>Documentação</h2>

          {haveSubscription && !profile?.subscription?.country_type ? <div className='user-block shadow' style={{ overflow: 'hidden' }}>
            <div className='content-block' style={{ padding: '10px' }}>

              <p className='contentTitle'>Você precisa finalizar a ficha de Matrícula antes de submeter a documentação</p>

            </div>
          </div> :


            <Documents />}


        </aside>




      </aside>
    </>
  );
};

export default FormContainer;
