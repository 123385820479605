import React from 'react';

import { IconType } from 'react-icons/lib';

import { Container, ContainerWhite } from './styles';

import Vimeo from '../../components/Vimeo';
import VideoGallery from '../../components/VideoGallery';
import ImageGallery from '../../components/ImageGallery';
import Speakers from '../Speakers';
import { ReciboReceita } from '../DashboardPages/features/ReciboReceita';
import { useParams } from 'react-router-dom';

interface SignInData {
  email: string;
  password: string;
}

interface IPublicData {
  icon: IconType;
  title: string;
  text: string;
}

const ValidarReportReceita: React.FC = () => {
  const { hash } = useParams<Record<string, any>>();

  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'column', width: '100%' }}>
      <img src='/apoio/logo-padrao2.png' style={{ width: '100px' }} />
      <h2 style={{ padding: '20px', color: '#fff', fontSize: '16px', textAlign: 'center' }}>Página de verificação de relatórios</h2>
      <ReciboReceita hash={hash} />
    </div>
  );
};
export default ValidarReportReceita;
