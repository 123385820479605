import React from 'react';
import * as Yup from 'yup';
import { FormHandles } from '@unform/core';
import uuid from '../../../../core/components/UniqueHash';
import { useModal } from '../../../../../../hooks/Modal';
import FormComponent, { sanitizeDataObject } from '../../../../core/components/FormComponentUnique';
import { createSchemaByForm, IYup } from '../../../../../../utils/SchemaCreator';
import getValidationErrors from '../../../../../../utils/getValidationErrors';
import api from '../../../../../../services/api';
import { useToast } from '../../../../../../hooks/Toast';
import { useSearch } from '../../../../../../hooks/Search';
import { AxiosInstance } from 'axios';
import { FaPlus, FaUpload, FaIdBadge, FaBookMedical } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import { useAuth } from '../../../../../../hooks/Auth';
import { FiX } from 'react-icons/fi';

interface ICreateModule {
  lineData: Record<string, any>;
}

interface IParams {
  projectHash: string;
  eventHash: string;
}

export default function CreateModule(): JSX.Element {
  const { reloadSearchAll } = useSearch();
  const { addModal, removeModal } = useModal();
  const { addToast } = useToast();
  const { projectHash, eventHash } = useParams<IParams>();
  const { user } = useAuth();
  const key = uuid();


  const updateData = async (data) => {

    data.map(async (item, index) => {


      await api.put(`/selective-process/${item?._id?.toString()}/register_position`, { register_position: 'Documentação não enviada' })
      addToast({ title: `${item?.client_id_response?.name} - ${index + 1} / ${data?.length}` })
    })
  }


  const transformToWaitingComplete = async ({ status, where, title }) => {

    const projectList = await api.get('/projects', { params: { where: { url: projectHash } } });

    const project = projectList?.data?.rows?.[0];

    const items = await api.get('/selective-process', { params: { limitItems: 10000, where: where } })


    const content = (
      <><p style={{ color: '#333' }}>{title}</p>

        {status === 'red' ? <>
          <p style={{ color: '#333' }}>Ao clicar em Finalizar, todos os participantes abaixo serão alterados para "Documentação não enviada"</p>
          <p style={{ color: '#333', paddingBottom: '10px' }}> {items?.data?.rows?.length} registros</p>
          <button className='defaultButtonMini' style={{ margin: '10px auto' }} onClick={() => updateData(items?.data?.rows)}>Finalizar</button>
        </> : <></>}
        <table className='tableRef'>
          <tbody>
            <tr><td>NOME</td><td>CATEGORIA</td><td>ÁREA</td><td>SUBÁREA</td><td>STATUS</td>
              {project?.register_steps?.map(i => <td>{i?.title}</td>)}
            </tr>
            {items?.data?.rows?.map(i => {
              return <tr><td>{i?.client_id_response?.name}</td><td>{i?.category}</td><td>{i?.area}</td>
                <td>{i?.subarea}</td><td>{i?.status}</td>
                {project?.register_steps?.map(projectStep => {



                  return <td>{i?.register_steps?.[projectStep?.hash]?.status}</td>
                })}

              </tr>
            })}
          </tbody>
        </table>

      </>
    );
    if (content) {
      const keyModal = addModal({ title: 'Atualizar processos', content, key, theme: "whiteModal" });
    }
  };

  const moduleCreate = (): JSX.Element => (
    user && user?.profile === 1 ? <>
      <button className="searchButton" type="button" onClick={() => transformToWaitingComplete({ title: 'Classificados que não enviaram documentação', status: 'red', where: { status: 'register', review_status: 'no', register_position: 'Classificado', project_id: projectHash } })}>
        <FaBookMedical title='Encerrar matrícula para os classificados atuais' color='red' />
      </button>
      <button className="searchButton" type="button" onClick={() => transformToWaitingComplete({ title: 'Classificados em ajuste', status: 'yellow', where: { "$or": [{ status: 'waiting_complete', project_id: projectHash }, { status: 'register', review_status: 'yes', register_position: 'Classificado', project_id: projectHash }] } })}>
        <FaBookMedical title='Aguardando ajuste' color='yellow' />
      </button>
      <button className="searchButton" type="button" onClick={() => transformToWaitingComplete({ title: 'Aprovados ou com documentação revisada e aprovada', status: 'green', where: { "$or": [{ status: 'aprovado', project_id: projectHash }] } })}>
        <FaBookMedical title='Aprovados' color='blue' />
      </button>
    </>
      : <></>
  );

  return moduleCreate();
}
