import React, { useState, useRef, useEffect, useCallback, FC } from 'react';

import {
  FiSearch,
  FiFile,
  FiChevronLeft,
  FiChevronRight,
  FiLock,
} from 'react-icons/fi';



import { FormHandles } from '@unform/core';
import {
  FaYoutube,
  FaTrophy,
  FaFilePdf,
  FaFileExcel,
  FaFilePowerpoint,
  FaStar,
  FaHeart,
  FaArrowLeft,
  FaLock,
  FaLockOpen,
} from 'react-icons/fa';

import { Link, useParams } from 'react-router-dom';
import { AiOutlineReload } from 'react-icons/ai';
import { uuid } from 'uuidv4';
import { LanguageSet } from '../Menu/styles';
import Button from '../Button';
import { useAuth } from '../../hooks/Auth';
import { useModal } from '../../hooks/Modal';
import { useToast } from '../../hooks/Toast';

import { useSocket } from '../../hooks/Socket';

import QuizzComponent from '../QuizzComponent';

import {
  Form,
  Input,
  Container,
  Sidebar,
  Body,
  Header,
  Content,
  AnimatedContainer,
  VideoContainer,
  HeaderBox,
  SidebarMobileButton,
  Modal,
  ButtonColor,
  Card, ContainerSelector, SelectButton, SelectButtonFixed
} from './styles';

import SidebarContent from './Sidebar';
import HeaderContent from './Header';
import api from '../../services/api';
import { useVideo } from '../../hooks/Video';
import Vimeo from '../Vimeo';
import WebsitePopupComponent from '../WebsitePopupComponent';
import ChatRoom from '../Chat';
import Youtube from '../Youtube';
import { authTitle, defaultTheme, urlWebsite } from '../../config';

import { RoomMessagerContainer, RoomMessagerCreator } from '../RoomMessager';
import { RoomChangeContainer, RoomChangeCreator } from '../RoomChange';
import { useLanguage } from '../../hooks/Language';
import launchMeeting from '../ZoomStarter/index.js';
import ZoomContainer from '../ZoomContainerLink';
import Zoom from '../ZoomStarter'

import VideoPlayer from '../VideoPlayer';
import SorteioRoom from '../Sorteio';
import StateManager from 'react-select';
import LessonEmoji from './modules/LessonEmojis';
import Favorite from './modules/Favorite';
import { useInteraction } from '../../hooks/Interactions';
import StatusChanger from './modules/StatusChanger';
import CounterCardRoomSocket from '../../pages/DashboardPages/core/components/CounterCardRoomSocket';
import Select from 'react-select/src/Select';
import { Apoio, ApoioGrid } from '../../pages/Sponsors/styles';
import Whatsapp from '../Whatsapp';
import { AnimatedDiv2 } from '../../pages/SignIn/styles';
import MyTickets from '../../pages/DashboardPages/features/MyTyckets';
import { useConfiguration } from '../../hooks/Configuration';


interface IValidationRules {
  type: 'email' | 'required' | 'min' | 'max' | 'length';
  message?: string;
  quantity?: number;
}

interface IvideoIdItem {
  videoIdItem: string;
  group_link: string;
}

interface IValidationSchema {
  name: string;
  type: 'string' | 'number' | 'boolean';
  label: string;
  rules: Array<IValidationRules>;
}

interface IUploads {
  document: string;
  title?: string;
  id?: number;
  user_name?: string;
}

interface IUploadFile {
  title: string;
  type: 'PDF' | 'PPT' | 'XLS';
  url: string;
}

interface IVideos {
  before: any;
  waiting: any;
  online: any;

}

interface IBlock {
  block: IVideos;
  title: string;
}


const ClassRoomContainer: React.FC = () => {
  const {
    courses,
    setCoursesList,
    videoStatus,
    openVideo,
    setFilter,
    openVideoByID,
    openCategory,
  } = useVideo();
  const { projectHash, eventHash } = useConfiguration();
  const { socket } = useSocket();
  const { translate } = useLanguage();
  const { user, signOut, token, handleApiErrors } = useAuth();
  const [loading, setLoading] = useState(true);
  const [eventDay, setEventDay] = useState(true);
  const [online, setOnline] = useState(true);
  const formRef = useRef<FormHandles>(null);
  const [activeMenu, setActiveMenu] = useState(false);
  const [programActive, setProgramActive] = useState(false);
  const { addToast } = useToast();
  const inputRef = useRef<HTMLInputElement>(null);
  const [uploads, setUploads] = useState<Array<IUploads>>([]);
  const [chatKey, setChatKey] = useState(() => new Date().getMilliseconds());

  const { interactions, addGame } = useInteraction();

  const [allowQuizz, setAllowQuizz] = useState(true);

  const [paymentDecline, setPaymentDecline] = useState(false);

  const asideRef = useRef<HTMLDivElement>(null);

  const { addModal } = useModal();
  const [playerKey, setPlayerKey] = useState('videoPlayer');
  const [dateFilter, setDateFilter] = useState({});
  const [statusFilter, setStatusFilter] = useState(0);
  const [roomFilter, setRoomFilter] = useState({});
  const getVideo = ({ type, id, class_name = '', time = '0', status, room_id, dificulty_id }) => {

    if (type === 2) { return <Vimeo time={time} id={id} keyName={playerKey} />; }
    if (type === 1) { return <Youtube id={id} keyName={playerKey} />; }
    if (type === 3) { return <ZoomContainer dificulty_id={dificulty_id} room_id={room_id} status={status} id={id} class_name={class_name} keyName={playerKey} />; }
    if (type === 4) { return <VideoPlayer url={id} keyName={playerKey} />; }
  }


  const { videoIdItem, group_link } = useParams<IvideoIdItem>();
  const load = async () => {
    if (!projectHash) {
      return;
    }
    setLoading(true);
    try {


      if (user.profile === 2) {

        const responsePayment = await api.get(`/checkPayment/${projectHash}`);
        const dataPayment = responsePayment?.data;

        if (!dataPayment || dataPayment?.status === 401) {
          setPaymentDecline(true);
          setLoading(false);
          return;
        }
      }

      const response = await api.get(group_link ? `/my-courses/category/${group_link}` : '/my-courses');
      const { data } = response;



      if (!data || data[0]?.lessons?.length < 1) {
        setLoading(false);
        setOnline(false);
      } else {

        data.map((courseContent, index) => {

          const daysRef = {};
          const roomsRef = {};

          courseContent.lessons.map((lessonContent, indexLesson) => {
            const currentDate = new Date(lessonContent.release_date);
            const currentDay = currentDate.getUTCDate() < 10 ? `0${currentDate.getUTCDate()}` : currentDate.getUTCDate();
            const currentMonth = (currentDate.getUTCMonth() + 1) < 10 ? `0${currentDate.getUTCMonth() + 1}` : currentDate.getUTCMonth() + 1;

            const currentRef = `${currentDay}.${currentMonth}`;

            if (!daysRef[currentRef]) {
              daysRef[currentRef] = currentRef;
            }

            try {



              data[index].lessons[indexLesson].reference_date = currentRef;

            }
            catch (err) {

            }

            if (!roomsRef[lessonContent.reference]) {

              roomsRef[lessonContent.reference] = lessonContent.room_name || lessonContent.reference;
            }

          })

          const newRoomsKey = Object.keys(roomsRef).sort().reduce(
            (obj, key) => {
              obj[key] = roomsRef[key];
              return obj;
            },
            {}
          );

          setDateFilter(daysRef);
          setRoomFilter(newRoomsKey);
        })

        setLoading(false);
        setCoursesList(data || []);
        setOnline(true);
        if (videoIdItem) {


          openVideoByID(data || [], videoIdItem);
        }
      }

    }
    catch (err) {
      handleApiErrors(err);
    }
  };




  const [videos, setVideos] = useState<IVideos>({
    before: { title: translate('Eventos anteriores'), list: [] },
    waiting: { title: translate('Em breve'), list: [] },
    online: { title: translate('Ao vivo'), list: [] },
  });

  const [filterVideos, setFilterVideos] = useState({
    day: '',
    reference: '',
    favorite: 1
  })

  const [listFavorites, setListFavorites] = useState<Array<number>>([]);

  const addToFilter = (column: string, value: any) => {

    setFilterVideos(state => ({ ...state, [column]: value }));

  }

  const addToFavorite = (id: number) => {

    const index = listFavorites.findIndex(list => list === id);

    if (index < 0) {
      setListFavorites(state => [...state, id]);

    }
    else {
      setListFavorites(state => {
        const response = state.reduce((prev: Array<number>, favorite) => {

          if (favorite !== id) {
            prev.push(id);
          }
          return prev;
        }
          , []);
        return response;
      });
    }

  }

  const sortLesson = (lesson) => {
    let response = false;

    if (filterVideos.day !== '' && lesson.release_date && lesson.reference_date === filterVideos.day) {
      response = true;
    }

    if (filterVideos.day === '') {
      response = true;
    }

    if (filterVideos.reference !== '' && filterVideos.reference !== lesson.reference) {
      response = false;
    }

    if (filterVideos.favorite === 2) {
      const index = interactions?.lesson?.[lesson._id]?.star;
      if (!index) {
        response = false;
      }
    }

    return response;

  }

  const sortVideos = () => {
    const newVideos: IVideos = {
      before: { title: translate('Eventos anteriores'), list: [] },
      waiting: { title: translate('Em breve'), list: [] },
      online: { title: translate('Ao vivo agora'), list: [] },
    }

    {
      videoStatus &&
        videoStatus.type === 'list' &&
        courses[videoStatus.course] &&
        courses[videoStatus.course].lessons.map((lesson, index) => {

          if (sortLesson(lesson)) {

            if (statusFilter !== 0) {
              if (lesson.status === 3) {
                newVideos.before.list.push(lesson);

              }
            }
            else {
              if (lesson.status === 1) {
                newVideos.waiting.list.push(lesson);
              }

              if (lesson.status === 2) {
                newVideos.online.list.push(lesson);
              }

              if (lesson.status === 3) {
                newVideos.before.list.push(lesson);
              }
            }


          }

        })

    };

    setVideos(newVideos);
  }

  useEffect(() => {

    sortVideos();

  }, [courses, filterVideos, interactions, statusFilter]);

  const printLesson = (lesson, index) => {

    const statusSelector = {
      "1": translate('Em breve'),
      "2": <><div className="redBall" /> {translate('Ao vivo')}</>,
      "3": translate('On Demand')
    }



    return (<div key={`videoLesson${lesson.hash}`} style={{ position: 'relative' }}><AnimatedContainer style={{ display: lesson.visible === 2 ? 'flex' : 'none' }}
      key={`videoLesson${lesson._id}`}
      className={`videoBox ${lesson.class_name && lesson.class_name} ${lesson.status && `status${lesson.status}`}`}
      onClick={() => { openVideoByID(courses, lesson._id); if (lesson.status >= 2) { addGame({ reference: 'palestra', id: lesson._id, points: lesson?.room_name === 'Palestra 4.0' || lesson?._id === 226 || lesson?._id === 236 ? 2 : 1 }) } }}
      background={
        lesson.image
          ? `${urlWebsite}/${lesson.image}`
          : ''
      }
    >

      <div className="videoThumbHeader">
        <div>{lesson.start_hour} - {translate(lesson.release_date_formatted)} </div>
        <div className="videoStatus"> {translate(statusSelector[lesson.status]) || translate('Em breve')}</div>
      </div>


      {lesson.image && (<>

        <h2 style={{ color: '#fff' }} dangerouslySetInnerHTML={{ __html: lesson.title }} />
        {lesson.subtitle && <p dangerouslySetInnerHTML={{ __html: lesson.subtitle }} />}



      </>)}

      {!lesson.image && lesson.title && (
        <>
          <h2 style={{ color: '#fff' }} dangerouslySetInnerHTML={{ __html: lesson.title }} />

        </>
      )}


    </AnimatedContainer>
      <div style={{ display: lesson.visible === 2 ? 'flex' : 'none' }} className="lessonFooter">
        <button className="lessonStar"><Favorite key={`lesson-${lesson._id}-star`} type="lesson" id={lesson._id} subtype="star" /></button>

      </div>
    </div> || <></>
    )
  }

  const reloadChat = () => {
    setChatKey(new Date().getMilliseconds());
  };

  const reloadPlayer = () => {
    const d = new Date();
    const n = d.getTime();
    setPlayerKey(`video${n}`);
  };

  useEffect(() => {
    reloadChat();
    load();
    socket.on('disconnectUser', () => {
      /*  
        addToast({
          type: 'error',
          title: translate('Você foi desconectado'),
          description: translate(
            'Seu token expirou ou outra pessoa logou com o seu acesso.',
          ),
        });
        setTimeout(() => {
         // signOut();
         // window.location.href = '/';
        }, 3000); */
    });

    socket.on('checkDisconnectUser', state => {

      if (token !== state.token) {
        /*  addToast({
            type: 'error',
            title: translate('Você foi desconectado'),
            description: translate(
              'Seu token expirou ou outra pessoa logou com o seu acesso.',
            ),
          });
          setTimeout(() => {
         //   signOut();
           // window.location.href = '/';
          }, 3000);*/
      }
    });
    socket.emit('checkUser');
  }, [projectHash]);

  useEffect(() => {
    reloadChat();
    load();
    setProgramActive(false);
  }, [videoIdItem, group_link]);

  const handleSearch = (data: Record<string, any>): void => {
    setFilter(data.search || '');
  };


  const ListRoomByCategory = (column, selector) => {
    const statusSelector = {
      "1": "Em breve",
      "2": <><div className="redBall" /> Online agora</>,
      "3": "On Demand"
    }



    return (
      (videoStatus &&
        videoStatus.type === 'list' &&
        courses[videoStatus.course] &&
        courses[videoStatus.course].lessons.map((lesson, index) => (
          lesson[column] && lesson[column] === selector ?
            <AnimatedContainer
              key={`videoLesson${lesson._id}`}
              className={`videoBox ${lesson.class_name && lesson.class_name} ${lesson.status && `status${lesson.status}`}`}
              onClick={() => openVideo(courses, videoStatus.course, index)}
              background={
                lesson.image
                  ? `${urlWebsite}/${lesson.image}`
                  : '/apoio/card_padrao.png'
              }
            >

              <div className="videoThumbHeader">
                <div>{lesson.start_hour} - {lesson.release_date_formatted} </div>
                <div className="videoStatus"> {statusSelector[lesson.status] || 'Em breve'}</div>
              </div>


              {lesson.image && (<>



                <img
                  className="videoSeta"
                  src="/apoio/seta.png"
                />


              </>)}

              {!lesson.image && lesson.title && (
                <>
                  <h2 dangerouslySetInnerHTML={{ __html: lesson.title }} />
                  <ButtonColor background={lesson.room_color}>

                    {' '}
                    |{lesson.release_date_formatted}
                    {' '}
                    -
                    {lesson.start_hour}
                  </ButtonColor>
                </>
              )}
            </AnimatedContainer> : <></>
        ))) || <></>
    );
  };

  const createRoomSelectors = () => {

    const selectors = [
      { column: 'status', selector: 2, title: "Ao Vivo" },
      { column: 'room_name', selector: 'Espaço 1', title: 'Espaço 1' },
      { column: 'room_name', selector: 'Espaço 2', title: 'Espaço 2' },
      { column: 'room_name', selector: 'Espaço 3', title: 'Espaço 3' },
      { column: 'room_name', selector: 'Espaço 4', title: 'Espaço 4' },
      { column: 'room_name', selector: 'Espaço 5', title: 'Espaço 5' },
      { column: 'room_name', selector: 'Espaço 6', title: 'Espaço 6' }
    ];

    const response = selectors.map(category => <div><h2>{category.title}</h2><div>{ListRoomByCategory(category.column, category.selector)}</div></div>);
    return <ContainerSelector>{response} </ContainerSelector> || <></>;
  }


  const listBlock = ({ block, title }) => {
    return (<>
      {videoStatus &&
        videoStatus.type === 'list' && block &&
        block.list &&
        block.list.length > 0 &&
        <div style={{ display: 'flex', width: '100%', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          <h2 style={{ background: '#333', padding: '10px', color: '#fff', margin: '10px 0px' }}>{title}</h2>
          <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', alignItems: 'center' }}>
            {block.list.map((lesson, index) => (
              printLesson(lesson, index)))}
          </div></div>
      }


    </>)
  }



  const ListRoom = () => {

    return (<>
      {listBlock({ block: videos.online, title: translate('Online Agora') })}
      {listBlock({ block: videos.waiting, title: translate('Em breve') })}
      {listBlock({ block: videos.before, title: translate('On Demand') })}
      {videoStatus &&
        videoStatus.type === 'list' && courses?.[videoStatus?.course]?.sponsors && courses?.[videoStatus?.course]?.sponsors.length > 0 && (

          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-start', minWidth: '350px', width: '100%', maxWidth: '1000px', margin: '10px 0px' }}>
            <h2 style={{ background: '#333', padding: '10px', color: '#fff', marginTop: '10px', marginBottom: '10px' }}>{translate('Patrocínio')}</h2>
            <ApoioGrid size={'M'}>
              {courses?.[videoStatus?.course]?.sponsors && courses?.[videoStatus?.course]?.sponsors.map(apoio => (apoio.link_url ? <a href={apoio.link_url} target="_BLANK" style={{ background: '#fff' }}><Apoio background={`${urlWebsite}/${apoio.url}`} key={apoio.url} /></a> : <div style={{ background: '#fff' }} ><Apoio background={`${urlWebsite}/${apoio.url}`} key={apoio.url} /></div>))}

            </ApoioGrid>
          </div>)
      }
    </>);

  };

  const [option, setOption] = useState(1);

  const getList = (): JSX.Element => {

    return (videoStatus &&
      videoStatus.type === 'list' &&
      courses[videoStatus.course] ? <> <div className="buttonDiv"> <div>
        <button type="button" onClick={() => setOption(1)} className={option === 1 ? 'squareButton' : "squareButtonReverse"}>Ver todos conteúdos</button>
        <button type="button" onClick={() => setOption(2)} className={option === 2 ? 'squareButton' : "squareButtonReverse"}><div className="redBall" /> <span> Ver Online Agora</span></button>
      </div>
      </div>
      {option === 1 ? ListRoom() : ListRoomByCategory('status', 2)}

    </> : <></>
    )



  }

  const getLanguages = (courseIndex, lesson, title = 'Selecionar idioma do vídeo') => {
    if (lesson.languages && (lesson.languages.en || lesson.languages.es)) {
      return (


        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexWrap: 'wrap',
            width: 'auto',
          }}
        >
          <p style={{ color: '#fff' }}>{translate(title)}: </p>
          <LanguageSet>
            <div onClick={() => openVideoByID(courses, lesson.languages.ptBr)}>
              <img
                style={{ margin: 0 }}
                src="/assets/languages/BR.png"
                alt="Portuguese"
              />
            </div>
            {lesson.languages.en && (
              <div onClick={() => openVideoByID(courses, lesson.languages.en)}>
                <img
                  style={{ margin: 0 }}
                  src="/assets/languages/US.png"
                  alt="English"
                />
              </div>
            )}
            {lesson.languages.es && (
              <div onClick={() => openVideoByID(courses, lesson.languages.es)}>
                <img
                  style={{ margin: 0 }}
                  src="/assets/languages/ES.png"
                  alt="Spanish"
                />
              </div>
            )}
          </LanguageSet>
        </div>

      );
    }

    return <></>;
  };

  const ListRoomHeader = () => {

    if (videoStatus?.type !== 'list') {
      return <></>
    }

    return <div className="listHeader">
      <SelectButton active={filterVideos.day === ''} onClick={() => addToFilter('day', '')}><p>{translate('Todos')}</p></SelectButton>


      {Object.keys(dateFilter)?.map(key => <SelectButton active={filterVideos.day === dateFilter[key]} onClick={() => addToFilter('day', dateFilter[key])}><p>{translate(key)}</p></SelectButton>)}

      <SelectButton active={filterVideos.favorite === 2} onClick={() => addToFilter('favorite', filterVideos.favorite === 1 ? 2 : 1)}><FaStar /><p>{translate('Favoritos')}</p></SelectButton>
      <select className="selectButton" onChange={(e) => addToFilter('reference', e.target.value)}>
        <option value="">{translate('TODAS AS SALAS')}</option>


        {Object.keys(roomFilter)?.map((key) => <option value={key}>{roomFilter[key]}</option>)}
      </select>
      <SelectButton active={statusFilter !== 0} onClick={() => setStatusFilter(state => state === 0 ? 3 : 0)}>On Demand</SelectButton>
    </div>
  }

  const OpenRoom = () => {
    const validTypes = {
      PDF: <FaFilePdf />,
      XLSX: <FaFileExcel />,
      PPT: <FaFilePowerpoint />,
    };

    const allow_categories = courses?.[videoStatus?.course]?.lessons?.[videoStatus?.video]?.allow_categories || [];
    const email = user.email || '';

    const uploadFiles: Array<IUploadFile> =
      videoStatus &&
        videoStatus.type === 'video' &&
        courses[videoStatus.course] &&
        courses[videoStatus.course].lessons &&
        courses[videoStatus.course].lessons[videoStatus.video] &&
        courses[videoStatus.course].lessons[videoStatus.video].uploads
        ? courses[videoStatus.course].lessons[videoStatus.video].uploads || []
        : [];


    return (
      (videoStatus &&
        videoStatus.type === 'video' &&
        courses[videoStatus.course] &&
        courses[videoStatus.course].lessons &&
        courses[videoStatus.course].lessons[videoStatus.video] && (
          <>
            <h2 style={{ width: '100%', textAlign: 'center', background: 'rgba(0,0,0,0.5)', color: '#fff', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', padding: '10px' }}>

              <div >
                <SelectButtonFixed style={{ margin: '0px 10px' }} onClick={() => { openCategory(0, 0, group_link); addToFilter('day', ''); }}><FaArrowLeft /> <p>{translate('Palestras')}</p></SelectButtonFixed>

              </div>


              <div style={{ color: '#fff', fontWeight: 400 }}>{courses?.[videoStatus.course]?.lessons?.[videoStatus?.video]?.room_name && <strong style={{ color: 'yellow', display: 'inline' }}>{courses?.[videoStatus.course]?.lessons?.[videoStatus?.video]?.room_name}:</strong>} <p style={{ display: 'inline', textTransform: 'uppercase' }}>{courses[videoStatus.course].lessons[videoStatus.video].title}</p></div>
              <CounterCardRoomSocket room={courses[videoStatus.course].lessons[videoStatus.video]._id} />


            </h2>

            {allow_categories && allow_categories.length > 0 && allow_categories[0] !== '' && user?.profile === 2 && email?.indexOf('@pfizer') < 0 && allow_categories.indexOf(user?.position) < 0 ?

              <div style={{ padding: '0px', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '300px', background: '#fff', margin: '30px auto', padding: '20px', borderRadius: '10px', boxShadow: '3px 2px 10px rgba(0,0,0,0.7)' }}>
                  <FaLock size={70} style={{ margin: '20px 0px' }} color="rgb(0, 14, 64)" />
                  <h2>Atividade exclusiva para:</h2>
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
                    {allow_categories.map(allow => {
                      return <h2 style={{ color: '#fff', background: 'rgb(0, 14, 64)', padding: '10px 20px', margin: '10px 20px' }}>{allow}s</h2>
                    })}

                    <p style={{ width: '100%', maxWidth: '400px', textAlign: 'center', color: 'rgb(0, 14, 64)', padding: '20px' }}>
                      Caso você esteja em uma destas categorias, realize o seu login novamente ou acione o nosso suporte.</p>

                    <Link to="/login"><button className='defaultButton'>Realizar login novamente</button></Link>
                    <aside style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>

                      <Whatsapp number="555180191425" message="Suporte técnico" text={`Olá, estou no ${authTitle}`} />
                    </aside></div>
                </div>
              </div>

              :


              <div className="videoGroup" style={{ padding: '0px' }}>
                <VideoContainer>


                  {courses[videoStatus.course].lessons[videoStatus.video].room_name === 'Espaço 1' && courses[videoStatus.course].lessons[videoStatus.video].status === 3 && courses[videoStatus.course].lessons[videoStatus.video]
                    .time &&
                    <p style={{ fontSize: '14px', textAlign: 'center', background: 'coral', color: '#fff', padding: '10px', margin: "5px" }}>

                      {courses[videoStatus.course].lessons[videoStatus.video]
                        .time && <> {`Esta palestra inicia aos ${courses[videoStatus.course].lessons[videoStatus.video]
                          .time} do vídeo`}</>}
                    </p>
                  }

                  <RoomMessagerContainer
                    room_id={
                      courses &&
                        courses[videoStatus.course] &&
                        courses[videoStatus.course].lessons &&
                        courses[videoStatus.course].lessons[videoStatus.video]
                        ? courses[videoStatus.course].lessons[videoStatus.video]
                          ._id
                        : ''
                    }
                    module_id={1}
                  />

                  {user.profile !== 9 && <div className="computerOnly" style={{ width: 'auto', margin: '5px auto', padding: '5px', borderRadius: '10px', background: 'rgba(0,0,0,0.5)' }}>{getLanguages(
                    videoStatus.course,
                    courses[videoStatus.course].lessons[videoStatus.video],
                  )}</div>}
                  {user && user.profile === 99 && <HeaderBox>
                    {videoStatus.prevVideo ? (
                      <button
                        className="defaultButton"
                        type="button"
                        onClick={() => {
                          videoStatus.prevVideo &&
                            openVideo(
                              courses,
                              videoStatus.prevVideo.courseIndex,
                              videoStatus.prevVideo.videoIndex,
                            );
                        }}
                      >
                        <FiChevronLeft />
                        Anterior
                      </button>
                    ) : (
                      <button type="button" className="defaultButton" />
                    )}
                    <button
                      onClick={() => openCategory(0, 0, projectHash)}
                      type="button"
                      className="defaultButton"
                    >
                      + Palestras
                    </button>
                    <button
                      onClick={() => setProgramActive(true)}
                      className="defaultButton"
                      type="button"
                    >
                      Programação
                    </button>
                    {videoStatus.nextVideo ? (
                      <button
                        className="defaultButton"
                        type="button"
                        onClick={() => {
                          videoStatus.nextVideo &&
                            openVideo(
                              courses,
                              videoStatus.nextVideo.courseIndex,
                              videoStatus.nextVideo.videoIndex,
                            );
                        }}
                      >
                        Próximo
                        <FiChevronRight />
                      </button>
                    ) : (
                      <button type="button" className="defaultButton" />
                    )}
                  </HeaderBox>}

                  {user && user.profile === 9 && (
                    <Zoom room_id={courses[videoStatus.course].lessons[videoStatus.video]
                      ._id} />
                  )

                  }
                  {user && user.profile !== 6 && user.profile !== 9 && (
                    <>
                      <div className="videoContainer">
                        {getVideo({
                          type: courses[videoStatus.course].lessons[videoStatus.video].video_type_id,
                          id: courses[videoStatus.course].lessons[videoStatus.video].video_id,
                          class_name: courses[videoStatus.course].lessons[videoStatus.video].class_name,
                          time: courses[videoStatus.course].lessons[videoStatus.video].time || '0',
                          status: courses[videoStatus.course].lessons[videoStatus.video].status,
                          room_id: courses[videoStatus.course].lessons[videoStatus.video]._id,
                          dificulty_id: courses[videoStatus.course].lessons[videoStatus.video].dificulty_id
                        }
                        )}

                        <StatusChanger dificulty_id={courses?.[videoStatus?.course]?.lessons?.[videoStatus?.video]?.dificulty_id} id={courses?.[videoStatus?.course]?.lessons?.[videoStatus?.video]?._id} status={courses?.[videoStatus?.course]?.lessons?.[videoStatus?.video]?.status} class_name={courses?.[videoStatus?.course]?.lessons?.[videoStatus?.video]?.class_name} type={courses?.[videoStatus?.course]?.lessons?.[videoStatus?.video]?.video_type || courses?.[videoStatus?.course]?.lessons?.[videoStatus?.video]?.video_type_id} />

                        <LessonEmoji reference="Palestra" room={courses[videoStatus.course].lessons[videoStatus.video].chat_id || courses[videoStatus.course].lessons[videoStatus.video]._id} />
                      </div>
                      {courses[videoStatus.course].lessons[videoStatus.video]
                        .video_type_id !== 3 && <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            padding: '5px 0px'

                          }}
                        >
                          {user.profile !== 9 && <div className="mobileOnly">{getLanguages(
                            videoStatus.course,
                            courses[videoStatus.course].lessons[videoStatus.video], 'Selecionar Idioma'
                          )}</div>}
                          <button
                            style={{ margin: '10px', minWidth: '150px', background: '#fff' }}
                            title="Player not working? Click here!"
                            className="reloadPlayer"
                            onClick={() => reloadPlayer()}
                          >
                            <AiOutlineReload />
                            {' '}
                            {translate('Recarregar vídeo')}
                          </button>
                        </div>}
                    </>
                  )}



                </VideoContainer>
                {courses[videoStatus.course].lessons[videoStatus.video]
                  .video_type_id !== 3 &&
                  <div style={{ position: 'relative' }}>


                    <ChatRoom key={`${courses[videoStatus.course].lessons[videoStatus.video].chat_id || courses[videoStatus.course].lessons[videoStatus.video]._id}_chat`} slides={courses?.[videoStatus.course]?.lessons?.[videoStatus.video]?.slides || []}
                      room_name={courses?.[videoStatus.course]?.lessons?.[videoStatus.video]?.reference || ''} poster_id={courses?.[videoStatus.course]?.lessons?.[videoStatus.video]?.poster_id}
                      id={courses[videoStatus.course].lessons[videoStatus.video].chat_id || courses[videoStatus.course].lessons[videoStatus.video]._id}
                    />

                    <QuizzComponent
                      roomNumber={
                        courses[videoStatus.course].lessons[videoStatus.video]._id
                      }
                    />

                    {user && user.profile === 1 && (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          padding: '5px',
                        }}
                      >
                        <WebsitePopupComponent
                          roomNumber={
                            courses &&
                              courses[videoStatus.course] &&
                              courses[videoStatus.course].lessons &&
                              courses[videoStatus.course].lessons[videoStatus.video]
                              ? courses[videoStatus.course].lessons[
                                videoStatus.video
                              ]._id
                              : ''
                          }
                        />
                        <RoomChangeCreator
                          room_id={
                            courses &&
                              courses[videoStatus.course] &&
                              courses[videoStatus.course].lessons &&
                              courses[videoStatus.course].lessons[videoStatus.video]
                              ? courses[videoStatus.course].lessons[
                                videoStatus.video
                              ]._id
                              : ''
                          }
                          module_id={1}
                        />
                      </div>
                    )}

                    {user &&
                      (user.profile === 1 ||
                        user.profile === 4 ||
                        user.profile === 6) && (
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            padding: '5px',
                          }}
                        >
                          <SorteioRoom id={courses &&
                            courses[videoStatus.course] &&
                            courses[videoStatus.course].lessons &&
                            courses[videoStatus.course].lessons[videoStatus.video]
                            ? courses[videoStatus.course].lessons[
                              videoStatus.video
                            ]._id
                            : 0} />
                          <RoomMessagerCreator
                            room_id={
                              courses &&
                                courses[videoStatus.course] &&
                                courses[videoStatus.course].lessons &&
                                courses[videoStatus.course].lessons[videoStatus.video]
                                ? courses[videoStatus.course].lessons[
                                  videoStatus.video
                                ]._id
                                : ''
                            }
                            module_id={1}
                          />
                        </div>
                      )}
                  </div>}
              </div>}
          </>
        )) || <></>
    );
  };

  const showTrophy = () => {
    addToast({
      type: 'info',
      title: 'Nenhum troféu identificado',
      description: 'Continue sua jornada para alcançar novas conquistas',
    });
  };

  if (loading) {
    return <Container />;
  }

  if (paymentDecline) {

    return <Container style={{ minHeight: '70vh', flexDirection: 'column', justifyContent: 'flex-start', paddingTop: '100px', alignItems: 'center' }}>

      <AnimatedDiv2 style={{ background: '#fff', maxWidth: '440px', borderRadius: '10px', padding: '110px 20px', boxShadow: '2px 2px 5px rgba(0,0,0,0.3)' }}>

        <img src="/apoio/logo-padrao2.png" style={{ width: '100%', maxWidth: '300px', margin: '10px auto' }} />

        <h2 style={{ fontSize: '24px', textAlign: 'center', marginTop: '20px' }}>{translate('Inscrição pendente')}</h2><br />
        <p style={{ color: '#333', fontSize: '14px', textAlign: 'center' }}>{translate('Seu pagamento consta como pendente, caso tenha pago com boleto, a confirmação pode demorar até 3 dias úteis.')}</p>



      </AnimatedDiv2>
      <MyTickets />

    </Container>;


  }

  if (!online) {

    return <Container style={{ minHeight: '70vh', flexDirection: 'column', justifyContent: 'flex-start', paddingTop: '100px', alignItems: 'center' }}>

      <AnimatedDiv2 style={{ background: '#fff', maxWidth: '440px', borderRadius: '10px', padding: '110px 20px', boxShadow: '2px 2px 5px rgba(0,0,0,0.3)' }}>

        <img src="/apoio/logo-padrao2.png" style={{ width: '100%', maxWidth: '300px', margin: '10px auto' }} />

        <h2 style={{ fontSize: '24px', textAlign: 'center', marginTop: '20px', color: '#333' }}>{translate('Inscrição confirmada')}</h2>



        {/* <br/><br/>
                Retire seu certificado no link abaixo, 20 dias após o evento.
              <br/><br/>
              <aside style={{width:'100%', display:'flex', justifyContent:'center'}}>
              <Link to="/certificados">
              <button className="defaultButton" type="button">
                {' '}
                {translate('Certificados')}
              </button>
            </Link></aside>
             
            </p>
        <p style={{color: '#333',fontSize:'14px', textAlign:'center', marginTop: '30px'}}>{translate('Caso tenha adquirido um lote de ingressos, clique no botão abaixo para visualizá-los')}</p>
              <Link to="/meus-codigos"><button className='defaultButton'>Visualizar</button></Link>*/}
      </AnimatedDiv2>

      <MyTickets />
    </Container>;


  }

  return (
    <Container background={videoStatus &&
      videoStatus.type === 'video' &&
      courses[videoStatus.course] &&
      courses[videoStatus.course].lessons &&
      courses[videoStatus.course].lessons[videoStatus.video] &&
      courses[videoStatus.course].lessons[videoStatus.video].background_image || ''}>
      <Container style={{
        background: videoStatus &&
          videoStatus.type === 'video' &&
          courses[videoStatus.course] &&
          courses[videoStatus.course].lessons &&
          courses[videoStatus.course].lessons[videoStatus.video] &&
          courses[videoStatus.course].lessons[videoStatus.video].background_image ? 'rgba(0,0,0,0.5)' : 'none'
      }}>
        <Content>

          <Body >

            {eventDay || user.profile === 1 || user.profile > 2 ? (
              <>

                {ListRoomHeader()}
                {ListRoom()}
                {OpenRoom()}
              </>
            ) :

              (<div style={{ minHeight: '80vh', display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}><div className="onlineMessage">

                <h2 style={{ color: '#fff' }} className="defaultMarked"><div />{translate('Inscrição Confirmada')}</h2>
                <p style={{ color: 'rgb(51, 51, 51)', fontSize: '14px', textAlign: 'center', marginTop: '20px', fontWeight: 'bold' }}>

                  <br /><br />


                </p><br /><br />
                <Link to="/">
                  <button className="defaultButton" type="button">
                    {' '}
                    {translate('Sair')}
                  </button>
                </Link>
              </div></div>)



            }
          </Body>
        </Content>

        {programActive && (
          <Modal top right>
            <div
              style={{
                display: 'block',
              }}
            >
              <Button onClick={() => setProgramActive(false)}>
                {translate('Fechar')}
                {' '}
              </Button>


            </div>
          </Modal>
        )}
        <RoomChangeContainer />
      </Container>
    </Container>
  );
};

export default ClassRoomContainer;
