import { Form } from '@unform/web';
import { addDays, endOfHour, parseISO } from 'date-fns';
import { userInfo } from 'os';
import React, { useEffect, useState } from 'react';
import { FaBell, FaCertificate, FaFile, FaIdBadge, FaImages, FaQuestionCircle, FaReceipt, FaRegIdBadge, FaSubscript, FaTicketAlt, FaTrophy, FaUser } from 'react-icons/fa';
import { FiMessageCircle } from 'react-icons/fi';

import { IconType } from 'react-icons/lib';
import { useParams } from 'react-router-dom';
import CheckboxInput from '../../../../../../components/Forms/CheckboxInput';
import { urlWebsite } from '../../../../../../config';
import { useAuth } from '../../../../../../hooks/Auth';
import { useConfiguration } from '../../../../../../hooks/Configuration';
import { useModal } from '../../../../../../hooks/Modal';
import { useSearch } from '../../../../../../hooks/Search';
import { useToast } from '../../../../../../hooks/Toast';
import api from '../../../../../../services/api';
import { date, dateFullWithHour, simpleDate, simpleDateUS } from '../../../../../../utils/date';
import InOut from '../../../../../DashboardPages/core/components/Forms/InOut';
import Loader from '../../../../../DashboardPages/core/components/Loader';
import MedicalRegistration from '../../../../../DashboardPages/features/MedicalRegistration';
import { banks } from '../../../../../DashboardPages/features/MedicalRegistration/FormContainer/banks';
import DatePickerHour from '../../../../core/components/Forms/DatePickerHour';


import SelectAction from '../../../../core/components/Forms/SelectAction';
import SelectSimple from '../../../../core/components/Forms/SelectSimple';
import TextareaInput from '../../../../core/components/Forms/TextareaInput';
import useDataHistory from '../../../SelectiveProcess/modules/RegistrationConfirmation/hook/useDataHistory';
import VisualizateDocuments from './modules/visualizateDocuments';
import { Container, ContainerWhite } from './styles';

interface IProps {
  lineData: Record<string, any>;

}

const ConfirmateRegistration: React.FC<IProps> = ({ lineData }) => {
  const { openHistory } = useDataHistory();

  const [profile, setProfile] = useState<Record<string, any>>(lineData)

  const { projectHash, configuration } = useConfiguration();
  const [loading, setLoading] = useState(false);
  const { user } = useAuth();

  const { addToast } = useToast();
  const { removeModal } = useModal();
  const { addModal } = useModal();
  const [review, setReview] = useState<Record<string, any>>(lineData?.review || {});
  const { reloadSearchAll } = useSearch();

  const sendReview = async (data, reviewInfo) => {


    let errorMessages: Array<string> = []
    /* check if all is set */
    Object.keys(review).map(key => {

      Object.keys(review[key]).map(propertyKey => {


        if (!review?.[key]?.[propertyKey]?.value) {
          errorMessages.push(`${key} - ${propertyKey}`)
        }


      })



    })

    /*
        if (errorMessages.length > 0) {
    
          addModal({
            title: '', content: <><h2 style={{ padding: '10px', color: '#333', textAlign: 'center', fontSize: '14px' }}>
    
    
              Há documentos / dados pendentes de avaliação.
    
    
            </h2></>, theme: 'whiteModalMedium'
          })
    
          return;
        }
    */




    if (!data.status) {
      addToast({ title: 'Informe o status da documentação', type: 'error' });
      return;
    }

    if (!data.register_change_date) {
      addToast({ title: 'Não remova a data de referência', type: 'error' });
      return;
    }

    setLoading(true);

    const dateText = dateFullWithHour(data.register_change_date)

    const payload = {
      review: { ...reviewInfo, dateText, register_change_date: data.register_change_date, justification: { label: 'Observações', value: data.justification }, status: { label: 'Status da documentação', value: data.status } }

    }

    try {
      const response = await api.post(`/send-documents-review/${lineData._id.toString()}`, { review: payload.review });
      addToast({ title: 'Enviado com sucesso', type: 'success' });
      setLoading(false);
      reloadSearchAll();
      removeModal('documents')
      return;
    }
    catch (err) {
      addToast({ title: err?.response?.data?.message || 'Erro ao enviar', type: 'error' });
      setLoading(false);
      return;
    }

  }




  useEffect(() => {

    const newReview = {};



    if (configuration?.register_steps) {
      configuration?.register_steps.map(step => {

        newReview[step.hash] = {};

        if (step.hash === 'jaleco') {
          newReview['jaleco']['dress_type'] = { label: 'Tipo de Jaleco', value: review?.['jaleco']?.['dress_type'] ? review['jaleco']['dress_type'].value : '' };
          newReview['jaleco']['dress_size'] = { label: 'Tamanho do Jaleco', value: review?.['jaleco']?.['dress_size'] ? review['jaleco']['dress_size'].value : '' };
        }
        if (step.hash === 'photo') {
          newReview['photo']['photo'] = { label: 'Foto do Crachá', value: review?.['photo']?.['photo'] ? review['photo']['photo'].value : '' };

        }




        if (configuration?.[`${step.hash}_configuration`]) {

          let stepInfo = `${step.hash}_configuration`;

          if (step.hash === 'documents') {
            stepInfo = profile?.category?.toUpperCase() === 'PRÉ-REQUISITOS' ? 'documents_configuration_pre_requisitos' : 'documents_configuration';

          }


          configuration?.[stepInfo].map(conf => {

            newReview[step.hash][conf.hash] = { label: conf.title, value: review?.[step.hash]?.[conf.hash] ? review[step.hash][conf.hash].value : '' }

          })

        }




      })
    }

    setReview(newReview)

  }, [profile, configuration])

  const getReviewValues = (category) => {

    const values = {};

    if (review?.[category]) {
      Object.keys(review?.[category]).map(key => {

        values[key] = review?.[category][key]?.value;
      })
    }



    return values;



  }

  const updateReview = (category, subcategory, value) => {

    const newReview = { ...review };

    if (!newReview[category]) {
      newReview[category] = {};
    }

    if (!newReview[category]) {
      newReview[category] = {}
    }

    if (!newReview[category][subcategory]) {
      newReview[category][subcategory] = {}
    }

    newReview[category][subcategory].value = value;

    setReview({ ...newReview })




  }

  const confirmRegistration = async (data) => {

    setLoading(true);


    if (!data?.term?.[0]) {
      setLoading(false);
      addToast({ title: 'Por favor, confirme o termo ao final da listagem', type: 'error' });
      return;
    }

    try {
      const response = await api.post(`/selective-process-confirm-registration/${profile._id.toString()}`, { term: data?.term?.[0] });
      addToast({ title: 'Confirmação realizada com sucesso', type: 'success' });

      setTimeout(() => {
        window.location.reload();
      }, 2000)
      setLoading(false);
      return;



    }
    catch (err) {
      setLoading(false);
      addToast({ title: err?.response?.data?.message || 'Erro ao realizar envio', type: 'error' });
      return;
    }





  }




  if (!profile?.client_id_response) {
    return <Container >   <div className='user-block shadow' style={{ overflow: 'hidden' }}>
      <div className='content-block' style={{ padding: '10px' }}>
        <nav style={{ background: '#333', padding: '30px', margin: '20px auto', color: '#fff', borderRadius: '15px' }}>Carregando ...</nav>

      </div></div>
    </Container>
  }

  if (profile?.event_profile?.[projectHash]?.allow_registration === 'no') {
    return <Container >

      <div className='user-block shadow' style={{ overflow: 'hidden' }}>
        <div className='content-block' style={{ padding: '10px' }}>

          <p className='contentTitle'>Matrícula não autorizada.</p>

        </div>
      </div>
    </Container>
  }


  const getBank = (value) => {

    const item = banks.find(bank => bank.value === value);


    if (item) {

      return `(${item.value}) ${item.label}`;
    }

    return ''


  }



  if (profile.status !== 'register' && profile.status !== 'waiting_complete' && profile.status !== 'aprovado') {
    return <Container>
      <div className='user-block shadow'  >
        <h2 style={{ color: "#333", padding: '20px', margin: '0px auto' }}>Etapa de Matrícula não liberada</h2>
      </div>
    </Container>
  }

  if (profile?.register_position !== 'Classificado') {
    return <Container >   <div className='user-block shadow' style={{ overflow: 'hidden' }}>
      <div className='content-block' style={{ padding: '10px' }}>
        <nav style={{ background: '#ddd', padding: '30px', margin: '20px auto', color: '#333', borderRadius: '15px' }}>Sua matrícula não está liberada<br />

          <strong>Seu status: {profile?.register_position}</strong>
        </nav>

      </div></div>
    </Container>
  }

  return <Container>

    {loading === true && (
      <Loader message="Preparando envio..." position="fixed" />
    )}

    <div className='user-block shadow'  >
      <div className='content-block' style={{ padding: '10px' }}>

        <p className='contentTitle'>Revisão de dados</p>
        {profile.status === 'waiting_complete' ? <nav style={{ background: 'rgb(0,200,0)', padding: '30px', margin: '20px auto', color: '#fff', borderRadius: '15px' }}>Envio Confirmado</nav> : <></>}



        {configuration?.register_steps && configuration?.register_steps?.findIndex(step => step?.hash === 'subscription') >= 0 ? <Form initialData={review?.['subscription'] ? getReviewValues('subscription') : {}} onSubmit={() => { }}>
          <table><tbody>

            <tr key={`sub_name`}><td>NOME</td><td>{profile?.name}</td><td> </td><td> </td></tr>
            <tr key={`sub_cpf`}><td>CPF</td><td>{profile?.document_number}</td><td> </td><td> </td></tr>
            <tr key={`sub_email`}><td>E-MAIL</td><td>{profile?.email}</td><td> </td><td> </td></tr>

            {configuration?.subscription_configuration?.map(sub => {

              return <tr key={sub.hash}><td>{sub.title}: </td><td>{profile?.subscription?.[sub.hash]}</td><td><SelectAction name={sub.hash} setCallback={(value) => updateReview('subscription', sub.hash, value)} /></td>

                <td>
                  <button className='historyIcon' onClick={() => openHistory(lineData?._id, 'subscription', sub.hash)}>
                    <FaReceipt />
                  </button>
                </td>

              </tr>

            })}
          </tbody></table>
        </Form> : <></>}

        {configuration?.register_steps && configuration?.register_steps?.findIndex(step => step?.hash === 'jaleco') >= 0 ? <> <h2>Jaleco</h2>

          <Form initialData={{ dress_type: review?.jaleco?.dress_type?.value, dress_size: review?.jaleco?.dress_size?.value, }} onSubmit={() => { }}>
            <table><tbody>


              <tr key={'dress_type'}><td>Tipo de Jaleco: </td><td>{profile?.dress_type}</td><td><SelectAction name={'dress_type'} setCallback={(value) => updateReview('jaleco', 'dress_type', value)} /></td>
                <td>
                  <button className='historyIcon' onClick={() => openHistory(lineData?._id, 'jaleco', 'dress_type')}>
                    <FaReceipt />
                  </button>
                </td>


              </tr>
              <tr key={'dress_size'}><td>Tamanho: </td><td>{profile?.dress_size}</td><td><SelectAction name={'dress_size'} setCallback={(value) => updateReview('jaleco', 'dress_size', value)} /></td>
                <td>
                  <button className='historyIcon' onClick={() => openHistory(lineData?._id, 'jaleco', 'dress_size')}>
                    <FaReceipt />
                  </button>
                </td>
              </tr>

            </tbody></table>
          </Form> </> : <></>}

        {configuration?.register_steps && configuration?.register_steps?.findIndex(step => step?.hash === 'vaccines') >= 0 ? <> <h2>Vacinas</h2>

          <Form initialData={review?.['vaccine'] ? getReviewValues('vaccine') : {}} onSubmit={() => { }}>
            <table><tbody>
              {configuration?.vaccine_configuration?.map(sub => {

                return <tr key={sub.hash}><td>{sub.title}: </td><td>{profile?.vaccine?.[sub.hash]}</td><td><SelectAction name={sub.hash} setCallback={(value) => updateReview('vaccine', sub.hash, value)} /></td>
                  <td>
                    <button className='historyIcon' onClick={() => openHistory(lineData?._id, 'vaccine', sub.hash)}>
                      <FaReceipt />
                    </button>
                  </td>

                </tr>

              })}
            </tbody></table>
          </Form></> : <></>}

        {configuration?.register_steps && configuration?.register_steps?.findIndex(step => step?.hash === 'photo') >= 0 ? <Form initialData={{ photo: review?.photo?.photo?.value }} onSubmit={() => { }}>
          <table><tbody>


            <tr key={'photo'}><td>Foto do Crachá: </td><td><img src={`${urlWebsite}/${profile?.photo}`} style={{ width: '150px' }} /></td><td><SelectAction name={'photo'} setCallback={(value) => updateReview('photo', 'photo', value)} /></td>

              <td>
                <button className='historyIcon' onClick={() => openHistory(lineData?._id, 'photo', 'photo')}>
                  <FaReceipt />
                </button>
              </td>
            </tr>

          </tbody></table>
        </Form> : <></>}
        {configuration?.register_steps && configuration?.register_steps?.findIndex(step => step?.hash === 'documents') >= 0 ? <Form initialData={{ ...getReviewValues('documents') }} onSubmit={() => { }}>
          <VisualizateDocuments lineData={profile} review={review} updateReview={updateReview} />
        </Form> : <></>}


        <Form initialData={{ justification: review.justification?.value, register_change_date: endOfHour(addDays(new Date(), 1)) }} onSubmit={(data) => sendReview(data, review)}>

          <h2>Observações</h2>
          <TextareaInput name="justification" />

          <h2>Status da documentação</h2>
          <SelectSimple name="status" label='' options={[
            { label: 'Aprovado', value: "Aprovado" },
            { label: 'Solicitação de ajustes', value: "Ajustes" },
            { label: 'Documentação reprovada', value: "Reprovada" },
          ]}></SelectSimple>

          <h2>Prazo para alteração</h2>
          <DatePickerHour name="register_change_date" placeholder='' />
          <button className='defaultButton'>Finalizar</button>
        </Form>


        {profile.status === 'register' && lineData.review_status !== 'yes' ? <Form onSubmit={confirmRegistration}>
          <CheckboxInput
            style={{ display: 'flex' }}
            name="term"
            unique={true}

            options={[
              {
                id: `DECLARO que apresento, neste momento, os documentos necessários para a MATRÍCULA. DECLARO que as informações aqui prestadas e os documentos apresentados são verdadeiros. DECLARO ciência  de que a comprovação da autenticidade poderá ser averiguada a qualquer momento, mesmo após a matrícula e que, se constatada falsidade ou divergência das informações, poderei responder por crime contra a fé pública, nos termos da legislação vigente, o que também acarretará a perda da vaga.`,
                label: 'DECLARO que apresento, neste momento, os documentos necessários para a MATRÍCULA. DECLARO que as informações aqui prestadas e os documentos apresentados são verdadeiros. DECLARO ciência  de que a comprovação da autenticidade poderá ser averiguada a qualquer momento, mesmo após a matrícula e que, se constatada falsidade ou divergência das informações, poderei responder por crime contra a fé pública, nos termos da legislação vigente, o que também acarretará a perda da vaga.',
                value: `DECLARO que apresento, neste momento, os documentos necessários para a MATRÍCULA. DECLARO que as informações aqui prestadas e os documentos apresentados são verdadeiros. DECLARO ciência  de que a comprovação da autenticidade poderá ser averiguada a qualquer momento, mesmo após a matrícula e que, se constatada falsidade ou divergência das informações, poderei responder por crime contra a fé pública, nos termos da legislação vigente, o que também acarretará a perda da vaga.`,
              },

            ]}
          />

          <button className='defaultButton'>Finalizar</button>
        </Form> : <></>}
      </div>

    </div>




  </Container>


};


export function useVisualizateRegister({ lineData }) {

  const { projectHash } = useParams<Record<string, any>>();
  const { addToast } = useToast();
  const { addModal, removeModal } = useModal();
  const { configuration } = useConfiguration();
  const { reloadSearchAll } = useSearch();





  const gerarRelatorioFinanceiro = async () => {


    const line = await api.get(`/user-medic-subscription/${lineData._id}`);





    addModal({
      title: 'Visualizar Documentação',
      content: <>

        <ConfirmateRegistration lineData={line.data} />

      </>,
      theme: 'whiteModal',
      key: 'documents',

    })


  }

  const moduleUpdate = (): JSX.Element => (
    projectHash && (lineData.status === 'waiting_complete' || lineData.status === 'aprovado' || lineData.review_status === 'yes' || lineData.term) ? <button className='defaultButtonMini' style={{ background: lineData?.curriculum_recurse_avaliation_finished !== 'yes' ? 'orange' : lineData.curriculum_recurse_avaliation_status === 'yes' ? 'rgb(0,150,0)' : 'rgb(250,50,0)', color: '#fff' }} type="button" onClick={() => gerarRelatorioFinanceiro()}>
      Visualizar Documentação </button> : <></>
  );

  return moduleUpdate();
}



