import React, { useEffect, useState } from 'react';

import {
  SearchComponent,
  ISearchComponent,
} from '../../core/components/SearchComponent';

import lineModules from '../../core/components/lineModules'
import { Container } from './styles';
import { useParams } from 'react-router-dom';
import { useConfiguration } from '../../../../hooks/Configuration';
import { useAvaliateCurriculum as avaliateCurriculum } from './modules/avaliateCurriculum';

import { useAvaliateCurriculum as visualizateCurriculumRecurse } from './modules/visualizateCurriculumRecurse';

import { useVisualizateRegister as visualizateRegister } from './modules/RegistrationConfirmation';
import { useAuth } from '../../../../hooks/Auth';
import api from '../../../../services/api';
import downloadit from '../../../../utils/download';
import { FaFileExcel } from 'react-icons/fa';

interface ValidationRules {
  type: 'email' | 'required' | 'min' | 'max' | 'length';
  message?: string;
  quantity?: number;
}

interface ValidationSchema {
  name: string;
  type: 'string' | 'number' | 'boolean';
  label: string;
  rules: Array<ValidationRules>;
}


const SelectiveProcessEquipe: React.FC = () => {

  const title = 'Processo Seletivo';
  const { projectHash, eventHash, configuration } = useConfiguration();
  const endpoint = `/selective-process-equipe/${projectHash}`;
  const { user } = useAuth();
  const schema: Record<string, any> = {
    _id: { ref: '_id', column: '_id', label: 'ID', show: false },
    project_id: { ref: 'project_id', column: 'project_id', label: 'Projeto' },
    review_status: { ref: 'review_status', column: 'review_status', label: 'Foi revisado?', show: true },
    test_grade: { ref: 'test_grade', column: 'test_grade', label: 'Nota na prova', show: false },
    client_id: {
      ref: 'client_id',
      column: 'client_id_response',
      includeColumn: 'name',
      label: 'Cliente',
    },
    avaliator_id: {
      ref: 'avaliator_id',
      column: 'avaliator_id_response',
      includeColumn: 'name',
      label: 'Avaliador', show: false
    },
    status: { ref: 'status', column: 'status', label: 'Status', show: true },
    category: { ref: 'category', column: 'category', label: 'Categoria' },
    area: { ref: 'area', column: 'area', label: 'Área' },
    subarea: { ref: 'subarea', column: 'subarea', label: 'Subárea' },
    register_position: { ref: 'register_position', column: 'register_position', label: 'Status de Matrícula', show: true },
    register_documents: { ref: 'register_documents', column: 'register_documents', label: 'Documentos de Matrícula', show: false },

    register_change_date: { ref: 'register_change_date', column: 'register_change_date', label: 'Data de reenvio', show: true },


    register_position_number: { ref: 'register_position_number', column: 'register_position_number', label: 'Posição de classificação', show: true },
    photo: { ref: 'photo', column: 'photo', label: 'Foto', show: false },
    dress_type: { ref: 'dress_type', column: 'dress_type', label: 'Gênero do uniforme', show: false },
    dress_size: { ref: 'dress_size', column: 'dress_size', label: 'Tamanho do uniforme', show: false },

    subscription: { ref: 'subscription', column: 'subscription', label: 'Matrícula', show: false },
    vaccines: { ref: 'vaccines', column: 'vaccines', label: 'Vacinas', show: false },
    curriculum: { ref: 'curriculum', column: 'curriculum', label: 'Currículo', show: false },

    curriculum_points: { ref: 'curriculum_points', column: 'curriculum_points', label: 'Pontuação do Currículo', show: false },
    curriculum_avaliation: { ref: 'curriculum_avaliation', column: 'curriculum_avaliation', label: 'Avaliação do Currículo', show: false },
    curriculum_status: { ref: 'curriculum_status', column: 'curriculum_status', label: 'Currículo avalido?', show: true },

    curriculum_recurse_status: { ref: 'curriculum_recurse_status', column: 'curriculum_recurse_status', label: 'Foi solicitado recurso?', show: false },
    allow_curriculum_recurse: { ref: 'allow_curriculum_recurse', column: 'allow_curriculum_recurse', label: 'Permitir recurso individual', show: false },


    curriculum_recurse_documents: { ref: 'curriculum_recurse_documents', column: 'curriculum_recurse_documents', label: 'Documentos do Recurso', show: false },
    curriculum_recurse_justification: { ref: 'curriculum_recurse_justification', column: 'curriculum_recurse_justification', label: 'Justificativa do Recurso', show: false },

    curriculum_recurse_avaliation_status: { ref: 'curriculum_recurse_avaliation_status', column: 'curriculum_recurse_avaliation_status', label: 'Recurso foi aprovado?', show: false },
    curriculum_recurse_avaliation_finished: { ref: 'curriculum_recurse_avaliation_finished', column: 'curriculum_recurse_avaliation_finished', label: 'Recurso foi avaliado?', show: false },
    curriculum_recurse_avaliation_justification: { ref: 'curriculum_recurse_avaliation_justification', column: 'curriculum_recurse_avaliation_justification', label: 'Justificativa do avaliador do Recurso', show: false },

    term: { ref: 'term', column: 'term', label: 'Termo de matrícula', show: false },


    cancelledAt: {
      ref: 'cancelledAt',
      column: 'cancelledAt',
      label: 'Data de Cancelamento',
      mask: 'date', show: false
    },

    reservedAt: {
      ref: 'reservedAt',
      column: 'reservedAt',
      label: 'Data de Reserva',
      mask: 'date', show: false
    },

    createdAt: {
      ref: 'created_at',
      column: 'createdAt',
      label: 'Data de Criação',
      mask: 'date', show: false
    },
  };




  const formSchema = {

    project_id: { model: 'input', type: 'text', name: 'project_id', label: 'Projeto' },
    test_grade: { model: 'input', type: 'text', name: 'test_grade', label: 'Nota' },

    category: { model: 'input', type: 'text', name: 'category', label: 'Categoria' },
    area: { model: 'input', type: 'text', name: 'area', label: 'Área' },
    subarea: { model: 'input', type: 'text', name: 'subarea', label: 'Subárea' },

    register_position_number: { model: 'input', type: 'text', name: 'register_position_number', label: 'Posição de classificação' },
    register_position: {
      model: 'selectSimple',
      name: 'register_position',
      label: 'Status de classificação',

      options: [
        { label: 'Classificado', value: 'Classificado' },
        { label: 'Documentação não enviada', value: 'Documentação não enviada' },
        { label: 'Suplente', value: 'Suplente' },
        { label: 'Desclassificado', value: 'Desclassificado' },
        { label: 'Não classificado', value: 'Não classificado' },
        { label: 'Cancelado pelo participante', value: 'Cancelado pelo participante' },
        { label: 'Reservado', value: 'Reservado' },
      ]

    },
    status: {
      model: 'selectSimple',
      name: 'status',
      label: 'Status',

      options: [
        { label: 'Aguardando envio de documentação', value: 'waiting' },
        { label: 'Aguardando avaliação da documentação', value: 'waiting_curriculum' },
        { label: 'Aguardando matrícula', value: 'register' },
        { label: 'Aguardando deferimento', value: 'waiting_complete' },
        { label: 'Desclassificado', value: 'disqualified' },
      ]

    },

    curriculum_status: {
      model: 'selectSimple',
      name: 'curriculum_status',
      label: 'Currículo foi avaliado?',

      options: [
        { label: 'Sim', value: 'yes' },
        { label: 'Não', value: 'no' },


      ]

    },

    allow_curriculum_recurse: {
      model: 'selectSimple',
      name: 'allow_curriculum_recurse',
      label: 'Permitir recurso individual',

      options: [
        { label: 'Sim', value: 'yes' },
        { label: 'Não', value: 'no' },


      ]

    },

    curriculum_recurse_status: {
      model: 'selectSimple',
      name: 'curriculum_recurse_status',
      label: 'Foi solicitado recurso?',

      options: [
        { label: 'Sim', value: 'yes' },
        { label: 'Não', value: 'no' },


      ]

    },

    curriculum_recurse_avaliation_finished: {
      model: 'selectSimple',
      name: 'curriculum_recurse_avaliation_finished',
      label: 'Recurso foi avaliado?',

      options: [
        { label: 'Sim', value: 'yes' },
        { label: 'Não', value: 'no' },


      ]

    },


    curriculum_avaliation_observation: { model: 'input', type: 'text', name: 'curriculum_avaliation_observation', label: 'Observação de avaliação currícular' },
  };

  const formSchemaUpdate = {
    register_position: {
      model: 'selectSimple',
      name: 'register_position',
      label: 'Status de classificação',
      filter: true,
      options: [
        { label: 'Classificado', value: 'Classificado' },
        { label: 'Documentação não enviada', value: 'Documentação não enviada' },
        { label: 'Suplente', value: 'Suplente' },
        { label: 'Desclassificado', value: 'Desclassificado' },
        { label: 'Não classificado', value: 'Não classificado' },
        { label: 'Cancelado pelo participante', value: 'Cancelado pelo participante' },
        { label: 'Reservado', value: 'Reservado' },
      ]

    },

  };

  const formValidation: Record<string, ValidationSchema> = {

  };

  const [type, setType] = useState('all');

  const config: ISearchComponent = {
    idKey: `${endpoint}-${projectHash}-${eventHash}`,
    title,
    endpoint,
    schema,
    autoStart: true,
    headModules: [

    ],
    bodyModules: [
      (lineData: Record<string, any>) =>
        lineModules.updateUnique({
          lineData,
          title,
          formSchema: formSchemaUpdate,
          validationSchema: {},
          endpoint: `selective-process`,
        }),

      (lineData: Record<string, any>) =>
        visualizateRegister({
          lineData,


        }),
    ],
  };

  const [projects, setProjects] = useState<Array<Record<string, any>>>([]);



  const loadProjects = async () => {
    const response = await api.get('/get-projects');
    if (response?.data) {

      response?.data?.rows.sort((a, b) => {
        return a.title > b.title ? 1 : a.title < b.title ? -1 : 0;
      })


      setProjects(response?.data.rows);
    }
  }

  useEffect(() => {
    if (user) {

      if (!user?.type) {
        window.location.href = `/app/${projectHash}/login-equipe-apoio`;
        return;
      }

      if (user?.type?.indexOf(projectHash) < 0) {

        const validUrl = user?.type?.split('|')?.[0]?.trim();

        window.location.href = `/app/${validUrl}/equipe-avaliacao-curricular`;
        return;
      }


      loadProjects();


    }


  }, [projectHash, user])


  const changeRoom = (url) => {
    window.location.href = `/app/${url}/equipe-avaliacao-curricular`;
  }




  return (<>

    <div style={{ display: 'flex', width: '100%', justifyContent: 'center', gap: '15px', padding: '10px', alignItems: 'center', background: '#fff' }}>
      <p style={{ color: '#333', textAlign: 'center' }}>Selecionar Processo Seletivo</p>
      <select style={{ padding: '16px 10px' }} onChange={(e) => {
        if (e.target.value !== '') {
          changeRoom(e.target.value);
        }
      }} className='defaultButtonMini'>

        {projects && projects.map(url => {
          if (user?.type?.indexOf(url?.url) >= 0) {
            return <option selected={url?.url === projectHash} value={url.url} key={`change-${url.url}`} >{url.title}</option>

          }
          else {
            return <></>;
          }
        })}
      </select>
    </div>
    <Container>



      <h2 style={{ color: '#333', width: '100%', textAlign: 'center' }}>{configuration?.title}</h2>




      <aside style={{ width: '100%', gap: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center', flexWrap: 'wrap' }}>
        <button className='defaultButton' onClick={() => setType('all')}>Todos</button>
        <button className='defaultButton' onClick={() => setType('classificado')}>Classificados</button>



        <button className='defaultButton' onClick={() => setType('suplente')}>Suplentes</button>
        <button className='defaultButton' onClick={() => setType('reserva')}>Reserva Militar</button>
        <button className='defaultButton' onClick={() => setType('cancelamento')}>Cancelamentos</button>
        <button className='defaultButton' onClick={() => setType('download')}>Downloads</button>

      </aside>

      <aside style={{ width: '100%', gap: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center', flexWrap: 'wrap' }}>


        <button className='defaultButton' onClick={() => setType('nao-enviados')}>Não enviados</button>
        <button className='defaultButton' onClick={() => setType('aguardando-avaliacao')}>Aguardando avaliação</button>
        <button className='defaultButton' onClick={() => setType('aguardando-ajustes')}>Aguardando ajustes</button>
        <button className='defaultButton' onClick={() => setType('aprovados')}>Aprovados</button>
        <button className='defaultButton' onClick={() => setType('sem-documentacao')}>Não enviou Docs no prazo</button>


      </aside>


      {type === 'all' ? <SearchComponent {...config} /> : <></>}
      {type === 'classificado' ? <SearchComponent {...config} endpoint={`/selective-process-equipe/${projectHash}/classificado`} idKey={`selective-process-equipe-classificado-${projectHash}-${eventHash}`} /> : <></>}
      {type === 'suplente' ? <SearchComponent {...config} endpoint={`/selective-process-equipe/${projectHash}/suplente`} idKey={`selective-process-equipe-supolente-${projectHash}-${eventHash}`} /> : <></>}
      {type === 'reserva' ? <SearchComponent {...config} endpoint={`/selective-process-equipe/${projectHash}/reserva`} idKey={`selective-process-equipe-reserva-${projectHash}-${eventHash}`} /> : <></>}
      {type === 'sem-documentacao' ? <SearchComponent {...config} endpoint={`/selective-process-equipe/${projectHash}/sem-documentacao`} idKey={`selective-process-equipe-sem-documento-${projectHash}-${eventHash}`} /> : <></>}
      {type === 'cancelamento' ? <SearchComponent {...config} endpoint={`/selective-process-equipe/${projectHash}/cancelamento`} idKey={`selective-process-equipe-cancelamento-${projectHash}-${eventHash}`} /> : <></>}

      {type === 'nao-enviados' ? <SearchComponent {...config} endpoint={`/selective-process-equipe/${projectHash}/nao-enviados`} idKey={`selective-process-equipe-cancelamento-${projectHash}-${eventHash}`} /> : <></>}
      {type === 'aguardando-avaliacao' ? <SearchComponent {...config} endpoint={`/selective-process-equipe/${projectHash}/aguardando-avaliacao`} idKey={`selective-process-equipe-cancelamento-${projectHash}-${eventHash}`} /> : <></>}
      {type === 'aguardando-ajustes' ? <SearchComponent {...config} endpoint={`/selective-process-equipe/${projectHash}/aguardando-ajustes`} idKey={`selective-process-equipe-cancelamento-${projectHash}-${eventHash}`} /> : <></>}
      {type === 'aprovados' ? <SearchComponent {...config} endpoint={`/selective-process-equipe/${projectHash}/aprovados`} idKey={`selective-process-equipe-cancelamento-${projectHash}-${eventHash}`} /> : <></>}



      {type === 'download' ? <table className='tableRef'>
        <tbody>
          <tr><td>ACESSO DIRETO - Aprovados</td><td><FaFileExcel style={{ width: '30px', fontSize: '24px', minWidth: '30px', color: 'rgb(12,148,144)', cursor: 'pointer' }} onClick={() => downloadit({ url: `/get-report-final/${projectHash}/aprovado/acesso_direto` })} /></td></tr>
          <tr><td>PRÉ-REQUISITOS - Aprovados</td><td><FaFileExcel style={{ width: '30px', fontSize: '24px', minWidth: '30px', color: 'rgb(12,148,144)', cursor: 'pointer' }} onClick={() => downloadit({ url: `/get-report-final/${projectHash}/aprovado/pre-requisitos` })} /></td></tr>
        </tbody>
      </table> : <></>}


    </Container>
  </>
  );
};

export default SelectiveProcessEquipe;
