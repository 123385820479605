import React, { useEffect, useState } from 'react';
import { Page, PDFViewer, PDFDownloadLink, Text, View, Document, StyleSheet, Image, Font, } from '@react-pdf/renderer';

import QRCode from 'qrcode.react';

import numero from 'numero-por-extenso';
import { dateLanguage, fullDate } from '../../../../utils/date';
import { useParams } from 'react-router-dom';
import api from '../../../../services/api';
import { useModal } from '../../../../hooks/Modal';
import { useLoading } from '../../../../hooks/LoadingHook';
import { urlWebsite } from '../../../../config';

Font.register({
  family: 'Montserrat',
  fonts: [
    { src: '/assets/fonts/Montserrat-Regular.ttf' },
    { src: '/assets/fonts/Montserrat-Bold.ttf', fontStyle: 'bold' }
  ]

});


interface IProps {
  hash?: string;
  year?: string;
  project_id?: string;
}

interface IRecibo {
  name: string;
  document_number: string;
  hash: string;
  total: number;
  payment_date: Date;
  createdAt: Date;
  projeto: string;
  document_number_type: string;
  product: string;
  project: string;
  year: string;
}

export function useReciboReceita() {

  const { addModal } = useModal();

  const openRecibo = ({ year, project_id, hash = '' }) => {

    addModal({ title: '', content: <ReciboReceita hash={hash} project_id={project_id} year={year} />, theme: 'whiteModal', key: 'reciboReceita' })

  }

  return { openRecibo }

}


export const ReciboReceita: React.FC<IProps> = ({ year, hash, project_id }) => {

  const { addLoading, removeLoading } = useLoading();

  const [recibo, setRecibo] = useState<IRecibo>({} as IRecibo);
  const [ready, setReady] = useState(false);
  const [afterReady, setAfterReady] = useState(false);
  const [loading, setLoading] = useState(true);
  const [failed, setFailed] = useState(false);
  const [notPaid, setNotPaid] = useState(false);

  const item: any = document.getElementById(`canva-${recibo.hash}`);

  const load = async (date, type, project_id) => {

    addLoading({ title: 'Validando', key: 'loading' })

    const response = await api.get(type === 'year' ? `/report-receita/${project_id}/${date}` : `/validate-report-receita/${date}`);

    if (response?.data?.total <= 0) {
      setNotPaid(true);
      removeLoading('loading');
      return;
    }

    setRecibo(response?.data);
    setReady(true)
    setTimeout(() => {
      setAfterReady(true)
      removeLoading('loading');

    }, 1000)

  }

  useEffect(() => {
    if (year) {
      load(year, 'year', project_id);
    }
    if (hash) {
      load(hash, 'hash', project_id);
    }
  }, [year, hash, project_id])

  const styles = StyleSheet.create({
    page: {
      backgroundColor: "#fff",
      color: "#333",
      padding: '20px'
    },
    section: {
      margin: 10,
      padding: 10,
    },
    viewer: {
      width: window.innerWidth, //the pdf viewer will take up all of the width and height
      height: window.innerHeight,
    },
  });


  if (notPaid) {
    return <div className='full-width row'>

      <h2 style={{ width: '100%', color: '#333', padding: '30px', fontSize: '16px', textAlign: 'center' }}>
        Nenhum pagamento identificado no período selecionado.</h2>

    </div>

  }

  if (ready) {
    return <div className='column' style={{ display: 'flex', gap: '20px', padding: '20px 0px', alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'column', width: '100%', background: '#fff' }}>

      <p style={{ fontSize: '12px', color: '#333', textAlign: 'left' }}>Nome: {recibo?.name}</p>
      <p style={{ fontSize: '12px', color: '#333', textAlign: 'left' }}>CPF: {recibo?.document_number}</p>

      {afterReady ? <><PDFDownloadLink fileName='recibo.pdf' document={
        <Document >
          {/*render a single page*/}
          <Page size="A4" style={styles.page}>

            <View>  <Image style={{ width: '150px' }} src="/apoio/logo-padrao2.png" />
            </View>

            <View style={{ borderTop: '1px solid #000', marginTop: '15px', paddingTop: '20px' }}>
              <Text style={{ fontWeight: 'bold', fontSize: '24px', margin: '30px auto', textAlign: 'center', width: '100%' }}>RECIBO</Text>
              <Text style={{ fontWeight: 700, fontSize: '10px', textAlign: 'center', marginBottom: '30px', width: '100%' }}>Chave de controle: {recibo.hash}</Text>
            </View>


            <View style={styles.section}>
              <Text style={{ fontSize: '14px' }}>{`A Fundação Médica do Rio Grande do Sul, CNPJ 94.391.901/0001-03, recebeu de ${recibo.name} inscrito no ${recibo?.document_number_type === 'cnpj' ? 'CNPJ' : 'CPF'} nº ${recibo.document_number}, a quantia de R$ ${(recibo.total / 100).toFixed(2).replace('.', ',')} (${numero.porExtenso(recibo.total / 100, numero.estilo.monetario)}) durante o ano de ${recibo.year}, referênte as mensalidades do projeto ${recibo?.project}.`}</Text>
            </View>

            <View style={{ ...styles.section, textAlign: 'center', display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
              <Text style={{ fontSize: '14px' }}>{`Acesse o QRCode abaixo para validar o comprovante`}</Text>
              <Image style={{ width: '100px', height: '100px', marginTop: '30px' }} src={item.toDataURL()} />
            </View>




            <View style={{ width: '100%', textAlign: 'right', fontSize: '14px', marginTop: '60px' }}>
              <Text>Porto Alegre, {fullDate(recibo?.createdAt)}</Text>

            </View>


            <View fixed style={{ width: '100%', position: 'absolute', bottom: '20px', left: '20px', textAlign: 'right' }}>


              <Text style={{ fontSize: '12px', padding: '5px', textAlign: 'center' }}> 51 3332.6840 | 51 3321.4041 – www.fundacaomedicars.org.br</Text>
              <Text style={{ fontSize: '12px', borderTop: '1px solid #000', textAlign: 'center', padding: '5px' }}>  Rua Ramiro Barcelos 2350, Sala 177 | Bom Fim, Porto Alegre/RS – CEP 90035-003</Text>
            </View>
          </Page>
        </Document>


      }>
        <button className='defaultButton'> Realizar Download do recibo </button>
      </PDFDownloadLink>


        <aside className='computerOnly' style={{ width: '100%' }}>
          <PDFViewer style={{
            width: window.innerWidth, //the pdf viewer will take up all of the width and height
            height: window.innerHeight,
          }}>
            <Document >
              {/*render a single page*/}
              <Page size="A4" style={styles.page}>

                <View>  <Image style={{ width: '150px' }} src="/apoio/logo-padrao2.png" />
                </View>

                <View style={{ borderTop: '1px solid #000', marginTop: '15px', paddingTop: '20px' }}>
                  <Text style={{ fontWeight: 'bold', fontSize: '24px', margin: '30px auto', textAlign: 'center', width: '100%' }}>RECIBO</Text>
                  <Text style={{ fontWeight: 700, fontSize: '10px', textAlign: 'center', marginBottom: '30px', width: '100%' }}>Chave de controle: {recibo.hash}</Text>
                </View>


                <View style={styles.section}>
                  <Text style={{ fontSize: '14px' }}>{`A Fundação Médica do Rio Grande do Sul, CNPJ 94.391.901/0001-03, recebeu de ${recibo.name} inscrito no ${recibo?.document_number_type === 'cnpj' ? 'CNPJ' : 'CPF'} nº ${recibo.document_number}, a quantia de R$ ${(recibo.total / 100).toFixed(2).replace('.', ',')} (${numero.porExtenso(recibo.total / 100, numero.estilo.monetario)}) durante o ano de ${recibo?.year}, referênte as mensalidades do projeto ${recibo?.project}.`}</Text>
                </View>
                <View style={{ ...styles.section, textAlign: 'center', display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                  <Text style={{ fontSize: '14px' }}>{`Acesse o QRCode abaixo para validar o comprovante`}</Text>
                  <Image style={{ width: '100px', height: '100px', marginTop: '30px' }} src={item.toDataURL()} />
                </View>

                <View style={{ width: '100%', textAlign: 'right', fontSize: '14px', marginTop: '60px' }}>
                  <Text>Porto Alegre, {fullDate(recibo?.createdAt)}</Text>

                </View>


                <View fixed style={{ width: '100%', position: 'absolute', bottom: '20px', left: '20px', textAlign: 'right' }}>


                  <Text style={{ fontSize: '12px', padding: '5px', textAlign: 'center' }}> 51 3332.6840 | 51 3321.4041 – www.fundacaomedicars.org.br</Text>
                  <Text style={{ fontSize: '12px', borderTop: '1px solid #000', textAlign: 'center', padding: '5px' }}>  Rua Ramiro Barcelos 2350, Sala 177 | Bom Fim, Porto Alegre/RS – CEP 90035-003</Text>
                </View>
              </Page>
            </Document>
          </PDFViewer>  </aside> </>
        : <></>}

      <aside style={{ display: 'none' }}>
        <QRCode id={`canva-${recibo.hash}`}
          size={100}
          value={`${urlWebsite}/validar-relatorio/${recibo.hash}`} renderAs="canva" />
      </aside>


    </div>
  }

  return <></>
}