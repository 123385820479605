import React from 'react';
import { Switch } from 'react-router-dom';
import { home } from '../config';
import Route from './routes';
import SignIn from '../pages/SignIn';
import Sobre from '../pages/Sobre';
import SignInAdmin from '../pages/SignInAdmin';
import SignUp from '../pages/SignUp';
import RecoverPassword from '../pages/RecoverPassword';
import ResetPassword from '../pages/ResetPassword';
import Products from '../pages/Products';

import VideosDashboard from '../pages/VideosDashboard';
import Dashboard from '../pages/DashboardPages';

import Home from '../pages/Home';
import AreaExposicao from '../pages/AreaExposicao';
import Expositor from '../pages/Expositor';
import Galeria from '../pages/Galeria';
import ExpositorChat from '../pages/ExpositorChat';
import SigninExpositor from '../pages/SigninExpositor';
import Program from '../pages/Program';
import Poster from '../pages/Poster';

import Speakers from '../pages/Speakers';
import Zoom from '../components/ZoomStarter';
import SpeakerLink from '../pages/SpeakerLink';
import ModeratorLink from '../pages/ModeratorLink';
import Validate from '../pages/DashboardPages/features/AuthIntegration';
import HomeApplication from '../PagesApplication/HomeApplication';
import GreenRoom from '../pages/GreenRoom';
import UserHome from '../pages/DashboardUserPages/Pages/Home';
import FileGallerys from '../pages/FileGallery';
import Sponsors from '../pages/Sponsors';
import ProgramTheme from '../PagesApplication/HomeApplication/modules/Program';
import Certificados from '../pages/Certificados';
import SurveyView from '../pages/DashboardPages/features/SurveyByURL';
import PaymentMethod from '../pages/Products/modules/PaymentMethod';
import PaymentConfirmation from '../pages/Products/modules/PaymentConfirmation';
import Faq from '../pages/Faq';
import { Recibo } from '../pages/DashboardPages/features/Recibo';
import { FormularioImpressao } from '../pages/DashboardPages/features/Formulario';
import SignInAvaliador from '../pages/SignInAvaliador';
import SelectiveProcessAvaliation from '../pages/DashboardPages/pages/SelectiveProcessAvaliation';


import SignInEquipe from '../pages/SignInEquipe';
import SelectiveProcessEquipe from '../pages/DashboardPages/pages/SelectiveProcessEquipe';
import ProductLogin from '../pages/Products/modules/Login';
import IsentionPage from '../pages/DashboardUserPages/Pages/IsentionPage';
import ValidarReportReceita from '../pages/ValidarReportReceita';
const ValidPaths = {
  SignIn,
  SignInAdmin,
  SignUp,
  RecoverPassword,
  ResetPassword,
  Products,
  VideosDashboard,

  Home,
  Sobre,
};

const Routes: React.FC = () => {
  return (
    <Switch>


      <Route
        path="/manager/:tab/:projectHash/:eventHash"
        template="dashboard"
        isPrivate
        isSecret
        component={Dashboard}
      />

      <Route
        path="/manager/:tab/:projectHash"
        template="dashboard"
        isPrivate
        isSecret
        component={Dashboard}
      />

      <Route
        path="/manager/:tab"
        template="dashboard"
        isPrivate
        isSecret
        component={Dashboard}
      />
      <Route
        path="/manager"
        template="dashboard"
        isPrivate
        exact
        isSecret
        component={Dashboard}
      />
      <Route template="client" path="/admin" component={SignInAdmin} />
      <Route

        path="/"
        exact
        component={ValidPaths[home] ? ValidPaths[home] : Home}
      />
      <Route path="/app/:projectHash/event/:eventHash/user-dashboard" component={UserHome} />
      <Route path="/app/:projectHash/user-dashboard" component={UserHome} />
      <Route path="/app/:projectHash/solicitar-isencao" component={IsentionPage} />



      <Route path="/app/:projectHash/dashboard" component={UserHome} />
      <Route
        isPrivate
        path="/user-dashboard"
        component={UserHome}
      />


      <Route path="/app/:projectHash/avaliacao-curricular" component={SelectiveProcessAvaliation} />
      <Route path="/app/:projectHash/login-avaliador-curriculos" component={SignInAvaliador} />


      <Route path="/app/:projectHash/equipe-avaliacao-curricular" component={SelectiveProcessEquipe} />
      <Route path="/app/:projectHash/login-equipe-apoio" component={SignInEquipe} />




      <Route path="/validar-relatorio/:hash" component={ValidarReportReceita} />


      <Route path="/app/:projectHash/formulario/:hash" component={FormularioImpressao} />

      <Route path="/app/:projectHash/recibo/:hash" component={Recibo} />
      <Route path="/app/:projectHash/arquivos-de-apoio" component={FileGallerys} />
      <Route path="/app/:projectHash/faq" component={Faq} />
      <Route path="/app/:projectHash/programacao" component={ProgramTheme} />
      <Route path="/app/:projectHash/login/" component={SignIn} />
      <Route path="/login" component={SignIn} />


      <Route path="/app/:projectHash/event/:eventHash/recuperar_senha" component={RecoverPassword} />
      <Route path="/app/:projectHash/recuperar_senha" component={RecoverPassword} />
      <Route path="/recuperar_senha" component={RecoverPassword} />

      <Route path="/app/:projectHash/event/:eventHash/reset_password/:key" component={ResetPassword} />
      <Route path="/app/:projectHash/reset_password/:key" component={ResetPassword} />
      <Route path="/reset_password/:key" component={ResetPassword} />


      <Route path="/app/:projectHash/registro/:product_hash" component={ProductLogin} />

      <Route path="/app/:projectHash/checkout/event/:eventHash/:product_hash" component={PaymentMethod} />
      <Route path="/app/:projectHash/checkout/:product_hash" component={PaymentMethod} />
      <Route path="/app/:projectHash/event/:eventHash/payment/success/:payment_hash" component={PaymentConfirmation} />
      <Route path="/app/:projectHash/payment/success/:payment_hash" component={PaymentConfirmation} />

      <Route path="/app/:projectHash/event/:eventHash/survey/:survey_id" component={SurveyView} />
      <Route path="/app/:projectHash/survey/:survey_id" component={SurveyView} />

      <Route path="/app/:projectHash/event/:eventHash/login/" component={SignIn} />

      <Route path="/app/:projectHash/event/:eventHash/arquivos-de-apoio" component={FileGallerys} />
      <Route path="/app/:projectHash/event/:eventHash/certificados" component={Certificados} />
      <Route path="/app/:projectHash/event/:eventHash/inscricao/:productId" component={Products} />
      <Route path="/app/:projectHash/event/:eventHash/inscricao" component={Products} />

      <Route path="/app/:projectHash/event/:eventHash/palestrantes" component={Speakers} />
      <Route path="/app/:projectHash/event/:eventHash/patrocinadores" component={Sponsors} />




      <Route path="/app/:projectHash/certificados" component={Certificados} />

      <Route path="/app/:projectHash/palestrantes" component={Speakers} />
      <Route path="/app/:projectHash/patrocinadores" component={Sponsors} />
      <Route path="/app/:projectHash/inscricao/:productId" component={Products} />
      <Route path="/app/:projectHash/inscricao" component={Products} />
      <Route path="/inscricao" component={SignUp} />

      <Route
        isPrivate
        path="/app/:projectHash/event/:eventHash/dashboard/:videoIdItem"
        component={VideosDashboard}
      />

      <Route
        isPrivate
        path="/app/:projectHash/event/:eventHash/dashboard/"
        component={VideosDashboard}
      />
      <Route
        isPrivate
        path="/app/:projectHash/dashboard/:videoIdItem"
        component={VideosDashboard}
      />
      <Route
        isPrivate
        path="/app/:projectHash/dashboard/"
        component={VideosDashboard}
      />

      <Route template={'page'} isPrivate isSecret path="/app/:projectHash/green-room/:id" component={GreenRoom} />

      <Route isPrivate path="/app/:projectHash/poster" component={Poster} />

      <Route path="/app/:projectHash/event/:eventHash" component={HomeApplication} />
      <Route path="/app/:projectHash" component={HomeApplication} />

      <Route template="client" path="/validate/:hash" component={Validate} />
      <Route template="client" path="/events/access/:hash" component={SpeakerLink} />
      <Route path="/zoom" component={Zoom} />

      <Route path="/moderator/access/:projectHash/:hash" component={ModeratorLink} />

      <Route path="/exposicao" component={AreaExposicao} />


      <Route isPrivate path="/login_expositor" component={SigninExpositor} />

      <Route

        path="/expositores/:link_name"
        template="page"
        component={ExpositorChat}
      />
      <Route
        isPrivate
        path="/galeria/:link_name"
        template="page"
        component={Galeria}
      />
      <Route
        isPrivate
        path="/expositores_chat/:link_name"
        template="page"
        component={ExpositorChat}
      />




    </Switch>
  );
};

export default Routes;
