import { Form } from '@unform/web';
import React from 'react';
import { useModal } from "../../../../../../../hooks/Modal";
import { useToast } from '../../../../../../../hooks/Toast';
import api from '../../../../../../../services/api';
import TextareaInput from '../../../../../core/components/Forms/TextareaInput';

const useDataHistory = () => {

    const { addModal, removeModal } = useModal();
    const { addToast } = useToast();


    const updateHistory = async (id, category, property, data) => {

        const { content } = data;

        const item = await api.get(`selective-process/${id}`);

        const historyData = item?.data?.register_history || {};

        if (!historyData?.[category]) {
            historyData[category] = {};
        }

        if (!historyData?.[category]?.[property]) {
            historyData[category][property] = [];
        }

        historyData[category][property].push({
            content: content,
            date: new Date()
        })

        await api.put(`selective-process/${id}/register_history`, { register_history: historyData });

        removeModal('register_history');
        addToast({ title: 'Atualizado com sucesso', type: 'info' })

    }


    const openHistory = async (id, category, property) => {

        const item = await api.get(`selective-process/${id}`);

        addModal({
            title: '', key: 'register_history', content: <>

                <Form onSubmit={(data) => updateHistory(id, category, property, data)}>

                    <TextareaInput name='content' placeholder='Descritivo' />
                    <button className='defaultButtonMini'>
                        Salvar
                    </button>

                </Form>

                {item?.data?.register_history?.[category]?.[property]?.length > 0 ?

                    <>
                        <table className='tableRef'><tbody>

                            {item?.data?.register_history?.[category]?.[property]?.map(i => {

                                return <tr><td>{i?.content}</td><td>{i?.date}</td></tr>


                            })}

                        </tbody></table>


                    </>

                    : <></>}

            </>, theme: 'whiteModalMedium'
        });


    }


    return { openHistory }
}

export default useDataHistory;