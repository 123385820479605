import React from 'react';
import api from '../../../../../../services/api';
import { useToast } from '../../../../../../hooks/Toast';

import { useAuth } from '../../../../../../hooks/Auth';
import { FiSend } from 'react-icons/fi';
import { FaFileExcel, FaQrcode, FaReceipt } from 'react-icons/fa';
import { useConfiguration } from '../../../../../../hooks/Configuration';
import { date } from '../../../../../../utils/date';
import { formatReal } from '../../../../../../utils/format';
import { downloaditPost } from '../../../../../../utils/download';
import { apiUrl } from '../../../../../../config';
import { useLoading } from '../../../../../../hooks/LoadingHook';

interface ICreateModule {
  lineData: Record<string, any>;
}

export default function UpdateModule(): JSX.Element {
  const { handleApiErrors } = useAuth();
  const { addToast } = useToast();
  const { addLoading, removeLoading } = useLoading();
  const { configuration } = useConfiguration();

  const prepareDownload = async () => {

    addLoading({ title: 'Gerando relatório', key: 'loading' })

    const invoices = await api.get('/sales-invoice', { params: { where: { project_id: configuration?.url }, limitItems: 10000 } });

    const data = [
      'Projeto',
      'Nº da Fatura',
      'Nome',
      'E-mail',
      'Telefone',
      'CPF',
      'Status de pagamento',
      'Forma de pagamento',

      'Valor da fatura',
      'Juros',
      'Multa',
      'Total pago',
      'Data de vencimento',
      'Data de pagamento',

    ]

    const items: Array<Array<string>> = [];

    const paymentStatus = {
      waiting: 'Aguardando',
      paid: 'Pago',
      cancelled: 'Cancelado'
    }


    const paymentTypes = {
      credit_card_recurring: 'Cartão de crédito',
      boleto_recurring: 'Boleto'
    }

    invoices?.data?.rows.map(i => {

      const total = parseInt(i?.installment_value || '0', 10) + parseInt(i?.juros || '0', 10) + parseInt(i?.multa || '0', 10);

      if (i?.installment_number <= 9) {
        i.installment_number = `0${i?.installment_number}`
      }

      items.push(
        [
          configuration?.title,
          i?.installment_number,
          i?.client_id_response?.name,
          i?.client_id_response?.email,
          i?.client_id_response?.phone,
          i?.client_id_response?.document_number,
          paymentStatus?.[i?.payment_status_id] ? paymentStatus?.[i?.payment_status_id] : i?.payment_status_id,
          paymentTypes?.[i?.payment_method_id] ? paymentTypes?.[i?.payment_method_id] : i?.payment_method_id,
          formatReal(i?.installment_value),
          formatReal(i?.juros),
          formatReal(i?.multa),
          formatReal(total),
          i?.payment_date_reference ? date(i?.payment_date_reference) : date(i?.payment_date),
          i?.approved_at ? date(i?.approved_at) : i?.payment_status_id === 'paid' ? i?.payment_date_reference ? date(i?.payment_date_reference) : date(i?.payment_date) : ''

        ]



      )
    })

    items.sort((a, b) => {

      const aData = `${a[1]}-${a[2]}`;
      const bData = `${b[1]}-${b[2]}`;

      return aData < bData ? -1 : aData > bData ? 1 : 0;

    })

    const fullData = [
      data,
      ...items
    ]

    await downloaditPost({ url: `${apiUrl}/converter/xlsx/`, data: fullData, type: 'xlsx' });
    removeLoading('loading')

  }



  const moduleUpdate = (): JSX.Element => (
    <button className="searchButton" title="Download do relatório geral" onClick={() => { prepareDownload() }} type="button"  >
      <FaFileExcel color='yellow' />
    </button>
  );

  return moduleUpdate();
}
