import React, { useState } from 'react';
import api from '../../../../../../services/api';
import { useToast } from '../../../../../../hooks/Toast';

import { useAuth } from '../../../../../../hooks/Auth';
import { FiSend } from 'react-icons/fi';
import Whatsapp from '../../../../../../components/Whatsapp';
import { authTitle, urlWebsite } from '../../../../../../config';
import { FaCheckCircle, FaMoneyBill } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import downloadit from '../../../../../../utils/download';
import { useModal } from '../../../../../../hooks/Modal';
import { useConfiguration } from '../../../../../../hooks/Configuration';



export function useVerificateReserved({ lineData }) {

  const { projectHash } = useParams<Record<string, any>>();
  const { addToast } = useToast();
  const { addModal } = useModal();
  const { configuration } = useConfiguration();



  const gerarRelatorioFinanceiro = async () => {

    const student = await api.get(`/selective-process/${lineData?._id?.toString()}`)


    addModal({
      title: 'Visualizar documentos',
      content: <>
        <h2 style={{ color: '#333', fontSize: '14px' }}>{lineData?.client_id_response?.name}</h2>
        <table className='tableRef table'>



          {student?.data?.reserve_documents?.documents?.uploads?.map((i, index) => {

            return <tr><td>
              Documento {index + 1}:
            </td>
              <td>
                <a href={`${urlWebsite}/${i?.url}`} download target="_BLANK"> <button className='defaultButtonMini'>Baixar</button>  </a>
              </td>


            </tr>

          })}
        </table>

      </>,
      theme: 'whiteModal',
      key: 'curriculum_avaliation',

    })


  }

  const moduleUpdate = (): JSX.Element => (
    projectHash && lineData?.status === 'reserved' ? <button className='defaultButtonMini' style={{ background: lineData?.curriculum_status === 'yes' ? 'rgb(0,150,0)' : 'rgb(250,50,0)', color: '#fff' }} type="button" onClick={() => gerarRelatorioFinanceiro()}>
      Documentos de reserva
    </button> : <></>
  );

  return moduleUpdate();
}
