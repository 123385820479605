import React, { useState, useEffect } from 'react';

import { FiUsers, FiUserPlus, FiCreditCard, FiUserCheck, FiMenu, FiBarChart, FiMail, FiXCircle } from 'react-icons/fi';
import { FaArrowLeft, FaBook, FaBookMedical, FaBox, FaBoxes, FaBuilding, FaCalendar, FaCertificate, FaChartBar, FaCreditCard, FaFileMedical, FaFilePdf, FaGifts, FaHome, FaImage, FaImages, FaMeetup, FaMicrophone, FaMoneyCheckAlt, FaPage4, FaPagelines, FaProjectDiagram, FaQuestion, FaReceipt, FaRegSmileBeam, FaSlideshare, FaSpeakerDeck, FaSquareFull, FaStar, FaTicketAlt, FaUser, FaVideo, FaVideoSlash, FaVoteYea } from 'react-icons/fa';


import CounterCard from './core/components/CounterCard';
import CounterCardRepeat from './core/components/CounterCardSocket';
import api from '../../services/api';
import {
  Container,

  CardContainer,
  RoomReport, RoomReportDiv,
  Sidebar
} from './styles';

import { useAuth } from '../../hooks/Auth';
import Lessons from './pages/Lessons';
import Sale from './pages/Sales';
import Expositor from './pages/Expositors';
import Speaker from './pages/Speakers';
import Students from './pages/Students';
import Program from './pages/Programs';
import Courses from './pages/Courses';
import ExpositorVote from './pages/ExpositorVotes';
import SponsorsAdmin from './pages/Sponsors';
import Reporter from './pages/Reporter';
import { Link, useParams } from 'react-router-dom';
import { authTitle } from '../../config';
import ProductsAdmin from './pages/Products';
import Certificate from './pages/Certificates';
import { id } from 'date-fns/locale';
import ImageGallery from './pages/ImageGallery';
import ExpositorsList from './pages/ExpositorList';
import VideoGallery from './pages/VideoGallery';
import PhotoGallery from './pages/PhotoGallery';
import UpdateData from './pages/UpdateData';
import Evento from './pages/Events';
import Slides from './pages/LessonSlides';
import Global from './pages/Global';
import Project from './pages/Platforms';
import fi from 'date-fns/esm/locale/fi/index.js';
import { usePages } from '../../hooks/Pages';
import LeadCapturePage from './pages/LeadCapturePage';
import FileGallerys from './pages/FileGallery';
import Resumo from './pages/Resumo';
import SpeakerPosition from './pages/SpeakersPosition';
import Exam from './pages/Exam';
import EmailTheme from './pages/EmailTheme';
import SatisfactionSurvey from './pages/SatisfactionSurvey';
import { useConfiguration } from '../../hooks/Configuration';
import FaqAdmin from './pages/Faq';

import SelectiveProcess from './pages/SelectiveProcess';
import SaleLog from './pages/SalesLog';
import SaleCoupon from './pages/SalesCoupon';
import SelectiveProccessSpecialTreatment from './pages/SelectiveProcessSpecialTreatment';
import SelectiveProccessSubscriptionRecurse from './pages/SelectiveProcessSubscriptionRecuse';
import SelectiveProccessProofRecurse from './pages/SelectiveProcessProofRecuse';
import IframePlayer from '../../components/IframePlayer';
import IsentionRecurse from './pages/IsentionRecurse';
import ResumoSalesGlobal from './pages/ResumoVendasGlobal';
import SalesReportByProject from './pages/SalesReportByProject';
import SaleInvoice from './pages/SalesInvoice';


interface IReport {
  id: number,
  client_id: number,
  room_id: number,
  name: string,
}

interface IOnline {
  title: string;
  room_id: number;
  count: number;
}

interface IParams {
  tab: string;

}

const SidebarComponent = ({ validSearch, projectSearch, eventSearch, active = true, }) => {

  const { user } = useAuth();
  const [projects, setProjects] = useState<Array<Record<string, any>>>([]);
  const [events, setEvents] = useState<Array<Record<string, any>>>([]);
  const [sidebarActive, setSidebarActive] = useState(true);
  const { tab = 'students' } = useParams<IParams>();
  const { projectHash, eventHash } = useConfiguration();
  const [currentProject, setCurrentProject] = useState('');
  const [currentEvent, setCurrentEvent] = useState('');

  useEffect(() => {
    setSidebarActive(active);
  }, [active]);

  const loadProjects = async () => {
    const response = await api.get('/get-projects');
    if (response?.data) {
      setProjects(response?.data.rows);
    }
  }

  const loadEvents = async () => {
    const response = await api.get(`/events-list`);
    if (response?.data) {
      setEvents(response?.data.rows);
    }
  }

  useEffect(() => {
    loadProjects();
    loadEvents();





  }, [])

  useEffect(() => {

    setCurrentEvent(eventHash || '');
    setCurrentProject(projectHash || '')
  }, [projectHash, eventHash])

  const renderValidSearch = () => {
    const items: Array<JSX.Element> = [];
    Object.keys(validSearch)?.map(key => {
      const { title, icon } = validSearch[key];
      items.push(<Link to={`/manager/${key}`}> {icon()} <p>{title}</p></Link>);

    })
    return items;

  }

  const renderValidProjectSearch = () => {
    const items: Array<JSX.Element> = [];
    const project = projects?.find(p => p.url === currentProject);
    if (project) {
      Object.keys(projectSearch)?.map(key => {
        const { title, icon } = projectSearch[key];


        items.push(<Link to={`/manager/${key}/${project.url}`}> {icon()} <p>{title}</p></Link>);

      })

      const links: Array<JSX.Element> = [];
      events?.map(event => {
        if (event.project_id === currentProject) {
          links.push(<Link key={`event-key-${event.url}`} to={`/manager/${Object.keys(eventSearch)[0]}/${currentProject}/${event.url}`}> <FaCalendar /> <p>{event.title}</p></Link>);
        }
      })


      return <>
        <h2 className='sidebar-title'>{project.title}</h2>
        {items}
        <h2 className='sidebar-subtitle'>Eventos</h2>
        {links}

      </>;
    }
    return <></>;
  }

  const renderValidEventSearch = () => {
    const items: Array<JSX.Element> = [];
    const eventInfo = events.find(p => p.url === currentEvent);

    if (eventInfo) {
      Object.keys(eventSearch).map(key => {

        const { title, icon } = eventSearch[key];


        items.push(<Link to={`/manager/${key}/${currentProject}/${eventInfo.url}`}> {icon()} <p>{title}</p></Link>);

      })
      return <>
        <h2>{eventInfo.title}</h2>
        {items}
      </>;
    }
    return <></>;
  }


  return <Sidebar active={sidebarActive}>
    <div className="menu" onClick={() => setSidebarActive(!sidebarActive)}><FiMenu size="30" /></div>
    <img src="/apoio/logo-padrao2.png" style={{ width: '90%', maxWidth: '150px', margin: '10px auto' }} />
    <div style={{ background: 'rgb(0,250,50)', padding: '5px 10px', borderRadius: '5px', margin: '10px auto', color: 'rgb(0,100,0)' }}>
      {user.name}
    </div>
    <div style={{ background: 'rgb(0,250,50)', padding: '5px 10px', borderRadius: '5px', margin: '10px auto', color: 'rgb(0,100,0)' }}>
      {authTitle}
    </div>

    {currentProject === '' &&
      <>
        {renderValidSearch()}
        <h2 className='sidebar-title'>Projetos</h2>
        {projects.map(project => <Link to={`/manager/home/${project.url}`} > <FaProjectDiagram /> <p>{project.reference_number && `(${project.reference_number}) `}{project.title}</p></Link>)}
      </>

    }

    {currentProject !== '' && currentEvent === '' &&
      <>
        <Link to="/manager" onClick={() => setCurrentProject('')}><button className='sidebarPrevButton'>Voltar</button></Link>
        <Link to={`/app/${currentProject}`} onClick={() => setCurrentProject('')}><button className='sidebarPrevButton'>Home do projeto</button></Link>
        <Link to={`/app/${currentProject}/dashboard`} onClick={() => setCurrentProject('')}><button className='sidebarPrevButton'>Palestras do projeto</button></Link>
        {renderValidProjectSearch()}
      </>
    }


    {currentProject !== '' && currentEvent !== '' && <>
      <Link to={`/manager/${Object.keys(projectSearch)[0]}/${projectHash}`}><button className='sidebarPrevButton'>Voltar</button></Link>
      <Link to={`/app/${currentProject}`} onClick={() => setCurrentProject('')}><button className='sidebarPrevButton'>Home do projeto</button></Link>
      <Link to={`/app/${currentProject}/dashboard`} onClick={() => setCurrentProject('')}><button className='sidebarPrevButton'>Palestras do projeto</button></Link>

      {renderValidEventSearch()}
    </>
    }

  </Sidebar>
}


const Dashboard: React.FC = () => {



  const { tab = 'projects' } = useParams<IParams>();
  const { projectHash, eventHash } = useConfiguration();
  const { token, user, signOut } = useAuth();

  const [online, setOnline] = useState(0);
  const [loading, setLoading] = useState(false);
  const [showOnlineUsers, setShowOnlineUsers] = useState(false);
  const [report, setReport] = useState<Array<IReport>>([]);
  const [search, setSearch] = useState(tab);
  const [rooms, setRooms] = useState<Array<IOnline>>([]);
  const { prepareMenu } = usePages();

  useEffect(() => {




    prepareMenu({ projectHash, active_pages: [] });
  }, [])


  let validSearch: Record<string, any> = {
    student: { icon: () => <FaUser />, title: 'Cadastros', render: () => <Students /> },
    resumeHome: {
      icon: () => <FaVideo />, title: 'Tutoriais', render: () => <>
        <aside style={{ width: '100%', maxWidth: '600px', margin: '0px auto', gap: '5px', display: 'flex', alignItems: 'stretch', justifyContent: 'center', padding: '25px', flexDirection: 'column' }}>

          <h2 style={{ color: '#333', fontSize: '24px', width: '100%', textAlign: 'center' }}>Tutoriais</h2>
          <p style={{ color: '#333', width: '100%', textAlign: 'center' }}>Antes de iniciar, veja os tutoriais desenvolvidos por nossa equipe. </p>
          <p style={{ color: '#333', width: '100%', textAlign: 'center' }}>Para visualizar em tela cheia, clique no botão de maximizar na parte inferior direita do player.</p>
        </aside>
        <aside style={{ minHeight: '20vh', width: '100%', gap: '25px', display: 'flex', alignItems: 'stretch', justifyContent: 'center', padding: '25px', flexDirection: 'row', flexWrap: 'wrap' }}>

          <aside className="videoGroupCoord" >
            <IframePlayer id='https://iframe.mediadelivery.net/embed/77638/04f533d2-cbc0-4e69-bd00-fa9cbd981182' />
            <p> Visão geral da plataforma </p>

          </aside>

          <aside className="videoGroupCoord" >
            <IframePlayer id='https://iframe.mediadelivery.net/embed/77638/64b19a58-1a5f-4dc8-9062-4ef6da34ae6c' />
            <p> Como funciona o processo seletivo na plataforma </p>
          </aside>



          <aside className="videoGroupCoord" >
            <IframePlayer id='https://iframe.mediadelivery.net/embed/77638/d5f252f2-7824-4090-8cf4-e688c1832b2c' />
            <p> Como alterar um cadastro </p>
          </aside>

          <aside className="videoGroupCoord" >
            <IframePlayer id='https://iframe.mediadelivery.net/embed/77638/96d8559d-6ab7-4906-84a0-23d3c22c22cc' />
            <p> Como alterar uma inscrição </p>
          </aside>

          <aside className="videoGroupCoord" >
            <IframePlayer id='https://iframe.mediadelivery.net/embed/77638/f6a84f32-985e-41fe-b48a-0367ef6e46c4' />
            <p> Como exportar dados  </p>
          </aside>

          <aside className="videoGroupCoord" >
            <IframePlayer id='https://iframe.mediadelivery.net/embed/77638/6a2f720e-1a0e-48ee-a251-64471de44d40' />
            <p> Como iniciar ou encerrar um processo de inscrição </p>
          </aside>

        </aside></>
    }

  };



  let projectSearch: Record<string, any> = {};
  let eventSearch: Record<string, any> = {};

  if (user.profile === 1) {
    validSearch = {
      ...validSearch,
      // global : { icon : () => <FaBook/>, title: 'Configurações de Capa' , render : () => <Global/>},
      projects: { icon: () => <FaProjectDiagram />, title: 'Projetos', render: () => <Project /> },

      //reporter : { icon : () => <FaChartBar/>, title : 'Relatórios', render: () => <Reporter/>, closeSidebar : true},
      sale: { icon: () => <FaCreditCard />, title: 'Vendas', render: () => <Sale /> },
      saleLog: { icon: () => <FaMoneyCheckAlt />, title: 'Logs', render: () => <SaleLog /> },
      saleProjectReport: { icon: () => <FaChartBar />, title: 'Relatório por projeto', render: () => <SalesReportByProject /> },
      saleReport: { icon: () => <FaChartBar />, title: 'Relatório de Vendas', render: () => <ResumoSalesGlobal /> },
      //updateData : { icon : () => <FaVideo/>, title: 'Update nos Dados' , render : () => <UpdateData/>},

    }

    projectSearch = {
      ...projectSearch,
      home: { icon: () => <FaHome />, title: 'Resumo', render: () => <Resumo /> },
      // student2: { icon: () => <FaUser />, title: 'Cadastros', render: () => <Students /> },
      emailThemeProject: { icon: () => <FiMail />, title: 'Modelo de e-mail', render: () => <EmailTheme /> },
      filegallery: { icon: () => <FaFilePdf />, title: 'Galeria de Arquivos', render: () => <FileGallerys /> },
      faq: { icon: () => <FaQuestion />, title: 'FAQ', render: () => <FaqAdmin /> },
      sponsor: { icon: () => <FaStar />, title: 'Patrocinadores', render: () => <SponsorsAdmin /> },
      events: { icon: () => <FaCalendar />, title: 'Eventos', render: () => <Evento /> },

      // course: { icon : () => <FaBoxes/>,  title : 'Cursos', render : () => <Courses />},
      sale2: { icon: () => <FaCreditCard />, title: 'Vendas', render: () => <Sale /> },
      salesInvoice: { icon: () => <FaCreditCard />, title: 'Faturas de Matrículas', render: () => <SaleInvoice /> },
      saleCoupon: { icon: () => <FaMoneyCheckAlt />, title: 'Cupom de desconto', render: () => <SaleCoupon /> },
      expositorslist: { icon: () => <FaGifts />, title: 'Cortesias', render: () => <ExpositorsList /> },
      // lesson: { icon : () => <FaBox/>, title : 'Palestras' , render : () => <Lessons />},

      // slides :  { icon : () => <FaSlideshare/>, title : 'Slides' , render : () => <Slides />},
      // leadpage :  { icon : () => <FaPagelines/>, title : 'Lead Page' , render : () => <LeadCapturePage />},
      // speaker : { icon : () => <FaMicrophone/>, title : 'Palestrantes' , render : () => <Speaker />},
      isentionRecurse: { icon: () => <FaTicketAlt />, title: 'Solicitação de isenção', render: () => <IsentionRecurse /> },
      selectiveProcess: { icon: () => <FaBookMedical />, title: 'Processos Seletivos', render: () => <SelectiveProcess /> },
      selectiveProcessSpecialTreatment: { icon: () => <FaFileMedical />, title: 'Processos Seletivos - Atendimento especial', render: () => <SelectiveProccessSpecialTreatment /> },
      selectiveProcessSubscriptionRecurse: { icon: () => <FiXCircle />, title: 'Inscrição - Solicitação de recurso', render: () => <SelectiveProccessSubscriptionRecurse /> },
      selectiveProcessProofRecurse: { icon: () => <FiXCircle />, title: 'Prova - Solicitação de recurso', render: () => <SelectiveProccessProofRecurse /> },

    }

    eventSearch = {
      ...eventSearch,
      home2: { icon: () => <FaHome />, title: 'Resumo', render: () => <Resumo /> },
      student3: { icon: () => <FaUser />, title: 'Cadastros', render: () => <Students /> },
      sale3: { icon: () => <FaCreditCard />, title: 'Vendas', render: () => <Sale /> },
      product: { icon: () => <FaTicketAlt />, title: 'Produtos', render: () => <ProductsAdmin /> },
      /*  program : { icon : () => <FaCalendar/>, title : 'Programação', render : () => <Program/>},
        sponsor : { icon : () => <FaStar/>, title: 'Patrocinadores' , render : () => <SponsorsAdmin/>},
        slides :  { icon : () => <FaSlideshare/>, title : 'Slides' , render : () => <Slides />},
        imagegallery : { icon : () => <FaRegSmileBeam/>, title: 'Mini Expositor' , render : () => <ImageGallery/>},
        photogallery : { icon : () => <FaImages/>, title: 'Galerias de Fotos' , render : () => <PhotoGallery/>},
        videogallery : { icon : () => <FaVideo/>, title: 'Galerias de Vídeos' , render : () => <VideoGallery/>},
        speakersPosition : { icon : () => <FaSpeakerDeck/>, title : 'Blocos de Palestrantes' , render : () => <SpeakerPosition />},
        expositor: { icon : () => <FaBuilding/>, title : 'Expositores' , render : () => <Expositor />},
        course: { icon : () => <FaBoxes/>,  title : 'Cursos', render : () => <Courses />},
        lesson: { icon : () => <FaBox/>, title : 'Palestras' , render : () => <Lessons />},
        certificate : { icon : () => <FaCertificate/>, title: 'Certificados' , render : () => <Certificate/>},
        satisfaction : { icon : () => <FaQuestion/>, title: 'Pesquisa de Satisfação' , render : () => <SatisfactionSurvey/>},
        exam : { icon : () => <FaReceipt/>, title: 'Exame' , render : () => <Exam/>},*/
    }
  }




  useEffect(() => {

    if (tab) {

      setSearch(tab);

    }



  }, [tab, projectHash, eventHash])



  if (loading) {
    return <Container>Você foi removido</Container>;
  }

  return (<>

    <Container>

      <SidebarComponent active={validSearch?.[tab]?.closeSidebar || window.innerWidth <= 800 ? false : true} projectSearch={projectSearch} validSearch={validSearch} eventSearch={eventSearch} />
      <div style={{ width: '100%' }}>
        <CardContainer>

          {/*
<Link style={{height: '46px', cursor: 'pointer'}} to="/">
   <FaArrowLeft  size="20"/>
   <p>
     Home
   </p>
 </Link>

 <Link style={{height: '46px', cursor: 'pointer'}} to="/manager/reporter">
   <FiBarChart  size="20"/>
   <p>
     Relatórios
   </p>
  </Link> 
             <CounterCardRepeat
          
   endpoint="/report/onlinePlayers"
   title="Usuários Online"
   Icon={FiUserPlus}

 />
 <CounterCard
   endpoint={`/report/users/${projectHash}`}
   title="Usuários Cadastrados"
   Icon={FiUsers}
   link="/manager/student" 
 />
  {user.profile === 1 && <>
 <CounterCard
   endpoint={`/report/sales_paid/${projectHash}`}
   title="Confirmados"
   Icon={FiCreditCard}
   link="/manager/sale"
 />
 <CounterCard
   endpoint={`/report/sales_waiting/${projectHash}`}
   title="Vendas Aguardando"
   Icon={FaCreditCard}
   link="/manager/sale"
 /> </>}
*/}


        </CardContainer>


        {!projectHash && validSearch[search] ? validSearch[search].render() : <></>}
        {projectHash && projectSearch[search] ? projectSearch[search].render() : <> </>}
        {eventHash && eventSearch[search] ? eventSearch[search].render() : <></>}
      </div>

    </Container></>
  );
};

export default Dashboard;
