import React, { useState } from 'react';
import api from '../../../../../../../../services/api';
import { useToast } from '../../../../../../../../hooks/Toast';

import { useAuth } from '../../../../../../../../hooks/Auth';
import { FiSend } from 'react-icons/fi';
import Whatsapp from '../../../../../../../../components/Whatsapp';
import { authTitle, urlWebsite } from '../../../../../../../../config';
import { FaCheckCircle, FaMoneyBill, FaReceipt } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import downloadit from '../../../../../../../../utils/download';
import { useModal } from '../../../../../../../../hooks/Modal';
import { useConfiguration } from '../../../../../../../../hooks/Configuration';
import { string } from 'yup';

import { ContentAvaliation } from './styles';
import { useSearch } from '../../../../../../../../hooks/Search';
import { rgb } from 'polished';
import SelectAction from '../../../../../../core/components/Forms/SelectAction';
import useDataHistory from '../../hook/useDataHistory';

interface ICreateModule {
  lineData: Record<string, any>;
}

interface IProps {
  lineData?: Record<string, any>;
  content: Record<string, any>;
  values: Record<string, any>;
  avaliation: Record<string, any>;
  setAvaliation: React.Dispatch<React.SetStateAction<Record<string, any>>>;
  currentAvaliationIndex: number;
  setCurrentAvaliationIndex: React.Dispatch<React.SetStateAction<number>>;
  index: number;
  globalCount: number;
  review: Record<string, any>;
  updateReview: Function;
}

interface IPropsList {
  content: Record<string, any>;
  lineData: Record<string, any>;
  review: Record<string, any>;
  updateReview: Function;
}

interface IOption {
  id: string;
  value: string;
  label: string;
  sub_title?: string;
}


const VisualizeCurriculum: React.FC<IProps> = ({ lineData, content, values, index, globalCount, avaliation, setAvaliation, currentAvaliationIndex, setCurrentAvaliationIndex, review, updateReview }) => {

  const { openHistory } = useDataHistory();

  const [hugeImage, setHugeImage] = useState('')


  const optionsList = content?.categories ? [...content?.categories] : [];


  const calculateSub = (data, sub, index = 0, url) => {





    const points = parseFloat(content?.categories?.[index]?.points);
    let value = 0;
    let currentPoints = 0;

    /* key === url */
    Object.keys(data.images).map(key => {



      currentPoints = currentPoints + parseFloat(data.images[key]);

      if (currentPoints > points) {
        currentPoints = points;
      }

    });





    return currentPoints > points ? points.toFixed(2) : currentPoints.toFixed(2);


  }


  const calculate = (data, sub, index = 0) => {



    const points = parseFloat(content.points);
    let value = 0;



    Object.keys(data.avaliations).map(key => {

      const content = data.avaliations[key]?.avaliations || {};
      const points = parseFloat(data.avaliations[key]?.points);



      let currentPoints = 0;

      Object.keys(content).map(k => {
        const imgValue = content[k];


        currentPoints = currentPoints + parseFloat(imgValue);

        if (currentPoints > points) {
          currentPoints = points;
        }

      });

      value = value + currentPoints;


    })


    return value > points ? points.toFixed(2) : value.toFixed(2);


  }

  const updateAvaliation = (value) => {




    const getAvaliation = { ...avaliation };

    if (!getAvaliation[content.hash]) {
      getAvaliation[content.hash] = {
        points: content.points,
        current_value: 0,
        avaliations: {},
        subcategories: []
      }
    }



    Object.keys(getAvaliation[content.hash].avaliations).map(k => {

      if (getAvaliation?.[content?.hash]?.avaliations[k]?.avaliations?.[value?.url]) {
        delete getAvaliation[content.hash].avaliations[k]?.avaliations?.[value?.url];

        if (getAvaliation[content.hash].avaliations[k]?.avaliations.length < 1) {
          delete getAvaliation[content.hash].avaliations[k];
        }


      }

    })


    if (!getAvaliation[content.hash].avaliations?.[value.title]) {
      getAvaliation[content.hash].avaliations[value.title] = {
        points: value.points, avaliations: {
          [value?.url]: value.value
        }
      }
    }
    else {
      getAvaliation[content.hash].avaliations[value.title].avaliations[value?.url] = value.value
    }


    const calculateValue = calculate(getAvaliation[content.hash], false);

    getAvaliation[content.hash].current_value = calculateValue;


    setAvaliation({ ...getAvaliation })


  }



  const updateAvaliationSub = (value, index) => {




    /* Qual a subcategoria */

    /* Qual o valor da opção selecionada */

    /* Gravar na avaliação */

    /* Atualizar o valor parcial */


    const getAvaliation = { ...avaliation };

    if (!getAvaliation[content.hash]) {
      getAvaliation[content.hash] = {
        points: content.points,
        current_value: 0,
        avaliations: {},
        subcategories: []
      }
    }



    Object.keys(getAvaliation[content.hash].avaliations).map(k => {

      if (getAvaliation?.[content?.hash]?.avaliations[k]?.avaliations?.[value?.url]) {
        delete getAvaliation[content.hash].avaliations[k]?.avaliations?.[value?.url];

        if (getAvaliation[content.hash].avaliations[k]?.avaliations.length < 1) {
          delete getAvaliation[content.hash].avaliations[k];
        }


      }

    })


    if (!getAvaliation[content.hash].avaliations?.[value.title]) {
      getAvaliation[content.hash].avaliations[value.title] = {
        points: value.points, avaliations: {
          [value?.url]: value.value
        }
      }
    }
    else {
      getAvaliation[content.hash].avaliations[value.title].avaliations[value?.url] = value.value
    }


    if (!getAvaliation[content.hash].sub_values) {
      getAvaliation[content.hash].sub_values = [];
    }



    if (!getAvaliation[content.hash].sub_values[index]) {
      getAvaliation[content.hash].sub_values[index] = {

        points: content?.categories?.[index]?.points,
        value: 0,
        images: {
          [value.url]: value.value
        }
      };
    }
    else {
      getAvaliation[content.hash].sub_values[index].images[value.url] = value.value
    }


    getAvaliation[content.hash].sub_values[index].value = calculateSub(getAvaliation[content.hash].sub_values[index], true, index, value.url);





    let finalPoint = 0;

    getAvaliation[content.hash].sub_values.map(p => {
      finalPoint = finalPoint + parseFloat(p?.value || '0')
    })



    getAvaliation[content.hash].current_value = content.points <= finalPoint ? content.points : finalPoint;


    setAvaliation({ ...getAvaliation })


  }


  //console.log(lineData, content)

  return <ContentAvaliation style={{ color: '#333', display: 'flex', marginTop: '15px' }} >
    <div className='contentAvaliationScore'>
      <h2> </h2>
      <table>
        <tbody>
          <tr key={content.hash}><td>{content.title}</td> <td><SelectAction name={content.hash} setCallback={(value) => updateReview('documents', content.hash, value)} /></td>

            <td>
              <button className='historyIcon' onClick={() => openHistory(lineData?._id, 'documents', content.hash)}>
                <FaReceipt />
              </button>
            </td>

          </tr>
          {content?.reference && lineData?.subscription?.[content?.reference] ? <tr><td>Número do documento:</td><td>{lineData?.subscription?.[content?.reference]}</td></tr> : <></>}
        </tbody>
      </table>

      <p style={{ color: '#333', width: '100%' }} dangerouslySetInnerHTML={{ __html: content.description }} />
      {content?.mandatory === 'yes' ? <strong style={{ width: '100%', textAlign: 'left', fontSize: '10px', color: 'red' }}>Obrigatório</strong> : <></>}
    </div>
    <div className='separator' />

    {values?.uploads?.length < 1 ? <h2 style={{ color: '#777', margin: '25px 0px' }}>Nenhum documento adicionado</h2> : <></>}

    {values?.uploads?.map((image, imageIndex) => {

      const options: Array<IOption> = [];

      {
        content?.categories?.map((av, index) => {
          options.push({ value: index, id: index, label: av.title });
        })
      }

      return <div style={{ background: '#eee', padding: '0px 15px', margin: '10px 0px', display: 'flex', alignItems: 'center', justifyContent: 'flex-start', marginBottom: '15px', borderRadius: '10px' }}>
        <nav className='title'>{imageIndex + 1} </nav>
        {image?.url?.toLowerCase()?.indexOf('.pdf') >= 0 ? <a target="_BLANK" href={`${urlWebsite}/${image.url}`}><img key={image.url} src={`/apoio/pdf.png`} style={{ cursor: 'pointer', width: '70px', minWidth: '70px', padding: '5px' }} /> </a> : <a href={`${urlWebsite}/${image.url}`} download><img onClick={() => setHugeImage(`${urlWebsite}/${image.url}`)} key={image.url} src={`${urlWebsite}/${image.url}`} style={{ width: '100%', cursor: 'pointer', maxWidth: '200px' }} /></a>}


      </div>
    })}

    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>



    </div>
    <div className='hugeImage' style={{ display: hugeImage ? 'flex' : 'none' }}>
      <button className='nextAvaliation' onClick={() => setHugeImage('')}>Fechar</button>
      <div>


        <img src={hugeImage} style={{ width: '100%' }} />
      </div>

    </div>
  </ContentAvaliation>





}


const VisualizeCurriculumList: React.FC<IPropsList> = ({ content, lineData, review, updateReview }) => {
  const [avaliation, setAvaliation] = useState<Record<string, any>>({});

  const [currentAvaliationIndex, setCurrentAvaliationIndex] = useState(0);
  const { addToast } = useToast();
  const { removeModal } = useModal();
  const { reloadSearchAll } = useSearch();





  const globalValue = Object.keys(avaliation).reduce((prev, key) => {


    prev = prev + parseFloat(avaliation?.[key]?.current_value);


    return prev;

  }, 0)


  const fields: Array<JSX.Element> = [];

  content.map((item, index) => {

    const items = lineData?.documents?.[item.hash] || [];



    fields.push(<VisualizeCurriculum lineData={lineData} key={item.hash} globalCount={content.length} index={index} setCurrentAvaliationIndex={setCurrentAvaliationIndex} currentAvaliationIndex={currentAvaliationIndex} setAvaliation={setAvaliation} avaliation={avaliation} content={item} values={items} review={review} updateReview={updateReview} />);

  })

  return <>


    {fields}


  </>
}

interface IPropsVisualization {
  lineData: Record<string, any>;
  review: Record<string, any>;
  updateReview: Function;
}


const CurriculumVisualization: React.FC<IPropsVisualization> = ({ lineData, review, updateReview }) => {

  const projectHash = lineData.project_id;
  const { addToast } = useToast();
  const { addModal } = useModal();
  const { configuration } = useConfiguration();






  const moduleUpdate = (): JSX.Element => {

    const content = lineData?.category?.toUpperCase() === 'PRÉ-REQUISITOS' ? configuration.documents_configuration_pre_requisitos || configuration.documents_configuration || [] : configuration.documents_configuration || [];

    return projectHash ? <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'column' }}> <h2 style={{ marginTop: '30px', color: '#333', width: '100%', textAlign: 'center', padding: '15px' }}>Documentos anexados</h2><br /><VisualizeCurriculumList content={content} review={review} updateReview={updateReview} lineData={lineData} /></div> : <></>
  }


  return moduleUpdate();
}


export default CurriculumVisualization;