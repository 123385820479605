import React from 'react';

import {
  SearchComponent,
  ISearchComponent,
} from '../../core/components/SearchComponent';

import lineModules from '../../core/components/lineModules';
import uploadSelectiveProcess from './modules/UploadSelectiveProcess'
import uploadSelectiveProcessDisqualify from './modules/UploadSelectiveProcessDisqualify'
import { Container } from './styles';
import { useParams } from 'react-router-dom';
import { useConfiguration } from '../../../../hooks/Configuration';
import { useAvaliateCurriculum as avaliateCurriculum } from './modules/avaliateCurriculum';

import { useAvaliateCurriculum as visualizateCurriculumRecurse } from './modules/visualizateCurriculumRecurse';

import { useAvaliateCurriculum as visualizateCurriculum } from './modules/visualizateCurriculum';

import { useVisualizateRegister as visualizateRegister } from './modules/RegistrationConfirmation';

import { useVisualizateRegister as downloadRegister } from './modules/RegistrationConfirmationDownload';
import notAvaliated from './modules/NotCurriculumAvaliatedProcess';

import uploadAvaliators from './modules/uploadAvaliators';

import notAvaliatedRecurse from './modules/NotCurriculumRecurseAvaliatedProcess';
import ApprovedOrReadySelectiveProcess from './modules/ApprovedOrReadySelectiveProcess';
import updateSelectiveProcess from './modules/UpdateSelectiveProcess';
import UploadSubscriptionUpdateSelectiveProcess from './modules/UploadSubscriptionUpdateSelectiveProcess';

import sendToAllAvaliators from './modules/SendToAllAvaliators';
import finishMatriculas from './modules/FinishMatriculas';
import PrepareJudicialDocs from './modules/PrepareJudicialDocs';
import { useUnifyDocs as unifyDocs } from './modules/UnifyToPDF';
import { useVerificateReserved as documentacaoReservado } from './modules/DocumentacaoReservado';
interface ValidationRules {
  type: 'email' | 'required' | 'min' | 'max' | 'length';
  message?: string;
  quantity?: number;
}

interface ValidationSchema {
  name: string;
  type: 'string' | 'number' | 'boolean';
  label: string;
  rules: Array<ValidationRules>;
}


const Dashboard: React.FC = () => {
  const endpoint = '/selective-process';
  const title = 'Processo Seletivo';
  const { projectHash, eventHash } = useConfiguration();

  const schema: Record<string, any> = {
    _id: { ref: '_id', column: '_id', label: 'ID', show: false },
    project_id: { ref: 'project_id', column: 'project_id', label: 'Projeto' },
    test_grade: { ref: 'test_grade', column: 'test_grade', label: 'Nota na prova' },
    client_id: {
      ref: 'client_id',
      column: 'client_id_response',
      includeColumn: 'name',
      label: 'Cliente',
    },
    avaliator_id: {
      ref: 'avaliator_id',
      column: 'avaliator_id_response',
      includeColumn: 'name',
      label: 'Avaliador',
    },
    unified_docs: { ref: 'unified_docs', column: 'unified_docs', label: 'PDF gerado?' },
    status: {
      ref: 'status', column: 'status', label: 'Status', filter: true, options: [
        { label: 'Aguardando envio de documentação', value: 'waiting' },
        { label: 'Aguardando avaliação da documentação', value: 'waiting_curriculum' },
        { label: 'Aguardando matrícula', value: 'register' },
        { label: 'Aguardando deferimento', value: 'waiting_complete' },
        { label: 'Documentação aprovada para matrícula', value: 'aprovado' },
        { label: 'Matriculado', value: 'matriculado' },
        { label: 'Cancelado', value: 'cancelled' },
        { label: 'Reservado', value: 'reserved' },
        { label: 'Desclassificado', value: 'disqualified' },

      ]
    },
    category: { ref: 'category', column: 'category', label: 'Categoria' },
    area: { ref: 'area', column: 'area', label: 'Área' },
    subarea: { ref: 'subarea', column: 'subarea', label: 'Subárea' },
    register_position: { ref: 'register_position', column: 'register_position', label: 'Status de Matrícula', show: true },
    register_documents: { ref: 'register_documents', column: 'register_documents', label: 'Documentos de Matrícula', show: false },




    register_position_number: { ref: 'register_position_number', column: 'register_position_number', label: 'Posição de classificação', show: true },
    photo: { ref: 'photo', column: 'photo', label: 'Foto', show: false },
    dress_type: { ref: 'dress_type', column: 'dress_type', label: 'Gênero do uniforme', show: false },
    dress_size: { ref: 'dress_size', column: 'dress_size', label: 'Tamanho do uniforme', show: false },

    subscription: { ref: 'subscription', column: 'subscription', label: 'Matrícula', show: false },
    vaccines: { ref: 'vaccines', column: 'vaccines', label: 'Vacinas', show: false },
    curriculum: { ref: 'curriculum', column: 'curriculum', label: 'Currículo', show: false },

    curriculum_points: { ref: 'curriculum_points', column: 'curriculum_points', label: 'Pontuação do Currículo', show: true },
    curriculum_avaliation: { ref: 'curriculum_avaliation', column: 'curriculum_avaliation', label: 'Avaliação do Currículo', show: false },
    curriculum_status: { ref: 'curriculum_status', column: 'curriculum_status', label: 'Currículo avalido?', show: true },

    curriculum_recurse_status: { ref: 'curriculum_recurse_status', column: 'curriculum_recurse_status', label: 'Foi solicitado recurso?', show: true },
    allow_curriculum_recurse: { ref: 'allow_curriculum_recurse', column: 'allow_curriculum_recurse', label: 'Permitir recurso individual', show: true },


    curriculum_recurse_documents: { ref: 'curriculum_recurse_documents', column: 'curriculum_recurse_documents', label: 'Documentos do Recurso', show: false },
    curriculum_recurse_justification: { ref: 'curriculum_recurse_justification', column: 'curriculum_recurse_justification', label: 'Justificativa do Recurso', show: false },

    curriculum_recurse_avaliation_status: { ref: 'curriculum_recurse_avaliation_status', column: 'curriculum_recurse_avaliation_status', label: 'Recurso foi aprovado?', show: false },
    curriculum_recurse_avaliation_finished: { ref: 'curriculum_recurse_avaliation_finished', column: 'curriculum_recurse_avaliation_finished', label: 'Recurso foi avaliado?', show: false },
    curriculum_recurse_avaliation_justification: { ref: 'curriculum_recurse_avaliation_justification', column: 'curriculum_recurse_avaliation_justification', label: 'Justificativa do avaliador do Recurso', show: false },
    review: { ref: 'review', column: 'review', label: 'Revisão', show: false },
    review_status: { ref: 'review_status', column: 'review_status', label: 'Foi revisado?', show: true },

    term: { ref: 'term', column: 'term', label: 'Termo de matrícula', show: false },

    cancelledAt: {
      ref: 'cancelledAt',
      column: 'cancelledAt',
      label: 'Data de Cancelamento',
      mask: 'date',
    },

    reservedAt: {
      ref: 'reservedAt',
      column: 'reservedAt',
      label: 'Data de Reserva',
      mask: 'date',
    },

    createdAt: {
      ref: 'created_at',
      column: 'createdAt',
      label: 'Data de Criação',
      mask: 'date',
    },
  };

  const formSchema = {

    project_id: { model: 'input', type: 'text', name: 'project_id', label: 'Projeto' },
    test_grade: { model: 'input', type: 'text', name: 'test_grade', label: 'Nota' },
    have_additional_grade: {
      model: 'selectSimple',
      name: 'have_additional_grade',
      label: 'Pontuação adicional aprovada?',

      options: [
        { label: 'Sim', value: 'yes' },
        { label: 'Não', value: 'no' },


      ]

    },
    client_id: {
      alias: 'client_id',
      model: 'selectApi',
      name: 'client_id',
      label: 'Cliente',
      endpoint,
    },

    category: { model: 'input', type: 'text', name: 'category', label: 'Categoria' },
    area: { model: 'input', type: 'text', name: 'area', label: 'Área' },
    subarea: { model: 'input', type: 'text', name: 'subarea', label: 'Subárea' },
    curriculum_points: { model: 'input', type: 'text', name: 'curriculum_points', label: 'Pontos de avaliação Currícular' },
    register_position_number: { model: 'input', type: 'text', name: 'register_position_number', label: 'Posição de classificação' },
    register_position: {
      model: 'selectSimple',
      name: 'register_position',
      label: 'Status de classificação',

      options: [
        { label: 'Classificado', value: 'Classificado' },
        { label: 'Documentação não enviada', value: 'Documentação não enviada' },
        { label: 'Suplente', value: 'Suplente' },
        { label: 'Desclassificado', value: 'Desclassificado' },
        { label: 'Não classificado', value: 'Não classificado' },
        { label: 'Cancelado pelo participante', value: 'Cancelado pelo participante' },
        { label: 'Reservado', value: 'Reservado' },
      ]

    },
    status: {
      model: 'selectSimple',
      name: 'status',
      label: 'Status',
      filter: true,
      options: [
        { label: 'Aguardando envio de documentação', value: 'waiting' },
        { label: 'Aguardando avaliação da documentação', value: 'waiting_curriculum' },
        { label: 'Aguardando matrícula', value: 'register' },
        { label: 'Aguardando deferimento', value: 'waiting_complete' },
        { label: 'Documentação aprovada para matrícula', value: 'aprovado' },
        { label: 'Matriculado', value: 'matriculado' },
        { label: 'Cancelado', value: 'cancelled' },
        { label: 'Reservado', value: 'reserved' },
        { label: 'Desclassificado', value: 'disqualified' },
      ]

    },

    curriculum_status: {
      model: 'selectSimple',
      name: 'curriculum_status',
      label: 'Currículo foi avaliado?',
      filter: true,
      options: [
        { label: 'Sim', value: 'yes' },
        { label: 'Não', value: 'no' },


      ]

    },

    allow_curriculum_recurse: {
      model: 'selectSimple',
      name: 'allow_curriculum_recurse',
      label: 'Permitir recurso individual',

      options: [
        { label: 'Sim', value: 'yes' },
        { label: 'Não', value: 'no' },


      ]

    },

    curriculum_recurse_status: {
      model: 'selectSimple',
      name: 'curriculum_recurse_status',
      label: 'Foi solicitado recurso?',

      options: [
        { label: 'Sim', value: 'yes' },
        { label: 'Não', value: 'no' },


      ]

    },

    curriculum_recurse_avaliation_finished: {
      model: 'selectSimple',
      name: 'curriculum_recurse_avaliation_finished',
      label: 'Recurso foi avaliado?',

      options: [
        { label: 'Sim', value: 'yes' },
        { label: 'Não', value: 'no' },


      ]

    },

    unified_docs: {
      model: 'selectSimple',
      name: 'unified_docs',
      label: 'Documentos foram unificados?',

      options: [
        { label: 'Sim', value: 'yes' },
        { label: 'Não', value: 'no' },


      ]

    },
    send_docs: {
      model: 'selectSimple',
      name: 'send_docs',
      label: 'Documentos adicionados a pasta?',

      options: [
        { label: 'Sim', value: 'yes' },
        { label: 'Não', value: 'no' },


      ]

    },
    curriculum_avaliation_observation: { model: 'input', type: 'text', name: 'curriculum_avaliation_observation', label: 'Observação de avaliação currícular' },

    dress_type: { model: 'input', type: 'text', name: 'dress_type', label: 'Gênero Jaleco' },
    dress_size: { model: 'input', type: 'text', name: 'dress_size', label: 'Tamanho Jaleco' },

  };

  const formSchemaUpdate = {

    photo: {
      model: 'uploadImage',
      type: 'upload',
      name: 'photo',
      label: 'photo',
    },
    ...formSchema
  };

  const formValidation: Record<string, ValidationSchema> = {

  };



  const config: ISearchComponent = {
    idKey: `${endpoint}-${projectHash}-${eventHash}`,
    title,
    endpoint,
    schema,
    autoStart: true,
    headModules: [
      () =>
        lineModules.create({
          title,
          formSchema,
          validationSchema: formValidation,
          endpoint,
        }),
      () => uploadSelectiveProcess(),
      () => uploadSelectiveProcessDisqualify(),
      () => notAvaliated(),
      () => notAvaliatedRecurse(),
      () => updateSelectiveProcess(),
      () => ApprovedOrReadySelectiveProcess(),
      () => UploadSubscriptionUpdateSelectiveProcess(),
      () => uploadAvaliators(),
      () => sendToAllAvaliators(),
      () => finishMatriculas()
    ],
    bodyModules: [
      (lineData: Record<string, any>) =>
        avaliateCurriculum({
          lineData,

        }),
      (lineData: Record<string, any>) =>
        lineModules.updateUnique({
          lineData,
          title,
          formSchema: formSchemaUpdate,
          validationSchema: {},
          endpoint,
        }),
      (lineData: Record<string, any>) =>
        lineModules.delete({
          lineData,
          title,
          formSchema,
          validationSchema: formValidation,
          endpoint,
        }),
      (lineData: Record<string, any>) =>
        visualizateCurriculumRecurse({
          lineData,


        }),

      (lineData: Record<string, any>) =>
        visualizateRegister({
          lineData,


        }),

      (lineData: Record<string, any>) =>
        visualizateCurriculum({
          lineData,

        }),
      /* (lineData: Record<string, any>) =>
         PrepareJudicialDocs({
           lineData,
 
         }),*/

      (lineData: Record<string, any>) =>
        unifyDocs({
          lineData,

        }),
      (lineData: Record<string, any>) =>
        documentacaoReservado({
          lineData,

        }),

      /* (lineData: Record<string, any>) =>
        downloadRegister({
          lineData,
     
     
        }), */
    ],
  };

  return (
    <Container>

      <SearchComponent {...config} />
    </Container>
  );
};

export default Dashboard;
