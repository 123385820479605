export const hash = '67e0f770-e46e-412a-ab0b-512570d8bb2b';

//export const hash = 'debbda34-2fe9-457a-aa12-a09046f842f7';

// export const gateKey = '';
export const gateKey = 'ek_live_g5Df6bp6y5ptAKeOVOpBloZHmqY0sb';
export const recaptchaKey = '6Ld9r7cZAAAAABw5wijiLD5PhlWqLivIN9NqFKi_';
export const apiUrl = `https://api-fundmed-selecao.encontrodigital.com.br/${hash}`;
export const apiSocket = 'https://global-socket-v2.encontrodigital.com.br/';
//export const apiSocket = 'http://localhost:8001';

//export const apiUrl = `http://localhost:8000/${hash}`;
//export const urlWebsite = 'https://residencia.fundmed.org.br';
export const urlWebsite = 'https://residencia.fundmed.org.br';
export const supportNumber = `5551995051267`;
export const home = 'Home';
export const haveTranslate = false;
export const defaultProjectHash = '';
// export const urlWebsite = 'http://localhost:3000';
export const eventName = 'Fundmed - Portal de Residências';
export const logo = '/apoio/logo-padrao2.png';
export const background = '/assets/evento/Fundo.jpeg';
export const authTitle = 'FundMed';
export const dadosOrganizacao = '';
export const social = {
  whatsapp: '',
  instagram: '',
  facebook: '',
  linkedin: '',
  youtube: '',
  twitter: '',
};


export const defaultTheme = {
  defaultBackground: 'rgb(0,50,150)',
  defaultColor: '#fff',
  logo: {
    minWidth: '450px',
  },
  menu: {
    background: 'none',
    backgroundSticky: 'none',
    backgroundMobile: 'rgb(0,50,150)',
    color: '#fff',
    shadow: '0',
    logoColor: 'none',
  },
};

export const getToken = (): string | null => {
  const token = localStorage.getItem(`@${authTitle}:token`);
  return token;
};
